import React from 'react';
import InfoModal from '../InfoModal';

const modalStyles = {
	content: {
		maxWidth: 500,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

const UploadPhotoErrorModal = ({
	showErrorModal,
	closeModal,
	isMinimumUploadSize,
	maxTitle = '5'
}) => {
	const heading = `${
		isMinimumUploadSize ? 'Invalid file size' : ' Upload File Error'
	}`;
	const bodyContent = `${
		isMinimumUploadSize
			? `File's size need to be atleast 1KB. Please upload again.`
			: `Upload Failed, File name already exists, unsupported file type or the file is larger than ${maxTitle}MB.`
	}`;
	return (
		<InfoModal
			showModal={showErrorModal}
			closeModal={closeModal}
			styles={modalStyles}
			heading={heading}
			bodyContent={bodyContent}
		/>
	);
};

export default UploadPhotoErrorModal;
