import React, { useEffect, useState } from 'react';
import HeaderBanner from '../HeaderBanner';
import ActionDataTable from '../ActionDataTable';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import { claimList, getFullCircleOpts, saveFullCircles } from './queries';
import ClaimedThoroughbredDataRow from '../ClaimedThoroughbredDataRow';
import { mobileHeadings, tableHeadings } from './constants';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import { Link } from 'gatsby';
import LoaderSpinner from '../LoaderSpinner';
import Dialog from '../Dialog';
import PrimaryButton from '../PrimaryButton';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import SelectInputField from '../FormComponents/SelectInputField';
import MultiDropDown from '../MultiDropDown';
import { FullCircleFormKeys } from '../../utils/constants';
import { useToast } from '../../hooks/useToast';

const ClaimedThoroughbredListings = ({ isMobile }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [numClaimedHorses, setNumClaimedHorses] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [claimedHorses, setClaimedHorses] = useState([]);
	const { currentUser } = useAuth();
	const [rows, setRows] = useState([]);
	const [selectAllFullCircle, setSelectAllFullCircle] = useState(false);
	const [openFullCircleDialog, setOpenFullCircleDialog] = useState(false);

	const [horseRelationship, setHorseRelationship] = useState(0);
	const [assistanceType, setAssistanceType] = useState(0);
	const [assistanceTypeList, setAssistanceTypeList] = useState([]);
	const [relationshipList, setRelationshipList] = useState([]);
	const [isAddFullCircle, setIsAddFullCircle] = useState(false);

	const [validateFullCircle, setValidateFullCircle] = useState(false);
	const [isValidFullCircle, setIsValidFullCircle] = useState(false);
	const [isFullCircleLoading, setIsFullCircleLoading] = useState(false);
	const [reloadClaimList, setReloadClaimList] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		if (currentUser) {
			const input = { memberExternalId: currentUser?.UserId };
			AppSyncService.execute(claimList, input).then((data) => {
				if (data?.data?.getHorseClaims) {
					const modifiedClaimedHorses = data.data.getHorseClaims.map((item) => {
						item.selected = false;
						return item;
					});
					setClaimedHorses(modifiedClaimedHorses);
					setNumClaimedHorses(data.data.getHorseClaims.length);
					getOptsFullCircle();
					setIsLoading(false);
					setReloadClaimList(false);
				}
			});
		}
	}, [currentUser, reloadClaimList]);

	useEffect(() => {
		buildRows();
	}, [claimedHorses]);

	useEffect(() => {
		setIsValidFullCircle(assistanceType != 0 && horseRelationship != 0);
		setValidateFullCircle(false);
	}, [validateFullCircle]);

	const getOptsFullCircle = async () => {
		await AppSyncService.execute(getFullCircleOpts).then((data) => {
			if (data?.data?.listFullCircleRelationships) {
				let relationshipList = data.data.listFullCircleRelationships;
				relationshipList.unshift({ id: 0, value: '' });
				setRelationshipList(relationshipList);
			}
			if (data?.data?.listFullCircleTypesOfAssistance) {
				let listAssistArray = [];
				let count = 0;
				data?.data?.listFullCircleTypesOfAssistance
					.filter((i) => i.value != 'Other')
					.map((rec) => {
						listAssistArray.push({
							id: count,
							key: rec.id,
							title: rec.value,
							selected: false
						});
						count++;
					});
				setAssistanceTypeList(listAssistArray);
			}
		});
	};

	const sortRowsDown = () => {
		const thoroughbredRows = claimedHorses;
		setClaimedHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.horse?.ottInformation?.paddockName ?? horse1?.horse?.name
				).localeCompare(
					horse2?.horse?.ottInformation?.paddockName ?? horse2.horse?.name
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = () => {
		const thoroughbredRows = claimedHorses;
		setClaimedHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horse?.name
				).localeCompare(
					horse1?.horse?.ottInformation?.paddockName ?? horse1.horse?.name
				)
			)
		);
		buildRows();
	};

	const buildRows = () => {
		const numRows = claimedHorses.length;

		const constructedRows = claimedHorses.map((claimedHorse, index) => {
			return (
				<ClaimedThoroughbredDataRow
					thoroughbred={claimedHorse}
					key={claimedHorse.horse.horseCode}
					index={index}
					isLastRow={numRows === index + 1}
					isMobile={isMobile}
					onSelectFullCircle={onSelectFullCircle}
				/>
			);
		});
		setRows(constructedRows);
	};

	const onSelectAllFullCircle = () => {
		setSelectAllFullCircle((prev) => !prev);
		const modifiedClaimedHorse = claimedHorses.map((claimed) => {
			if (!claimed.fullCircles.id) {
				claimed.selected = !selectAllFullCircle;
			}
			return claimed;
		});
		setIsAddFullCircle(modifiedClaimedHorse.some((item) => item.selected));
		setClaimedHorses(modifiedClaimedHorse);
	};

	const onSelectFullCircle = (selectedHorseCodeClaim) => {
		const modifiedClaimedHorse = claimedHorses.map((claimed) => {
			if (claimed.horse.horseCode === selectedHorseCodeClaim) {
				claimed.selected = !claimed.selected;
			}
			return claimed;
		});
		setIsAddFullCircle(modifiedClaimedHorse.some((item) => item.selected));
		setClaimedHorses(modifiedClaimedHorse);
	};

	const onRenderActionButtons = () => {
		return (
			<>
				{isFullCircleLoading ? (
					<>
						<LoaderSpinner
							status={isFullCircleLoading}
							styles={{ marginTop: 20 }}
						/>
					</>
				) : (
					<>
						<Button
							onClick={() => {
								onCloseFullCircle();
							}}
							variant="contained"
							style={{
								textTransform: 'none',
								color: '#ffff',
								font: 'Open-sans',
								fontWeight: 600,
								fontSize: 15,
								backgroundColor: '#c62828'
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								onSaveFullCircle();
							}}
							variant="contained"
							style={
								isValidFullCircle
									? {
											textTransform: 'none',
											color: '#ffff',
											font: 'Open-sans',
											fontWeight: 600,
											fontSize: 15,
											backgroundColor: '#006da6'
									  }
									: {
											textTransform: 'none',
											font: 'Open-sans',
											fontWeight: 600,
											fontSize: 15
									  }
							}
							disabled={!isValidFullCircle}
						>
							Save
						</Button>
					</>
				)}
			</>
		);
	};

	const resetAssistType = (id) => {
		const temp = assistanceTypeList;

		temp[id].selected = !temp[id].selected;

		let selectedAssist = Object.assign([], assistanceType);

		if (temp[id].selected) {
			selectedAssist.push({ key: temp[id].key, value: temp[id].title });
		}

		if (!temp[id].selected) {
			const key = temp[id].key;
			const filterIndex = selectedAssist
				.map(function (e) {
					return e.key;
				})
				.indexOf(key);
			if (filterIndex > -1) {
				selectedAssist.splice(filterIndex, 1);
			}
		}
		setValidateFullCircle(true);
		setAssistanceTypeList(temp);
		setAssistanceType(selectedAssist);
	};

	const onRenderFullCircleContent = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div style={{ display: 'flex', gap: 50 }}>
					<div className={classes.fieldTitle}>Relationship to Horse*</div>
					<SelectInputField
						id={FullCircleFormKeys.RELATIONSHIP_TO_HORSE}
						value={horseRelationship}
						options={relationshipList}
						onChange={() => {
							setHorseRelationship(event.target.value);
							setValidateFullCircle(true);
						}}
					/>
				</div>

				<div style={{ display: 'flex', gap: 70 }}>
					<div className={classes.fieldTitle}>Type of Assistance*</div>
					<MultiDropDown
						id={FullCircleFormKeys.TYPE_OF_ASSISTANCE}
						list={assistanceTypeList}
						resetThenSet={resetAssistType}
						headTitle=""
					/>
				</div>
			</div>
		);
	};

	const onAddFullCircle = () => {
		setOpenFullCircleDialog(true);
	};

	const onCloseFullCircle = () => {
		const assistanceTypeListTemp = assistanceTypeList.map((item) => {
			item.selected = false;
			return item;
		});
		setAssistanceType(0);
		setAssistanceTypeList(assistanceTypeListTemp);
		setHorseRelationship(0);
		setOpenFullCircleDialog(false);
		setIsValidFullCircle(false);
	};

	const onSaveFullCircle = async () => {
		setIsFullCircleLoading(true);
		const listOfCodes = claimedHorses
			.filter((claimed) => claimed.selected)
			.map((item) => item.horse.horseCode);
		const listOfAssistance = assistanceType.map((item) => item.key);
		const input = {
			horseCodes: listOfCodes,
			memberExternalId: currentUser?.UserId,
			status: 'Review',
			typeOfAssistance: listOfAssistance,
			relationship: horseRelationship
		};
		try {
			await AppSyncService.execute(saveFullCircles, {
				input
			});
			addToast({ Message: 'Successul Saving Full Circle/s', IsSuccess: true });
			setIsFullCircleLoading(false);
			setReloadClaimList(true);
			onCloseFullCircle();
		} catch (e) {
			console.log(e, 'error');
			addToast({ Message: 'Error Saving Full Circle/s', IsSuccess: false });
			setIsFullCircleLoading(false);
		}
	};
	return (
		<div>
			<HeaderBanner
				title={'Claimed Thoroughbreds (' + numClaimedHorses + ')'}
				styles={{ marginBottom: '0px', marginTop: 0, borderRadius: 0 }}
			/>
			<div className={classes.fullCircleBtnContaner}>
				<PrimaryButton
					style={{ width: '165px' }}
					onClick={() => onAddFullCircle()}
					isNonSubmit
					disabled={!isAddFullCircle}
				>
					Add To Full Circle
				</PrimaryButton>
				<div style={{ marginBottom: '10px' }}></div>
			</div>
			<ActionDataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
				withCheckBox
				onSelectAllFullCircle={onSelectAllFullCircle}
				selectAllFullCircle={selectAllFullCircle}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			{!isLoading && numClaimedHorses === 0 && (
				<InfoBar
					type={MessageBarTypeEnum.info}
					noButton
					id={`No-Claimed-Thoroughbreds-Infobar`}
					style={{ borderRadius: 5, marginTop: 20 }}
				>
					You have not yet claimed any thoroughbred horses, or your claim form
					is being reviewed by the RVEW team, which occurs during business
					hours.
					<br />
					<br />
					To start a claim form and let us know about an OTT in your possession
					please{' '}
					<Link style={{ color: '#0087ce' }} to="/search">
						Search for your Thoroughbred
					</Link>
					.
					<br />
					<br />
					Need more information about the Claim process or details about the
					information required to complete a Claim form? Visit the{' '}
					<Link style={{ color: '#0087ce' }} to="/programs/claim-thoroughbred">
						Claim page
					</Link>
					.
				</InfoBar>
			)}
			<div style={{ marginBottom: '0px', marginTop: '20px' }} />
			<Dialog
				openDialog={openFullCircleDialog}
				handleClose={() => {}}
				title={'Full Circle'}
				contentChildren={onRenderFullCircleContent}
				actionButtons={onRenderActionButtons}
				diagContentStyle={{ height: '180px' }}
			/>
		</div>
	);
};

export default ClaimedThoroughbredListings;
