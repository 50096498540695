import { isMultiSelectOptionOther } from '../../utils/helpers';
import { NEW_ARRIVAL } from './constants';

export const isOtherSelectedDropdown = (id, options) => {
	return (
		options.find((item) => item.id == id)?.value?.toLowerCase() === 'other'
	);
};

export const isOtherSelectedMulti = (options) => {
	return options.some((item) => item.label?.toLowerCase() === 'other');
};

export const extractValidationErrors = ({
	formCause,
	assessmentDate,
	arrivalDate,
	knownAsName,
	explainSettlingPeriod,
	describeHorseCondition,
	isWorming,
	wormingDate,
	isFarrier,
	farrierDate,
	isDentist,
	dentistDate,
	isVet,
	vetDate,
	vetVisitDetails,
	horseFeedDetails,
	horseTemperamentDesc,
	horseInteractionDesc,
	habitOtherDetails,
	habits,
	photosOfTheHorse
}) => {
	let errors = {};
	if (!formCause)
		errors = {
			...errors,
			formCause: { message: 'This field is required' }
		};
	if (!assessmentDate)
		errors = {
			...errors,
			assessmentDate: { message: 'This field is required' }
		};
	if (!arrivalDate && formCause == NEW_ARRIVAL)
		errors = {
			...errors,
			arrivalDate: { message: 'This field is required' }
		};
	if (!knownAsName || knownAsName == '')
		errors = {
			...errors,
			knownAsName: { message: 'This field is required' }
		};
	if (!photosOfTheHorse || photosOfTheHorse?.length == 0)
		errors = {
			...errors,
			photosOfTheHorse: { message: 'This field is required' }
		};
	if (
		formCause == NEW_ARRIVAL &&
		(!explainSettlingPeriod || explainSettlingPeriod == '')
	)
		errors = {
			...errors,
			explainSettlingPeriod: { message: 'This field is required' }
		};
	if (!describeHorseCondition || describeHorseCondition == '')
		errors = {
			...errors,
			describeHorseCondition: { message: 'This field is required' }
		};
	if (isWorming && !wormingDate)
		errors = {
			...errors,
			wormingDate: { message: 'This field is required' }
		};
	if (isFarrier && !farrierDate)
		errors = {
			...errors,
			farrierDate: { message: 'This field is required' }
		};
	if (isDentist && !dentistDate)
		errors = {
			...errors,
			dentistDate: { message: 'This field is required' }
		};
	if (isVet && !vetDate)
		errors = {
			...errors,
			vetDate: { message: 'This field is required' }
		};
	if (!vetVisitDetails || vetVisitDetails == '')
		errors = {
			...errors,
			vetVisitDetails: { message: 'This field is required' }
		};
	if (!horseFeedDetails || horseFeedDetails == '')
		errors = {
			...errors,
			horseFeedDetails: { message: 'This field is required' }
		};
	if (!horseTemperamentDesc || horseTemperamentDesc == '')
		errors = {
			...errors,
			horseTemperamentDesc: { message: 'This field is required' }
		};
	if (!horseInteractionDesc || horseInteractionDesc == '')
		errors = {
			...errors,
			horseInteractionDesc: { message: 'This field is required' }
		};
	if (isMultiSelectOptionOther(habits) && !habitOtherDetails) {
		errors = {
			...errors,
			habitOtherDetails: { message: 'This field is required' }
		};
	}
	return errors;
};
