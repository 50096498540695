export const getAcknowledgedReTrainerForm = /* GraphQL */ `
	query getAcknowledgedRetrainerForm($formId: Int!) {
		getAcknowledgedReTrainerFormById(id: $formId) {
			id
			member {
				email
				firstName
				gender
				lastName
				dateOfBirth
				phone
				postalAddress {
					address
					country
					postcode
					state
					suburb
				}
				residentialAddress {
					address
					country
					postcode
					state
					suburb
				}
			}
			acknowledgesEquineWelfare
			acknowledgesNationalPoliceRecordCertificate
			acknowledgesCriminalCharges
			acknowledgesBankruptcy
			acknowledgesAgreement
			acknowledgesToUtiliseOttCommunity
			acknowledgesCorrectApplicationDetails
			stableAddressSameAsResidental
			stableCountry
			stablePostcode
			stableSuburb
			stableState
			stableAddress
			residentalAddress
			residentalCountry
			residentalPostcode
			residentalState
			residentalSuburb
			agentName
			personalDetailUploads {
				filename
				key
				documentType
				type
				sightedBy {
					firstName
					lastName
					id
				}
				isDeleted
				dateApprovedRejected
				status
			}
			insuranceCertificateOfCurrencyUploads {
				filename
				key
				sightedBy {
					firstName
					lastName
					id
				}
				isDeleted
				dateApprovedRejected
				status
			}
			status
			businessName
			abn
			businessNameOperation
			additionalEmployment {
				id
				value
			}
			additionalEmploymentDetails
			otherActivitiesDetails
			veterinaryPracticeName
			veterinaryAddress
			veterinaryPhone
			veterinaryEmail
			regularVeterinarian
			farrier
			farrierEmail
			farrierPhone
			creditReferencesUploads {
				filename
				key
			}
			staffs {
				fullName
				role {
					value
				}
				id
			}
			liabilityPolicyNumber
			liabilityCoverage
			liabilityExpiryDate
			publicLiabilityInsurerName
			publicLiabilityInsuredPartyName
			workersCompensationCoverage
			workersCompensationExpiryDate
			workersCompensationPolicyNumber
			workersCompensationInsuredPartyName
			indemnityInsurerName
			indemnityInsuredPartyName
			indemnityPolicyNumber
			indemnityExpiryDate
			indemnityCoverage
			professionalIndemnity
			sourceOfHorses {
				value
			}
			sourceOfHorsesOther
			disciplines {
				value
			}
			disciplinesOther
			numberOfHorses {
				value
			}
			rehomedHorsesDetails
			confirmCapacityToRetrain
			reasonToBecomeAckRetrainer
			expectations
			retrainingProcess
			stepsTakenForLongTermHorsePlacement
			raceNames
			signedReferences {
				filename
				key
			}
			manageUnsuitableDetails
			businessModel
			canReceiveEmergencyCases
			ridingExperience
			memberships {
				value
			}
			membershipsOther
			coach
			qualifyToCoach
			qualifications
			certificates {
				filename
				key
			}
			resume {
				filename
				key
			}
			videoDetails
			licenceType {
				value
			}
			licence
			licenceStart
			previousLicenceType {
				value
			}
			previousLicence
			previousLicenceStart
			hadRSPCAInvestigation
			rspcaInvestigationDetails
			isBannedByAnyRacingAuthority
			bannedByAnyRacingAuthorityDetails
			disqualified
			disqualificationDetails
			wasCharged
			chargeDetails
			currentCriminalProceedings
			criminalProceedingDetails
			bankruptcy
			bankruptcyDetails
			postalAddress
			postalCountry
			postalPostcode
			postalState
			postalSuburb
			businessType {
				id
				value
			}
			additionalDetails
			trusteeName
			trusteeAcn
			trusteeHasShares
			companySharesOwnershipDetails
			trusteeFurtherDetails
			partners {
				contactDetails
				name
			}
			ownsAllCompanyShares
			companySharesOwnershipDetails
			adminAction {
				admin {
					externalId
				}
				adminStatus {
					id
				}
				formComplete
			}
			videoAssessmentComments
			videoAssessmentDate
			videoAssessmentUploads {
				filename
				key
			}
			phoneInterviewComments
			phoneInterviewDate
			propertyAssessmentComments
			propertyAssessmentDate
			propertyAssessmentUploads {
				filename
				key
			}
			ridingAssessmentComments
			ridingAssessmentUpload {
				filename
				key
			}
			ridingAssessmentdate
			ridingScore
			propertyRidingPhotos {
				filename
				key
			}
			ewgmComment
			ewgmReviewDate
			applicationRecommendation
			rejectionReasons {
				id
				value
			}
			propertyRented
			contactNumber
		}
	}
`;

export const getApplicationReviewLookupValues = `
  query getApplicationReviewLookupValues {
    getAdminMembers {
      id
      externalId
      firstName
      lastName
    }
    listRetrainerFormAdminActions {
      id
      value
    }
    listRetrainerFormRejectReasons {
      id
      value
    }
	listRetirementFormRejectReasons {
		id
		value
	}
  }
`;
