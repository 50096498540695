export const CLAIM_THOROUGHBRED_FORM = 'Claim Thoroughbred';
export const CLAIM_CURRENT_THOROUGHBRED_FORM = 'Claim Thoroughbred (Current)';
export const CLAIM_PREVIOUS_THOROUGHBRED_FORM = 'Claim Thoroughbred (Previous)';
export const FULL_CIRCLE_FORM = 'Full Circle';
export const FIND_THOROUGHBRED_FORM = 'Find Your Thoroughbred';
export const AVAIL_RETRAINING_FORM = 'Request for Retraining';
export const AVAIL_RETRAINING_INTEREST_FORM =
	'Acknowledged Program Applications';
export const RESET_NOMINATION_FORM = 'RESET Nomination';
export const RESET_WEEKLY_PROG_FORM = 'RESET Weekly Progress';
export const RESET_INITIAL_FORM = 'RESET Initial Assessment';
export const RESET_EXPRESS_REHOME_REPORT = 'PRESET Ready for Rehoming Report';
export const RESET_EXPRESS_REHABILITATION_REPORT =
	'PRESET Rehabilitation Conclusion Report';
export const SHARE_INFO_FORM = 'Share Information';
export const UNNAMED = 'Unnamed';
export const USER_EDIT_STATUS_LIST = ['Draft', 'Review'];
export const CONTACTS_FORM = 'Contact Forms';
export const BUSINESS_PROFILE_FORM = 'Business Profile Requests';
export const MEMBER_BENEFITS_AND_MERCHANDISE_FORM =
	'Member benefits and merchandise';
export const OHEP_EOI_FORM = 'OHEP EOI';
export const OHEP_NOMINATION_FORM = 'OHEP Nomination';
export const TRANSITION_SUPPORT_FORM = 'Transition Support';
export const RETIREMENT_FARM_INITIAL_ASSESSMENT =
	'Retirement Farm Initial Assessment';
export const RETIREMENT_FARM_QUARTERLY_ASSESSMENT =
	'Retirement Farm Quarterly Assessment';
export const FOSTER_INITIAL_ASSESSMENT = 'STAR Initial Assessment';
export const FOSTER_WEEKLY_ASSESSMENT = 'STAR Weekly Assessment';
export const DECEASED_THOROUGHBRED_FORM = 'Deceased Thoroughbred Form';
export const SPONSORSHIP_OUTCOME_FORM = 'Sponsorship Outcome Form';
export const THOROUGHBRED_UNREGISTERED_FORM =
	'Thoroughbred unregistered in Australia';
export const RESET_EXPRESS_INITIAL_ASSESSMENT = 'PRESET Initial Assessment';
export const REHABILITATION_SUBSIDY_APPLICATION =
	'Rehabilitation Subsidy Application';
export const RTCP_HORSE_WELFARE_REPORT = 'RTCP Horse Welfare report';

export const ARRAY_FORM_TYPE_LIST = [
	{
		id: 1,
		value: CLAIM_CURRENT_THOROUGHBRED_FORM
	},
	{
		id: 2,
		value: CLAIM_PREVIOUS_THOROUGHBRED_FORM
	},
	{
		id: 3,
		value: FULL_CIRCLE_FORM
	},
	{
		id: 4,
		value: FIND_THOROUGHBRED_FORM
	},
	{
		id: 5,
		value: AVAIL_RETRAINING_FORM
	},
	{
		id: 6,
		value: AVAIL_RETRAINING_INTEREST_FORM
	},
	{
		id: 7,
		value: RESET_NOMINATION_FORM
	},
	{
		id: 8,
		value: RESET_WEEKLY_PROG_FORM
	},
	{
		id: 9,
		value: RESET_INITIAL_FORM
	},
	{
		id: 10,
		value: SHARE_INFO_FORM
	},
	{
		id: 11,
		value: CONTACTS_FORM
	},
	{
		id: 12,
		value: BUSINESS_PROFILE_FORM
	},
	{
		id: 13,
		value: OHEP_EOI_FORM
	},
	{
		id: 14,
		value: OHEP_NOMINATION_FORM
	},
	{
		id: 15,
		value: TRANSITION_SUPPORT_FORM
	},
	{
		id: 16,
		value: RETIREMENT_FARM_INITIAL_ASSESSMENT
	},
	{
		id: 17,
		value: RETIREMENT_FARM_QUARTERLY_ASSESSMENT
	},
	{
		id: 18,
		value: FOSTER_INITIAL_ASSESSMENT
	},
	{
		id: 19,
		value: FOSTER_WEEKLY_ASSESSMENT
	},
	{
		id: 20,
		value: DECEASED_THOROUGHBRED_FORM
	},
	{
		id: 21,
		value: THOROUGHBRED_UNREGISTERED_FORM
	},
	{
		id: 22,
		value: SPONSORSHIP_OUTCOME_FORM
	},
	{
		id: 23,
		value: RESET_EXPRESS_INITIAL_ASSESSMENT
	},
	{
		id: 24,
		value: RESET_EXPRESS_REHOME_REPORT
	},
	{
		id: 25,
		value: RESET_EXPRESS_REHABILITATION_REPORT
	},
	{
		id: 26,
		value: REHABILITATION_SUBSIDY_APPLICATION
	},
	{
		id: 27,
		value: RTCP_HORSE_WELFARE_REPORT
	}
];
