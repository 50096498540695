import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
	getFilteredEvents,
	getUpcomingEvents
} from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import { useToast } from '../../hooks/useToast';
import {
	checkIsSmallScreen,
	convertUTCTimeToLocal,
	eventColourMap
} from '../../utils/helpers';
import EventsBanner from '../EventsBanner';
import PageContainer from '../PageContainer';
import { navigate } from 'gatsby-link';
import SEO from '../seo';
import MultiSelectAll from '../MultiSelectAll';
import LoaderSpinner from '../LoaderSpinner';
import { Container, Row, Col, useScreenClass } from 'react-grid-system';
import Triangle from '../../assets/icons/Triangle.svg';
import EventPopover from '../EventPopover';
import EventsUpcoming from '../EventsUpcoming';
import cancelEventIcon from '../../assets/icons/Cancel_Event_icon.svg';
import { Popover } from 'react-tiny-popover';
import Accordion from '../Accordion';
import FormDatePicker from '../FormDatePicker';
import { getMinDate } from '../../utils/helpers';

moment.locale('en-au', {
	week: {
		dow: 1,
		doy: 1
	}
});
const localizer = momentLocalizer(moment);

const Events = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [eventsList, setEventsList] = useState([]);
	let selectedEventDate = JSON.parse(localStorage.getItem('selectedDate'));
	const defaultSelectedDate = selectedEventDate
		? new Date(selectedEventDate)
		: new Date();
	const [selectedDate, setSelectedDate] = useState(defaultSelectedDate);
	const [selectedWeek, setSelectedWeek] = useState(moment(new Date()).week());
	const [weekSelection, setWeekSelection] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [upcomingEvents, setUpcomingEvents] = useState([]);
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const [toggleFilter, setToggleFilter] = useState(false);
	const disciplines = [
		{ value: '*', label: 'All' },
		{ value: 1, label: 'Combined Training' },
		{ value: 2, label: 'Dressage' },
		{ value: 3, label: 'Education' },
		{ value: 4, label: 'Horse Trials' },
		{ value: 5, label: 'Mounted Games' },
		{ value: 6, label: 'Multi' },
		{ value: 7, label: 'Show Horse' },
		{ value: 8, label: 'Polo' },
		{ value: 9, label: 'Show Jumping' },
		{ value: 10, label: 'Trail Riding' },
		{ value: 11, label: 'Other' }
	];
	const eventOrganisers = [
		{ value: '*', label: 'All' },
		{ value: 1, label: 'Boneo Park' },
		{ value: 2, label: 'EQEAL' },
		{ value: 3, label: 'Equestrian Victoria' },
		{ value: 4, label: 'Equine Pathways Australia' },
		{ value: 5, label: 'HRCAV' },
		{ value: 6, label: 'Pony Club Victoria' },
		{ value: 7, label: 'Riding For The Disabled Victoria' },
		{ value: 8, label: 'Show Horse Council Victoria' },
		{ value: 9, label: 'Sporting Horse Australia' },
		{ value: 10, label: 'Victorian Agricultural Shows' },
		{ value: 11, label: 'Victorian Polo Association' },
		{ value: 12, label: 'Other' }
	];
	let storedEventOrganiserOpts = JSON.parse(
		localStorage.getItem('selectedEventOrganiserOptions')
	);
	let storedDisciplineOpts = JSON.parse(
		localStorage.getItem('selectedDisciplineOptions')
	);
	const defaultDisciplines = !storedDisciplineOpts
		? disciplines
		: storedDisciplineOpts;
	const defaultEventOrganisers = !storedEventOrganiserOpts
		? eventOrganisers
		: storedEventOrganiserOpts;

	const [selectedDisciplineOptions, setSelectedDisciplineOptions] = useState(
		defaultDisciplines
	);

	const [
		selectedEventOrganiserOptions,
		setSelectedEventOrganiserOptions
	] = useState(defaultEventOrganisers);

	const convertEventStructure = (event) => {
		let start = event.eventStartTime
			? event.eventStartDate + ' ' + convertUTCTimeToLocal(event.eventStartTime)
			: event.eventStartDate;
		let end = event.eventEndTime
			? (event.eventEndDate ?? event.eventStartDate) +
			  ' ' +
			  convertUTCTimeToLocal(event.eventEndTime)
			: event.eventEndDate ?? event.eventStartDate;
		return {
			start: new Date(start),
			end: new Date(end),
			startTime: event.eventStartTime
				? convertUTCTimeToLocal(event.eventStartTime)
				: null,
			endTime: event.eventEndTime
				? convertUTCTimeToLocal(event.eventEndTime)
				: null,
			allDay: !event.eventStartTime && !event.eventEndTime,
			eventDiscipline: event.eventDiscipline,
			title: event.eventName,
			address: event.eventAddress,
			suburb: event.eventSuburb,
			postcode: event.eventPostcode,
			state: event.eventState,
			country: event.eventCountry,
			startDate: event.eventStartDate,
			endDate: event.eventEndDate,
			id: event.id,
			isCancelled: event.isCancelled
		};
	};

	useEffect(() => {
		renderUpcomingEvents();
		updateQueryResults(selectedDate);
	}, []);

	useEffect(() => {
		if (selectedEventOrganiserOptions.length > 0) {
			onSetSelectedEventOrganiser(selectedEventOrganiserOptions);
		}
		if (selectedDisciplineOptions.length > 0) {
			onSetSelectedDiscipline(selectedDisciplineOptions);
		}
	}, [selectedEventOrganiserOptions, selectedDisciplineOptions]);

	const onSetSelectedEventOrganiser = (val) => {
		localStorage.setItem('selectedEventOrganiserOptions', JSON.stringify(val));
	};

	const onSetSelectedDiscipline = (val) => {
		localStorage.setItem('selectedDisciplineOptions', JSON.stringify(val));
	};

	const renderUpcomingEvents = async () => {
		AppSyncService.execute(getUpcomingEvents).then((data) => {
			if (data?.data?.getUpcomingEvents) {
				let upcoming = [];
				data.data.getUpcomingEvents.forEach((event) => {
					upcoming.push(convertEventStructure(event));
				});
				setUpcomingEvents(upcoming);
			}
		});
	};

	const fetchEvents = (disciplineList, eventOrganisersList, dateRange) => {
		setIsLoading(true);

		AppSyncService.execute(getFilteredEvents, {
			status: 'Approved',
			eventDisciplineIds: disciplineList.filter((item) => item !== '*'),
			eventOrganiserIds: eventOrganisersList.filter((item) => item !== '*'),
			eventStartFilter: dateRange.startDate,
			eventEndFilter: dateRange.endDate
		}).then((data) => {
			try {
				setEventsList(data.data.getEvents);
				let el = [];
				data.data.getEvents.map((event) => {
					el.push(convertEventStructure(event));
				});
				setEventsList(el);

				setIsLoading(false);
			} catch {
				addToast({
					Message: 'Error Fetching Events. Please try again later.',
					IsSuccess: false
				});
				setIsLoading(false);
			}
		});
	};

	const getEventQueryDateRange = (date) => {
		let startDate = new Date(date.getTime());
		startDate.setDate(15);
		startDate.setMonth(date.getMonth() - 1);
		startDate.setDate(20);
		let endDate = new Date(date.getTime());
		endDate.setDate(15);
		endDate.setMonth(date.getMonth() + 1);
		endDate.setDate(10);
		return {
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD')
		};
	};

	const getMobileEventQueryDateRange = (date) => {
		const start = moment(selectedDate).startOf('week');

		const days = [];
		for (let i = 0; i <= 6; i++) {
			days.push(moment(start).add(i, 'days').toDate());
		}
		setWeekSelection(days);
		return getEventQueryDateRange(date);
	};

	const updateQueryResults = (date) => {
		let dateRange;
		if (isSmallScreen) {
			dateRange = getMobileEventQueryDateRange(date);
		} else {
			dateRange = getEventQueryDateRange(date);
		}
		const selectedDisciplines = selectedDisciplineOptions.map(
			(value) => value.value
		);
		const allDisciplines = disciplines.map((value) => value.value);
		const filteredDisciplinesArray = allDisciplines.filter((value) =>
			selectedDisciplines.includes(value)
		);

		const selectedEventOrganisers = selectedEventOrganiserOptions.map(
			(value) => value.value
		);
		const allEventOrganisers = eventOrganisers.map((value) => value.value);
		const filteredEventOrganisersArray = allEventOrganisers.filter((value) =>
			selectedEventOrganisers.includes(value)
		);
		fetchEvents(
			filteredDisciplinesArray,
			filteredEventOrganisersArray,
			dateRange
		);
	};

	const updateFilterDate = (filterDate) => {
		if (!filterDate) {
			setSelectedDate(new Date());
			localStorage.setItem('selectedDate', JSON.stringify(new Date()));
		} else {
			setSelectedDate(filterDate);
			localStorage.setItem('selectedDate', JSON.stringify(filterDate));
		}
	};

	const onSearchClick = () => {
		updateQueryResults(selectedDate);
	};

	const eventStyleGetter = (event) => {
		var style = {
			backgroundColor: eventColourMap(event),
			borderRadius: '5px',
			opacity: 0.8,
			color: 'black',
			border: '0px',
			display: 'block',
			textTransform: event.isCancelled ? 'line-through' : ''
		};
		return {
			style: style
		};
	};

	const Event = (event) => {
		const [isPopoverOpen, setIsPopoverOpen] = useState(false);
		return (
			<div>
				<Popover
					isOpen={isPopoverOpen}
					positions={['top', 'bottom', 'left', 'right']}
					content={<EventPopover event={event.event} setHeight={false} />}
					padding={10}
					containerStyle={{ 'z-index': 25, width: '275px' }}
				>
					<div
						onMouseLeave={() => setIsPopoverOpen(false)}
						onMouseEnter={() => setIsPopoverOpen(true)}
						onClick={() => navigate(`/event/${event.event.id}`)}
					>
						<div
							style={{
								textDecoration: event.event.isCancelled ? 'line-through' : ''
							}}
						>
							{event.event.isCancelled && (
								<img
									src={cancelEventIcon}
									style={{ height: 14, marginRight: 3 }}
								/>
							)}
							{event.title}
						</div>
					</div>
				</Popover>
			</div>
		);
	};

	const CustomToolbar = (props) => {
		const goToBack = () => {
			props.date.setMonth(props.date.getMonth() - 1);
			props.onNavigate('prev');
		};

		const goToNext = () => {
			props.date.setMonth(props.date.getMonth() + 1);
			props.onNavigate('next');
		};

		const label = () => {
			const date = moment(props.date);
			return (
				<span>
					<b>
						{date.format('MMMM')}
						<span> {date.format('YYYY')}</span>
					</b>
				</span>
			);
		};

		return (
			<div className={classes.toolbarContainer}>
				<div className={classes.backNextButtons}>
					<div className={classes.btnBack} onClick={goToBack}>
						<img src={Triangle} className={classes.arrowLeft} />
					</div>
					<label className={classes.labelDate}>{label()}</label>
					<div className={classes.btnNext} onClick={goToNext}>
						<img src={Triangle} className={classes.arrowRight} />
					</div>
				</div>
				{/* <div style={{ float: 'right' }}>
					<MultiSelectAll
						label="EVENT ORGANISER"
						options={eventOrganisers}
						selectedOptions={selectedEventOrganiserOptions}
						setSelectedOptions={setSelectedEventOrganiserOptions}
					/>
				</div>
				<div style={{ float: 'right' }}>
					<MultiSelectAll
						label="DISCIPLINES"
						options={disciplines}
						selectedOptions={selectedDisciplineOptions}
						setSelectedOptions={setSelectedDisciplineOptions}
					/>
				</div> */}
			</div>
		);
	};

	const MobileToolBar = (props) => {
		const goToBack = () => {
			props.date.setMonth(props.date.getMonth() - 1);
			props.onNavigate(props.date);
		};

		const goToNext = () => {
			props.date.setMonth(props.date.getMonth() + 1);
			props.onNavigate(props.date);
		};

		const goWeekBack = () => {
			props.date.setDate(props.date.getDate() - 7);
			props.onNavigate(props.date);
		};

		const goWeekNext = () => {
			props.date.setDate(props.date.getDate() + 7);
			props.onNavigate(props.date);
		};

		const label = () => {
			const date = moment(props.date);
			return (
				<span>
					<b>
						{date.format('MMMM')}
						<span> {date.format('YYYY')}</span>
					</b>
				</span>
			);
		};

		return (
			<>
				{props.fullbar && (
					<div className={classes.toolbarContainer}>
						<div className={classes.backNextButtons}>
							<div className={classes.btnBack} onClick={goToBack}>
								<img src={Triangle} className={classes.arrowLeft} />
							</div>
							<label className={classes.labelDate}>{label()}</label>
							<div className={classes.btnNext} onClick={goToNext}>
								<img src={Triangle} className={classes.arrowRight} />
							</div>
						</div>
						{/* <div style={{ float: 'right' }}>
							<MultiSelectAll
								label="EVENT ORGANISER"
								options={eventOrganisers}
								selectedOptions={selectedEventOrganiserOptions}
								setSelectedOptions={setSelectedEventOrganiserOptions}
							/>
							<MultiSelectAll
								label="DISCIPLINES"
								options={disciplines}
								selectedOptions={selectedDisciplineOptions}
								setSelectedOptions={setSelectedDisciplineOptions}
							/>
						</div> */}
					</div>
				)}
				<div className={classes.backNextWeekButtons}>
					<div className={classes.btnBack} onClick={goWeekBack}>
						<img src={Triangle} className={classes.arrowLeft} />
					</div>
					<span className={classes.labelDateWeek}>Week {selectedWeek}</span>
					<div className={classes.btnNext} onClick={goWeekNext}>
						<img src={Triangle} className={classes.arrowRight} />
					</div>
				</div>
			</>
		);
	};

	const DateHeader = ({ date, label }) => {
		let eventsCount = 0;

		eventsList.forEach((event) => {
			if (
				moment(date).isBetween(
					moment(
						new Date(
							event?.start?.getFullYear(),
							event?.start?.getMonth(),
							event?.start?.getDate(),
							0,
							0,
							0,
							0
						)
					),
					moment(
						new Date(
							event?.end?.getFullYear(),
							event?.end?.getMonth(),
							event?.end?.getDate(),
							11,
							59,
							59,
							9999
						)
					),
					null,
					'[]'
				)
			) {
				eventsCount++;
			}
		});

		return (
			<div className={classes.dateHeader}>
				<div>{label}</div>
				{eventsCount !== 0 && (
					<span className={classes.eventCount}>
						{eventsCount} EVENT{eventsCount > 1 ? 'S' : ''}
					</span>
				)}
			</div>
		);
	};

	return (
		<PageContainer className={classes.container}>
			<SEO title="Off The Track Events" />
			<EventsBanner />
			{upcomingEvents && <EventsUpcoming upcomingEvents={upcomingEvents} />}
			<Container className={classes.filterContainer}>
				<div
					className={classes.addFilter}
					onClick={() => setToggleFilter(!toggleFilter)}
				></div>
				<Accordion
					title="Add Filters"
					isOpen={toggleFilter}
					isSmallScreen
					styles={{ marginTop: 15 }}
				>
					<div style={{ float: 'right' }}></div>
					<Container
						style={
							isSmallScreen
								? { background: '#ededed', minWidth: 100, paddingBottom: 20 }
								: { background: '#ededed', paddingBottom: 20 }
						}
					>
						<Row>
							<Col
								xs={12}
								lg={3}
								style={isSmallScreen ? { paddingBottom: 10 } : ''}
							>
								<div
									className={
										isSmallScreen
											? classes.filterContainerHeaderMobile
											: classes.filterContainerHeader
									}
								></div>
								{!isSmallScreen && (
									<FormDatePicker
										name="filterDate"
										format="MM/yyyy"
										styles={{ width: '100%', color: 'blue' }}
										customDatePicker
										DateValue={selectedDate}
										updateSelection={(value) => updateFilterDate(value)}
										minDate={getMinDate(26)}
										showMonthYearPicker
									/>
								)}
								{isSmallScreen && (
									<FormDatePicker
										name="filterDate"
										styles={{ width: '100%', color: 'blue' }}
										customDatePicker
										DateValue={selectedDate}
										updateSelection={(value) => updateFilterDate(value)}
										minDate={getMinDate(26)}
									/>
								)}
							</Col>
							<Col
								style={isSmallScreen ? { paddingBottom: 10 } : ''}
								xs={12}
								lg={3}
							>
								<MultiSelectAll
									label="EVENT ORGANISER"
									options={eventOrganisers}
									selectedOptions={selectedEventOrganiserOptions}
									setSelectedOptions={setSelectedEventOrganiserOptions}
								/>
							</Col>
							<Col
								style={isSmallScreen ? { paddingBottom: 10 } : ''}
								xs={12}
								lg={3}
							>
								<MultiSelectAll
									label="DISCIPLINES"
									options={disciplines}
									selectedOptions={selectedDisciplineOptions}
									setSelectedOptions={setSelectedDisciplineOptions}
								/>
							</Col>
							<Col xs={12} lg={3}>
								<div style={{ flexGrow: 1 }}>
									<input
										type="button"
										value="Search"
										className={classes.searchButton}
										// style={buttonStyles}
										onClick={() => onSearchClick()}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</Accordion>
			</Container>

			{!isSmallScreen && (
				<div className={classes.calendar}>
					<Calendar
						localizer={localizer}
						components={{
							event: Event,
							toolbar: (props) => (
								<CustomToolbar disciplines={disciplines} {...props} />
							),
							month: {
								dateHeader: DateHeader
							}
						}}
						events={eventsList}
						date={selectedDate}
						defaultView="month"
						style={{ minHeight: '100vh' }}
						eventPropGetter={eventStyleGetter}
						popup={true}
						views={{ month: true }}
						onNavigate={(date) => {
							setSelectedDate(date);
							updateQueryResults(date);
						}}
						tooltipAccessor={null}
					/>
					<LoaderSpinner
						id="spinner"
						status={isLoading}
						styles={{ position: 'absolute', top: '50%', left: '50%' }}
					/>
				</div>
			)}
			{isSmallScreen && (
				<>
					<MobileToolBar
						date={selectedDate}
						onNavigate={(date) => {
							setSelectedDate(date);
							setSelectedWeek(moment(date).week());
							updateQueryResults(date);
						}}
						fullbar
					/>
					{weekSelection.map((day) => {
						let eventsOnDay = eventsList.filter((ev) =>
							moment(day).isBetween(
								moment(ev.start).toDate(),
								moment(ev.end).toDate(),
								'date',
								[]
							)
						);
						return (
							<div key={day.getDate()} className={classes.mobileDateContainer}>
								<div className={classes.dateLabel}>
									<div className={classes.dayLabel}>
										{day.toLocaleDateString('en-US', { day: 'numeric' })}
									</div>
									<div className={classes.dowLabel}>
										{day.toLocaleDateString('en-US', { weekday: 'short' })}
									</div>
								</div>
								<div style={{ minWidth: '75%' }}>
									{eventsOnDay.length === 0 && (
										<div className={classes.noEvents}>No Events</div>
									)}
									{eventsOnDay.length > 0 && (
										<>
											{eventsOnDay.map((event) => {
												return (
													<>
														<EventPopover event={event} key={event.id} />
														<br />
													</>
												);
											})}
										</>
									)}
								</div>
								<LoaderSpinner
									id="spinner"
									status={isLoading}
									styles={{ position: 'absolute', top: '50%', left: '50%' }}
								/>
							</div>
						);
					})}
					<MobileToolBar
						date={selectedDate}
						onNavigate={(date) => {
							setSelectedDate(date);
							setSelectedWeek(moment(date).week());
							updateQueryResults(date);
						}}
					/>
				</>
			)}
		</PageContainer>
	);
};
export default Events;
