import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	generateApplicationRehabSubsidyEmail,
	saveRehabilitationSubsidyFormStatus
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { formatDateReadableVariant } from '../../utils/helpers';
import SNSAdmin from '../SNSAdmin';
import useFilePreview from '../../hooks/useFilePreview';
import RetirementCategory from '../RetirementCategory/index';
import { APPROVED, REJECTED } from '../../utils/constants';
import { vetReportOpts } from '../RetrainerRehabilitationForm/constants';

const MAXREASONLENGTH = 300;

const RehabilitationSubsidyReviewInfo = ({ data, mobile }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const filePreviewHandler = useFilePreview();
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const [onValidate, setOnValidate] = useState(false);

	const [adminDataErrors, setAdminDataErrors] = useState({
		reasonRetirement: {
			value: false,
			message: `Reason is required*`
		},
		behaviourRetirement: {
			value: false,
			message: `Behaviour is required*`
		},
		conformationalRetirement: {
			value: false,
			message: `Conformational defect/s is required*`
		},
		education: {
			value: false,
			message: `Education is required*`
		},
		educationDetails: {
			value: false,
			message: `Other education - details is required*`
		},
		injuryRetirement: {
			value: false,
			message: `Injury is required*`
		},
		otherInjuryRetirement: {
			value: false,
			message: `Other injury details is required*`
		},
		illnessRetirement: {
			value: false,
			message: `Illness is required*`
		},
		otherIllnessRetirement: {
			value: false,
			message: `Other illness details is required*`
		},
		rehomingHistory: {
			value: false,
			message: `Rehoming history - details is required*`
		},
		otherDetailsRetirement: {
			value: false,
			message: `Other - details is required*`
		},
		behaviourOtherRetirement: {
			value: false,
			message: 'Other is required*'
		},
		vulnerabilityType: {
			value: false,
			message: 'Vulnerability Type is required*'
		}
	});

	let splitOtherVicesRetirement = [];
	let mapBehaviourRetirement = [];
	if (data.behaviourOthersRetirement) {
		splitOtherVicesRetirement = data.behaviourOthersRetirement.split(',');
		mapBehaviourRetirement = splitOtherVicesRetirement.map((otherVices) => ({
			label: otherVices,
			value: otherVices
		}));
	}
	const [adminData, setAdminData] = useState({
		//Retirement
		selectedCategory:
			data.retirementCategory !== null ? data.retirementCategory : 2,
		selectedVulnerabilityType: data.vulnerabilityType
			? data.vulnerabilityType
			: 0,
		selectedReason:
			data.reasonRetirement.length > 0
				? data.reasonRetirement.map((reason) => {
						return { value: reason.id, label: reason.value };
				  })
				: [],
		selectedVicesRetirement:
			data.habitsRetirement.length > 0
				? data.habitsRetirement.map((selectedHabitsRetirement) => {
						return {
							value: selectedHabitsRetirement.id,
							label: selectedHabitsRetirement.value
						};
				  })
				: [],
		otherVicesRetirement: data.behaviourOthersRetirement
			? mapBehaviourRetirement
			: '',
		conformationalDefectsRetirement: data.conformationalDetailsRetirement
			? data.conformationalDetailsRetirement
			: '',
		selectedEducation:
			data.education.length > 0
				? data.education.map((selectedEducation) => {
						return {
							value: selectedEducation.id,
							label: selectedEducation.value
						};
				  })
				: [],
		otherEducationDetails: data.otherEducationDetails
			? data.otherEducationDetails
			: '',
		selectedInjuriesRetirement:
			data.injuriesRetirement.length > 0
				? data.injuriesRetirement.map((injuriesRetirement) => {
						return {
							value: injuriesRetirement.id,
							label: injuriesRetirement.value
						};
				  })
				: [],
		selectedIllnessRetirement:
			data.illnessRetirement.length > 0
				? data.illnessRetirement.map((illnessRetirement) => {
						return {
							value: illnessRetirement.id,
							label: illnessRetirement.value
						};
				  })
				: [],
		otherInjuryDetails: data.otherInjuryDetailsRetirement
			? data.otherInjuryDetailsRetirement
			: '',
		otherIllnessDetails: data.otherIllnessDetailsRetirement
			? data.otherIllnessDetailsRetirement
			: '',
		rehomingHistoryDetails: data.rehomingHistoryDetails
			? data.rehomingHistoryDetails
			: '',
		otherDetailsRetirement: data.otherDetailsRetirement
			? data.otherDetailsRetirement
			: ''
	});

	useEffect(() => {
		setRejectReason(data.reviewMessages === null ? '' : data.reviewMessages);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
	}, []);

	useEffect(() => {
		if (onValidate) {
			evaluateAdminError();
			setOnValidate(false);
		}
	}, [onValidate]);

	const handleMultiFieldChange = (value, name, fieldsToClear) => {
		let items = { ...adminData };
		items[name] = value;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setAdminData(items);
		setOnValidate(true);
	};

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const reject = () => {
		setIsProcessingReject(true);
		const hasError = evaluateAdminError();
		if (hasError) {
			setIsProcessingReject(false);
		} else {
			const payload = rehabSubsidyFormStatusPayload();
			AppSyncService.execute(saveRehabilitationSubsidyFormStatus, {
				id: parseInt(data.horseStatusId),
				reviewNotes: notes,
				status: REJECTED,
				input: payload
			}).then((res) => {
				setIsProcessingReject(false);
				if (res.data?.saveRehabilitationSubsidyFormStatus?.success) {
					AppSyncService.execute(generateApplicationRehabSubsidyEmail, {
						horseCode: payload.horseCode,
						isApproved: false,
						memberExternalId: data.member.externalId
					})
						.then(() => {
							addToast({ Message: 'Form updated', IsSuccess: true });
							navigate(`/review-list`);
						})
						.catch((error) => {
							console.error(error);
							addToast({
								Message: 'Failed to send email notification',
								IsSuccess: false
							});
							navigate(`/review-list`);
						});
				} else {
					addToast({ Message: 'Error processing form', IsSuccess: false });
					setErrorMessage(
						res.data?.saveRehabilitationSubsidyFormStatus?.error?.errorMessage
					);
				}
			});
		}
	};

	const evaluateAdminError = () => {
		let errorItems = { ...adminDataErrors };

		let reasonRetirement = {
			...errorItems['reasonRetirement'],
			value:
				adminData.selectedCategory != 0 && adminData.selectedReason.length === 0
		};
		errorItems['reasonRetirement'] = reasonRetirement;

		let vulnerabilityType = {
			...errorItems['vulnerabilityType'],
			value:
				(adminData.selectedCategory == 3 || adminData.selectedCategory == 4) &&
				adminData.selectedVulnerabilityType == 0
		};
		errorItems['vulnerabilityType'] = vulnerabilityType;

		let behaviourRetirement = {
			...errorItems['behaviourRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value === 2) &&
				adminData.selectedVicesRetirement.length === 0
		};
		errorItems['behaviourRetirement'] = behaviourRetirement;

		let conformationalRetirement = {
			...errorItems['conformationalRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 3) &&
				adminData.conformationalDefectsRetirement.length === 0
		};
		errorItems['conformationalRetirement'] = conformationalRetirement;

		let education = {
			...errorItems['education'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.length === 0
		};
		errorItems['education'] = education;

		let educationDetails = {
			...errorItems['educationDetails'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.some((educ) => educ.value == 4) &&
				adminData.otherEducationDetails.length === 0
		};
		errorItems['educationDetails'] = educationDetails;

		let injuryRetirement = {
			...errorItems['injuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) &&
				adminData.selectedInjuriesRetirement.length === 0
		};
		errorItems['injuryRetirement'] = injuryRetirement;

		let otherInjuryRetirement = {
			...errorItems['otherInjuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) && //@NOTE: If selected reason has injury
				adminData.selectedInjuriesRetirement.some(
					(injury) => injury.value == 12
				) &&
				adminData.otherInjuryDetails.length === 0
		};
		errorItems['otherInjuryRetirement'] = otherInjuryRetirement;

		let illnessRetirement = {
			...errorItems['illnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) &&
				adminData.selectedIllnessRetirement.length === 0
		};
		errorItems['illnessRetirement'] = illnessRetirement;

		let otherIllnessRetirement = {
			...errorItems['otherIllnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) && //@NOTE: If selected reason has illness
				adminData.selectedIllnessRetirement.some(
					(illness) => illness.value == 4
				) &&
				adminData.otherIllnessDetails.length === 0
		};
		errorItems['otherIllnessRetirement'] = otherIllnessRetirement;

		let rehomingHistory = {
			...errorItems['rehomingHistory'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 7) &&
				adminData.rehomingHistoryDetails.length === 0
		};
		errorItems['rehomingHistory'] = rehomingHistory;

		let otherDetailsRetirement = {
			...errorItems['otherDetailsRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 8) &&
				adminData.otherDetailsRetirement.length === 0
		};
		errorItems['otherDetailsRetirement'] = otherDetailsRetirement;

		let behaviourOtherRetirement = {
			...errorItems['behaviourOtherRetirement'],
			value:
				adminData.selectedVicesRetirement.some((vice) => vice.value == 12) &&
				adminData.otherVicesRetirement.length === 0
		};
		errorItems['behaviourOtherRetirement'] = behaviourOtherRetirement;
		setAdminDataErrors(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	const approve = async () => {
		setIsProcessingApprove(true);
		const hasError = evaluateAdminError();
		if (hasError) {
			setIsProcessingApprove(false);
		} else {
			const payload = rehabSubsidyFormStatusPayload();
			const reviewRehabilitationSubsidyFormData = await AppSyncService.execute(
				saveRehabilitationSubsidyFormStatus,
				{
					id: parseInt(data.horseStatusId),
					status: APPROVED,
					input: payload
				}
			);
			setIsProcessingApprove(false);
			if (
				reviewRehabilitationSubsidyFormData.data
					?.saveRehabilitationSubsidyFormStatus?.success
			) {
				AppSyncService.execute(generateApplicationRehabSubsidyEmail, {
					horseCode: payload.horseCode,
					isApproved: true,
					memberExternalId: data.member.externalId
				})
					.then(() => {
						addToast({ Message: 'Form updated', IsSuccess: true });
						navigate(`/review-list`);
					})
					.catch((error) => {
						console.error(error);
						addToast({
							Message: 'Failed to send email notification',
							IsSuccess: false
						});
						navigate(`/review-list`);
					});
				addToast({ Message: 'Form updated', IsSuccess: true });
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					reviewRehabilitationSubsidyFormData.data?.saveHorsePlacementFormStatus
						?.error?.errorMessage
				);
			}
		}
	};

	const rehabSubsidyFormStatusPayload = () => {
		const filteredViceRetirement = adminData.selectedVicesRetirement.map(
			(selectedViceRetirement) => selectedViceRetirement.value
		);
		const filteredEducation = adminData.selectedEducation.map(
			(selectedEduc) => selectedEduc.value
		);
		const filteredInjuryRetirement = adminData.selectedInjuriesRetirement.map(
			(selectedInjuryRetirement) => selectedInjuryRetirement.value
		);
		const filteredIllnessRetirement = adminData.selectedIllnessRetirement.map(
			(selectedIllnessRetirement) => selectedIllnessRetirement.value
		);
		const filteredReasonRetirement = adminData.selectedReason.map(
			(selectedReason) => selectedReason.value
		);

		const input = {
			//Retirement
			retirementCategory: adminData.selectedCategory,
			vulnerabilityType: adminData.selectedVulnerabilityType,
			reasonRetirement: filteredReasonRetirement,
			habitsRetirement: filteredViceRetirement,
			behaviourOthersRetirement:
				adminData.otherVicesRetirement.length === 0
					? ''
					: adminData.otherVicesRetirement.map((item) => item.value).toString(),
			conformationalDetailsRetirement:
				adminData.conformationalDefectsRetirement,
			education: filteredEducation,
			otherEducationDetails: adminData.otherEducationDetails,
			injuriesRetirement: filteredInjuryRetirement,
			otherInjuryDetailsRetirement: adminData.otherInjuryDetails,
			illnessRetirement: filteredIllnessRetirement,
			otherIllnessDetailsRetirement: adminData.otherIllnessDetails,
			rehomingHistoryDetails: adminData.rehomingHistoryDetails,
			otherDetailsRetirement: adminData.otherDetailsRetirement,
			currentInjuryOther: adminData.otherCurrentInjuries,
			previousInjuryOther: adminData.otherPreviousInjuries,
			horseCode: data.horseCode
		};
		return input;
	};

	const getVetReport = () => {
		return vetReportOpts.find((item) => item.id == data.arrangedVetReport)
			?.value;
	};

	const getInjuryTypes = () => {
		let injuries = '';
		injuries = data.injuriesRehab.map((injuries) => injuries.value).join(', ');
		return injuries.length > 0 ? injuries : '-';
	};

	const getIllnessTypes = () => {
		let injuries = '';
		injuries = data.illnessRehab.map((injuries) => injuries.value).join(', ');
		return injuries.length > 0 ? injuries : '-';
	};

	return (
		<>
			<div className={classes.adminReviewBody}>
				{data?.horse && <SNSAdmin horseProfileData={data.horse} />}
				<HeaderBanner
					title="Application for Rehabilitation Subsidy"
					mobile={mobile}
					inline
					styles={{ marginTop: 0, marginBottom: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row>
						<Col xs={12} lg={12}>
							<FormLabelField
								title="Do you want to apply for the Rehabilitation Subsidy?"
								value={data.isRehabSubsidy ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Date rehabilitation commenced*"
								value={
									data.dateRehabCommenced
										? formatDateReadableVariant(data.dateRehabCommenced)
										: ''
								}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Had this hose commenced retraining?"
								value={data.hasHorseCommencedRetraining ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Veterinary assessment report/s
								</div>
								<UploadPhoto
									data={data.rehabSubsidyUploads}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="What date was the vet assessment completed?*"
								value={
									data.dateVetAssessment
										? formatDateReadableVariant(data.dateVetAssessment)
										: ''
								}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Who arranged the vet report?*"
								value={getVetReport()}
							/>
						</Col>
						{data.arrangedVetReport == 4 ? (
							<Col xs={12} lg={4}>
								<FormLabelField title="Other" value={data.arrangedVetOther} />
							</Col>
						) : null}
						<Col xs={12} lg={4}>
							<FormLabelField
								title="How many weeks rehabilitation does this horse require?*"
								value={data.rehabWeek}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="What date to you intend to bring this horse back into work?*"
								value={
									data.dateBackToWork
										? formatDateReadableVariant(data.dateBackToWork)
										: ''
								}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Injury Type" value={getInjuryTypes()} />
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Illness Type" value={getIllnessTypes()} />
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}></Col>
						{data.injuriesRehab.some((item) => item.id == 12) ? (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Other Injury"
									value={
										data.otherInjury
											? data.otherInjury.split(',').join(', ')
											: ''
									}
								/>
							</Col>
						) : null}
						{data.illnessRehab.some((item) => item.id == 4) ? (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Other Illness"
									value={
										data.otherIllness
											? data.otherIllness.split(',').join(', ')
											: ''
									}
								/>
							</Col>
						) : null}
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="Any additional information to support the assessment?"
								value={data.additionalAssessment}
							/>
						</Col>
					</Row>
				</div>
				<AdminReviewFormApproval
					notes={notes}
					updateNotes={(value) => setNotes(value)}
					rejectReason={rejectReason}
					updateRejectReason={(value) => updateRejectReason(value)}
					mobile={mobile}
					isProcessingApprove={isProcessingApprove}
					isProcessingReject={isProcessingReject}
					processReject={reject}
					processApprove={approve}
					status={data.status}
					errorMessage={errorMessage}
					maxReasonCharacters={maxCharacters}
					totalLength={300}
					formName="availableForRetraining"
					retirementCategory={
						<RetirementCategory
							isReadOnly={data.status !== 'Review'}
							adminData={adminData}
							adminDataErrors={adminDataErrors}
							handleMultiFieldChange={handleMultiFieldChange}
						/>
					}
					notifNotRequired
				/>
			</div>
		</>
	);
};
export default RehabilitationSubsidyReviewInfo;
