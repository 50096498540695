import React, { useState } from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import Sorting_Default_icon from '../../assets/icons/Sorting_Default_icon.svg';
import Sorting_Down_icon from '../../assets/icons/Sorting_Down_icon.svg';
import Sorting_Up_icon from '../../assets/icons/Sorting_Up_icon.svg';
import MessageBar from '../MessageBar';
import { FOSTER, RETRAINER } from '../../utils/constants';
import BasicCheckbox from '../BasicCheckbox';

const ActionDataTable = ({
	headings,
	rows,
	sortHeading,
	sortUpFunction,
	sortDownFunction,
	hasHorseInOriginalList,
	program,
	emptyMessage,
	withCheckBox,
	onSelectAllFullCircle,
	selectAllFullCircle
}) => {
	const classes = useStyles();
	const [sortIcon, setSortIcon] = useState(Sorting_Default_icon);

	const renderRows = () => {
		let numRows = rows.length;
		if (numRows > 0) {
			return rows.map((singleRow, index) => {
				return <React.Fragment key={index}>{singleRow}</React.Fragment>;
			});
		}
	};

	const sort = () => {
		if (sortIcon === Sorting_Down_icon) {
			setSortIcon(Sorting_Up_icon);
			sortUpFunction();
		} else {
			setSortIcon(Sorting_Down_icon);
			sortDownFunction();
		}
	};

	return (
		<table className={classes.table}>
			{headings && (
				<thead className={classes.thead}>
					<tr className={classes.tr}>
						<>
							{headings.map((heading, index) => {
								return (
									<th
										className={
											heading === 'Name' || heading === 'Event Name'
												? classes.thname
												: classes.th
										}
										key={index}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center'
											}}
										>
											{heading}{' '}
											{sortHeading === heading.label && (
												<img src={sortIcon} onClick={() => sort()} />
											)}
										</div>
									</th>
								);
							})}
							{withCheckBox ? (
								<th className={classes.thButton}>
									<BasicCheckbox
										value={selectAllFullCircle}
										disabled={false}
										handleChange={() => {
											onSelectAllFullCircle();
										}}
									/>
								</th>
							) : null}

							<th className={classes.thButton}></th>
						</>
					</tr>
					{program === 'Retirement' && !hasHorseInOriginalList && (
						<tr>
							<td colSpan={8} className={classes.tdEmptyList}>
								<MessageBar type="info">{emptyMessage}</MessageBar>
							</td>
						</tr>
					)}
					{(program === FOSTER && !hasHorseInOriginalList) ||
						(program === RETRAINER && !hasHorseInOriginalList && (
							<tr>
								<td colSpan={8} className={classes.tdEmptyList}>
									{emptyMessage}
								</td>
							</tr>
						))}
				</thead>
			)}
			<tbody className={classes.tbody}>{renderRows()}</tbody>
		</table>
	);
};

ActionDataTable.proptypes = {
	headings: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired
};

export default ActionDataTable;
