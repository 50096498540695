import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import SEO from '../seo';
import { Container, Row, Col, useScreenClass } from 'react-grid-system';
import Accordion from '../Accordion';
import BannerImage from '../../assets/images/header_banner.png';
import MessageBar from '../MessageBar';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import SimpleCheckBox from '../SimpleCheckBox';
import PrimaryButton from '../PrimaryButton';
import { navigate } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService';
import {
	checkDropDownValue,
	checkNotEqualDropDownValue,
	formatDate,
	formatDateReadableVariant
} from '../../utils/helpers';
import {
	APPROVED,
	BLANK_OPTION,
	CommonKeys,
	DEFAULT_COUNTRY,
	REVIEW
} from '../../utils/constants';
import {
	// 	convertToSortedMultiListData,
	convertToSortedMultiListDataClubs
} from '../../utils/helpers';
import axios from 'axios';
import {
	deleteImagesFromS3,
	saveAcknowledgedRetirementForm,
	getUserImage,
	uploadImageToS3,
	saveUploadedRetirementFile,
	updateAcknowledgedRetirementFormStatus,
	getAcknowledgedRetirementForm,
	deleteImageFromS3,
	deleteUploadedRetirementFile,
	deleteAcknowledgedRetirementForm,
	getAcknowledgedRetirementReferences
} from '../../graphql/custom.queries';
import {
	validation,
	MULTI_DEPENDENT,
	TOGGLE_DEPENDENT,
	MULTI_INPUT_FIELD_WITH_DROPDOWN,
	REGEX,
	MULTI_INPUT_FIELD,
	MIN_LENGTH,
	DEPENDENT_DROPDOWN_WITH_TOGGLE,
	DEPENDENT_DROPDOWN_NOT_EQUAL_WITH_TOGGLE
} from '../AcknowledgedRetrainer/validation';
import FormTextField from '../FormTextField';
import ResizingTextArea from '../ResizingTextArea';
import Toggle from '../Toggle';
import Lightbox from 'react-image-lightbox';
import UploadPhoto from '../UploadPhoto';
import ConfirmationModal from '../ConfirmationModal';
import LoaderSpinner from '../LoaderSpinner';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import FormMultipleInputs from '../FormMultipleInputs';
import FormDatePicker from '../FormDatePicker';
import FormDropDown from '../FormDropDown';
import FormMultiSelectList from '../FormMultiSelectList';
import StateSelector from '../StateSelector';
import CountrySelector from '../CountrySelector';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import {
	DASHBOARD_ROUTE,
	ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
} from '../../utils/constants/routes';
import NewTabLink from '../NewTabLink';
import classNames from 'classnames';
import { getDataModel, setDataModel } from './helper';
import { SightedUploadedText } from '../AcknowledgedRetrainer';
import { AcknowledgedAppFormKeys } from './../../utils/constants/index';

const validationFields = [
	'dateOfBirth',
	'contactNumber',
	'resAddress',
	'resSuburb',
	'resState',
	'resPostcode',
	'resCountry',
	'posAddress',
	'posSuburb',
	'posState',
	'posPostcode',
	'posCountry',
	'staAddress',
	'staSuburb',
	'staState',
	'staPostcode',
	'staCountry',
	'propertyRented',
	'passportDriverLicense',
	//Business Details
	'companyBusiness',
	'companyBusinessName',
	'businessStructure',
	'abn',
	'businessName',
	'additionalBusinessDetails',
	'additionalEmployment',
	'typeOfEmployment',
	'additionalEmploymentTypeDetails',
	'otherBusinessActivity',
	'otherBusinessActivityDetails',
	'ownAllShares',
	'additionalOwnAllShares',
	'trusteeName',
	'trusteeAcn',
	'partnerList',
	'trustOwnAllShares',
	'additionalTrustOwnAllShares',
	//Business Supplies
	'veterinaryName',
	'veterinaryRegular',
	'veterinaryAddress',
	'veterinaryPhone',
	'veterinaryEmail',
	'farrier',
	'farrierPhone',
	'farrierEmail',
	// Credit References
	'creditRef',
	// Staff
	'staffList',
	//Insurance
	'publicLiabilityPolicyNumber',
	'publicLiabilityExpiry',
	'publicLiabilityCoverage',
	'publicLiabilityInsurerName',
	'publicLiabilityInsuredPartyName',
	'professionalIndemnity',
	'indemnityInsurerName',
	'indemnityInsuredPartyName',
	'professionalIndemnityPolicyNumber',
	'professionalIndemnityExpiry',
	'professionalIndemnityCoverage',
	'workerCompensation',
	'workersCompensationInsuredPartyName',
	'workerCompensationExpiry',
	'workerCompensationPolicyNumber',
	'workerCompensationCoverage',
	'insuranceCertificateOfCurrency',
	//Experience
	'ridingComExperience',
	'ackFarmSupplierQuestion',
	'expectationFromRVQuestion',
	'hadFormalAgreementForAgistmentQuestion',
	'outlineApproachQuestion',
	'outlineResponsibilityQuestion',
	'clientRefUploads',
	'agistmentFacilitiesUploads',
	'listMembership',
	'otherMembership',
	//Current
	'currentLicenseType',
	'currentLicenseNumber',
	'currentLicenseStartDate',
	//Previous
	'previousLicenseType',
	'previousLicenseNumber',
	'previousLicenseStartDate',
	//Declaration
	'subjectedToAnimalWelfare',
	'subjectedToAnimalWelfareDetails',
	'anyBan',
	'anyBanDetails',
	'disqualified',
	'disqualifiedDetails',
	'guiltyOfOffense',
	'guiltyOfOffenseDetails',
	'anyCriminalCharges',
	'anyCriminalChargesDetails',
	'bankruptcy',
	'bankruptcyDetails',
	//Acknowledged
	'supportPromoteEquineWelfare',
	'policeRecordCert',
	'criminalCharges',
	'personalBankruptcy',
	'readAndAgree',
	'utiliseToSubmitReport',
	'declareApplicationTrueAndCorrect'
];

import FormLabelField from '../FormLabelField';
import BasicCheckbox from '../BasicCheckbox';

const RV_ACKNOWLEDGED_RETIREMENT_RELEVANT_ONLY_VIC_MESSAGE =
	'RV Acknowledged Retirement Supplier are only relevant to Retirement Supplier with a Victorian stable address.';

const AcknowledgedRetirementForm = () => {
	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};
	const hrefLink = useRef(null);
	const classes = useStyles();
	const { addToast } = useToast();
	const { currentUser } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [validationOn, setValidationOn] = useState(false);
	const [errors, setErrors] = useState({});
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [imgSrcPdf, setImageSrcPdf] = useState(null);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [formDataReceived, setFormDataReceived] = useState(null);
	const [referenceEmploymentType, setReferenceEmploymentType] = useState([]);
	const [referenceStaffRoles, setReferenceStaffRoles] = useState([]);
	// const [
	// 	referenceSourceForHorseTypes,
	// 	setReferenceSourceForHorseTypes
	// ] = useState([]);
	const [referenceLicenseTypes, setReferenceLicenseTypes] = useState([]);
	// const [
	// 	referenceNumberOfHorses,
	// 	setReferenceNumberOfHorses
	// ] = useState([]);
	const [referenceClubMemberships, setReferenceClubMemberships] = useState([]);
	// const [
	// 	referenceDisciplines,
	// 	setReferenceDisciplines
	// ] = useState([]);
	const [formId, setFormId] = useState(null);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [businessTypes, setBusinessTypes] = useState([]);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [values, setValues] = useState({
		// Personal Details
		firstName: {
			value: ''
		},
		email: {
			value: ''
		},
		lastName: {
			value: ''
		},
		dateOfBirth: {
			value: null,
			isRequired: false,
			conditions: [],
			errorMessage: 'Date of birth is required'
		},
		contactNumber: {
			value: '',
			isRequired: true,
			conditions: [],
			errorMessage: 'Contact number is required'
		},
		resAddress: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'Residential address is required'
		},
		resSuburb: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'Residential suburb is required'
		},
		resState: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'Residential state is required'
		},
		resPostcode: {
			value: null,
			isRequired: true,
			conditions: [
				{
					condition: REGEX,
					regex: '^[0-9]*$',
					errorMessage: 'Numeric value for postcode required'
				}
			],
			errorMessage: 'Residential postcode is required'
		},
		resCountry: {
			value: DEFAULT_COUNTRY,
			isRequired: true,
			conditions: [],
			errorMessage: 'Residential country is required'
		},
		isResidentialAddressManual: {
			value: false
		},
		isPostalAddressDifferent: {
			value: false
		},
		posAddress: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isPostalAddressDifferent',
					value: true,
					errorMessage: 'Postal address is required'
				}
			]
		},
		posSuburb: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isPostalAddressDifferent',
					value: true,
					errorMessage: 'Postal suburb is required'
				}
			]
		},
		posState: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isPostalAddressDifferent',
					value: true,
					errorMessage: 'Postal state is required'
				}
			]
		},
		posPostcode: {
			value: null,
			conditions: [
				{
					condition: REGEX,
					regex: '^[0-9]*$',
					errorMessage: 'Numeric value for postcode required',
					conditional: 'isPostalAddressDifferent',
					conditionalValue: true
				},
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isPostalAddressDifferent',
					value: true,
					errorMessage: 'Postal postcode is required'
				}
			]
		},
		posCountry: {
			value: DEFAULT_COUNTRY,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isPostalAddressDifferent',
					value: true,
					errorMessage: 'Postal country is required'
				}
			]
		},
		isPostalAddressManual: {
			value: false
		},
		staAddress: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isStableSameAsResidential',
					value: false,
					errorMessage: 'Stable address is required'
				}
			]
		},
		staSuburb: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isStableSameAsResidential',
					value: false,
					errorMessage: 'Stable suburb is required'
				}
			]
		},
		staState: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isStableSameAsResidential',
					value: false,
					errorMessage: 'Stable state is required'
				}
			]
		},
		staPostcode: {
			value: null,
			conditions: [
				{
					condition: REGEX,
					regex: '^[0-9]*$',
					errorMessage: 'Numeric value for postcode required',
					conditional: 'isStableSameAsResidential',
					conditionalValue: false
				},
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isStableSameAsResidential',
					value: false,
					errorMessage: 'Stable postcode is required'
				}
			]
		},
		staCountry: {
			value: DEFAULT_COUNTRY,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isStableSameAsResidential',
					value: false,
					errorMessage: 'Stable country is required'
				}
			]
		},
		isStableAddressManual: {
			value: false
		},
		isStableSameAsResidential: {
			value: false
		},
		preFilledAddress: {
			value: false
		},
		propertyRented: {
			value: false,
			conditions: []
		},
		passportDriverLicense: {
			value: [],
			isRequired: true,
			conditions: [],
			errorMessage: 'Passport/Driver licence required'
		},
		//Business Details
		companyBusiness: {
			value: false,
			isRequired: false,
			conditions: []
		},
		companyBusinessName: {
			value: null,
			isRequired: false,
			conditions: []
		},
		businessStructure: {
			value: 0,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'companyBusiness',
					value: true,
					errorMessage: 'Structure of business is required'
				}
			]
		},
		ownAllShares: {
			value: false,
			isRequired: false,
			conditions: []
		},
		additionalOwnAllShares: {
			value: '',
			isRequired: false,
			conditions: []
		},
		partnerList: {
			value: [{ partnerName: '', partnerDetails: '' }],
			isRequired: false,
			conditions: [
				{
					condition: MULTI_INPUT_FIELD,
					field: 'businessStructure',
					value: 4,
					errorMessage:
						'Full Name & Contact Details are required for each partner.'
				}
			]
		},
		trusteeName: {
			value: '',
			isRequired: false,
			conditions: []
		},
		trusteeAcn: {
			value: '',
			isRequired: false,
			conditions: []
		},
		trustOwnAllShares: {
			value: false,
			isRequired: false,
			conditions: []
		},
		additionalTrustOwnAllShares: {
			value: '',
			isRequired: false,
			conditions: []
		},
		abn: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'companyBusiness',
					value: true,
					errorMessage: 'Abn is required'
				}
			]
		},
		businessName: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'companyBusiness',
					value: true,
					errorMessage: 'Business Name is required'
				}
			]
		},
		additionalBusinessDetails: {
			value: '',
			isRequired: false,
			conditions: []
		},
		additionalEmployment: {
			value: false,
			isRequired: false,
			conditions: []
		},
		typeOfEmployment: {
			value: 0,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'additionalEmployment',
					value: true,
					errorMessage: 'Type of employment is required'
				}
			]
		},
		additionalEmploymentTypeDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'additionalEmployment',
					value: true,
					errorMessage: 'Additional details are required'
				}
			]
		},
		otherBusinessActivity: {
			value: false,
			isRequired: false,
			conditions: []
		},
		otherBusinessActivityDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'otherBusinessActivity',
					value: true,
					errorMessage: 'Other activity details are required'
				}
			]
		},
		//Business Supplies
		veterinaryName: {
			value: null,
			isRequired: false,
			conditions: []
		},
		veterinaryRegular: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'Regular veterinarian is required'
		},
		veterinaryAddress: {
			value: null,
			isRequired: false,
			conditions: []
		},
		veterinaryPhone: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'Veterinarian phone is required'
		},
		veterinaryEmail: {
			value: null,
			isRequired: false,
			conditions: []
		},
		farrier: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'Farrier is required'
		},
		farrierPhone: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'Farrier phone is required'
		},
		farrierEmail: {
			value: null,
			isRequired: false,
			conditions: []
		},
		// Credit References
		creditRef: {
			value: [],
			isRequired: true,
			conditions: [
				{
					condition: MIN_LENGTH,
					minLength: 3,
					errorMessage: 'At least 3 credit references are required'
				}
			]
		},
		// Staff
		anyStaff: {
			value: false
		},
		staffList: {
			value: [{ fullName: '', roleId: 0, roleOther: null }],
			isRequired: false,
			conditions: [
				{
					dependsOn: 'anyStaff',
					dependsOnValue: true,
					condition: MULTI_INPUT_FIELD_WITH_DROPDOWN,
					field: 'fullName',
					dependantArray: [],
					dependantField: 'roleId',
					value: 'Other',
					conditionFieldIfVal: 'roleOther',
					errorMessage: 'Staff details are required'
				}
			]
		},
		//Insurance
		publicLiability: {
			value: false,
			isRequired: true,
			conditions: []
		},
		publicLiabilityPolicyNumber: {
			value: null,
			isRequired: true,
			errorMessage: 'Public liability policy number is required',
			conditions: []
		},
		publicLiabilityExpiry: {
			value: null,
			isRequired: true,
			errorMessage: 'Public liability expiry date is required',
			conditions: []
		},
		publicLiabilityCoverage: {
			value: '',
			isRequired: true,
			errorMessage: 'Public liability coverage is required',
			conditions: []
		},
		publicLiabilityInsurerName: {
			value: '',
			isRequired: true,
			errorMessage: 'Insurer Name is required',
			conditions: []
		},
		publicLiabilityInsuredPartyName: {
			value: '',
			isRequired: true,
			errorMessage: 'Name of Insured Party or Parties is required',
			conditions: []
		},
		professionalIndemnity: {
			value: false,
			isRequired: false,
			conditions: []
		},
		indemnityInsurerName: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'professionalIndemnity',
					value: true,
					errorMessage: 'Professional indemnity insurer name is required'
				}
			]
		},
		indemnityInsuredPartyName: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'professionalIndemnity',
					value: true,
					errorMessage: 'Professional indemnity insured party name is required'
				}
			]
		},
		professionalIndemnityPolicyNumber: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'professionalIndemnity',
					value: true,
					errorMessage: 'Professional indemnity policy number is required'
				}
			]
		},
		professionalIndemnityExpiry: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'professionalIndemnity',
					value: true,
					errorMessage: 'Professional indemnity expiry date is required'
				}
			]
		},
		professionalIndemnityCoverage: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'professionalIndemnity',
					value: true,
					errorMessage: 'Professional indemnity coverage is required'
				}
			]
		},
		workerCompensation: {
			value: false,
			isRequired: false,
			conditions: []
		},
		workerCompensationExpiry: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'workerCompensation',
					value: true,
					errorMessage: 'Workers compensation expiry date is required'
				}
			]
		},
		workersCompensationInsuredPartyName: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'workerCompensation',
					value: true,
					errorMessage: 'Workers compensation policy number is required'
				}
			]
		},
		workerCompensationPolicyNumber: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'workerCompensation',
					value: true,
					errorMessage: 'Workers compensation policy number is required'
				}
			]
		},
		workerCompensationCoverage: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'workerCompensation',
					value: true,
					errorMessage: 'Workers compensation coverage is required'
				}
			]
		},
		insuranceCertificateOfCurrency: {
			value: [],
			isRequired: true,
			errorMessage:
				'At least 1 insurance certificate/s of currency is required',
			conditions: [
				{
					condition: MIN_LENGTH,
					minLength: 1,
					errorMessage:
						'At least 1 insurance certificate/s of currency is required'
				}
			]
		},
		//Experience
		ridingComExperience: {
			value: '',
			isRequired: true,
			conditions: [],
			errorMessage: 'Riding & competition experience details are required'
		},
		listMembership: {
			value: [],
			isRequired: false,
			conditions: []
		},
		otherMembership: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: MULTI_DEPENDENT,
					field: 'listMembership',
					value: 'Other',
					errorMessage: 'Other memberships are required'
				}
			]
		},
		ackFarmSupplierQuestion: {
			value: '',
			isRequired: true,
			conditions: [],
			errorMessage: 'Answer to this question is required'
		},
		expectationFromRVQuestion: {
			value: '',
			isRequired: true,
			conditions: [],
			errorMessage: 'Answer to this question is required'
		},
		hadFormalAgreementForAgistmentQuestion: {
			value: false,
			isRequired: false,
			conditions: []
		},
		outlineApproachQuestion: {
			value: '',
			isRequired: true,
			conditions: [],
			errorMessage: 'Answer to this question is required'
		},
		outlineResponsibilityQuestion: {
			value: '',
			isRequired: true,
			conditions: [],
			errorMessage: 'Answer to this question is required'
		},
		clientRefUploads: {
			value: [],
			isRequired: true,
			conditions: [
				{
					condition: MIN_LENGTH,
					minLength: 2,
					errorMessage: 'At least 2 client references are required'
				}
			]
		},
		agistmentFacilitiesUploads: {
			value: [],
			isRequired: true,
			conditions: [
				{
					condition: MIN_LENGTH,
					minLength: 4,
					errorMessage: 'At least 4 agistment facilities photos are required'
				}
			]
		},
		//Current
		currentLicenseType: {
			value: 0,
			isRequired: false,
			conditions: []
		},
		currentLicenseNumber: {
			value: null,
			isRequired: false,
			conditions: []
		},
		currentLicenseStartDate: {
			value: null,
			isRequired: false,
			conditions: []
		},
		//Previous
		previousLicenseType: {
			value: 0,
			isRequired: false,
			conditions: []
		},
		previousLicenseNumber: {
			value: null,
			isRequired: false,
			conditions: []
		},
		previousLicenseStartDate: {
			value: null,
			isRequired: false,
			conditions: []
		},
		//Declaration
		subjectedToAnimalWelfare: {
			value: false,
			isRequired: false,
			conditions: []
		},
		subjectedToAnimalWelfareDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'subjectedToAnimalWelfare',
					value: true,
					errorMessage: 'Details are required'
				}
			]
		},
		anyBan: {
			value: false,
			isRequired: false,
			conditions: []
		},
		anyBanDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'anyBan',
					value: true,
					errorMessage: 'Details are required'
				}
			]
		},
		disqualified: {
			value: false,
			isRequired: false,
			conditions: []
		},
		disqualifiedDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'disqualified',
					value: true,
					errorMessage: 'Details are required'
				}
			]
		},
		guiltyOfOffense: {
			value: false,
			isRequired: false,
			conditions: []
		},
		guiltyOfOffenseDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'guiltyOfOffense',
					value: true,
					errorMessage: 'Details are required'
				}
			]
		},
		anyCriminalCharges: {
			value: false,
			isRequired: false,
			conditions: []
		},
		anyCriminalChargesDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'anyCriminalCharges',
					value: true,
					errorMessage: 'Details are required'
				}
			]
		},
		bankruptcy: {
			value: false,
			isRequired: false,
			conditions: []
		},
		bankruptcyDetails: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'bankruptcy',
					value: true,
					errorMessage: 'Details are required'
				}
			]
		},
		//Acknowledged
		supportPromoteEquineWelfare: {
			value: false,
			isRequired: true,
			conditions: []
		},
		policeRecordCert: {
			value: false,
			isRequired: true,
			conditions: []
		},
		criminalCharges: {
			value: false,
			isRequired: true,
			conditions: []
		},
		personalBankruptcy: {
			value: false,
			isRequired: true,
			conditions: []
		},
		readAndAgree: {
			value: false,
			isRequired: true,
			conditions: []
		},
		utiliseToSubmitReport: {
			value: false,
			isRequired: true,
			conditions: []
		},
		declareApplicationTrueAndCorrect: {
			value: false,
			isRequired: true,
			conditions: []
		},
		status: {
			value: null
		}
	});

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			if (!img.key.includes('.pdf')) {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			} else {
				setImageSrcPdf(data.data.getUserImage.signedUrl);
			}
		});
	};

	useEffect(() => {
		if (imgSrcPdf != null) {
			hrefLink.current.click();
		}
	}, [imgSrcPdf]);

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const handleDateUpdate = (value, name) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			let items = { ...values };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		} else {
			let items = { ...values };
			let item = {
				...items[name],
				value: null
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		}
	};

	const handleChange = (e) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: e.target.value
		};
		items[e.target.name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleMultiFieldChange = (name, val) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleAddNewStaff = () => {
		let items = { ...values };
		let item = {
			...items['staffList'],
			value: [
				...values.staffList.value,
				{ fullName: '', roleId: 0, roleOther: null }
			]
		};
		items['staffList'] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleAddNewPartner = () => {
		let items = { ...values };
		let item = {
			...items['partnerList'],
			value: [
				...values.partnerList.value,
				{ partnerName: '', partnerDetails: '' }
			]
		};
		items['partnerList'] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeAddress = (address, name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: address
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeDropDown = (e, fieldsToClear) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value)
		};
		items[e.target.name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeToggle = (val, name, fieldsToClear) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val
		};
		items[name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeMultiSelect = (val, name, fieldsToClear) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val
		};
		items[name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleCheckbox = (id, name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: !items[name].value
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	//{Resume, CreditReferences, PersonalDetails, Certificates}
	const handlePhotoUpload = (image, name, uploadType) => {
		{
			const headers = {
				'Content-Type': image.type
			};
			const imageKey = { imageKey: image.name };
			AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
				if (data.data != null) {
					let imageKey = data.data.putUserImage.imageKey;
					axios
						.put(data.data.putUserImage.signedUrl, image, { headers: headers })
						.then(() => {
							AppSyncService.execute(saveUploadedRetirementFile, {
								filename: image.name,
								key: imageKey,
								memberExternalId: currentUser?.UserId,
								type: uploadType
							}).then((uploadResponse) => {
								try {
									let items = { ...values };
									setFormId(uploadResponse.data.saveUploadedRetirementFile?.id);
									let dataList = Object.assign([], items[name].value);
									dataList.push({ filename: image.name, key: imageKey });
									let item = {
										...items[name],
										value: dataList,
										isTouched: true
									};
									items[name] = item;
									setValues(items);
									setErrors(validation(values, validationOn, validationFields));
									addToast({ Message: 'File uploaded', IsSuccess: true });
								} catch {
									addToast({
										Message: 'Error Uploading to S3...',
										IsSuccess: false
									});
								}
							});
						});
				}
			});
		}
	};

	const removeImage = (img, name) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteUploadedRetirementFile, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteUploadedRetirementFile) {
							let items = { ...values };
							let dataList = Object.assign([], items[name].value);
							let newDataList = dataList.filter((d) => d.key != img.key);
							let item = {
								...items[name],
								value: newDataList,
								isTouched: true
							};
							items[name] = item;
							setValues(items);
							addToast({ Message: 'File removed', IsSuccess: true });
							setErrors(validation(values, validationOn, validationFields));
						}
					});
				}
			}
		);
	};

	const getListOfKeys = () => {
		let listOfKeys = [];
		if (values.creditRef.value.length > 0) {
			values.creditRef.value.map((d) => {
				//if (!d.isApproved) {
				listOfKeys.push(d.key);
				//}
			});
		}
		if (values.passportDriverLicense.value.length > 0) {
			values.passportDriverLicense.value.map((d) => {
				//if (!d.isApproved) {
				listOfKeys.push(d.key);
				//}
			});
		}
		if (values.clientRefUploads.value.length > 0) {
			values.clientRefUploads.value.map((d) => {
				//if (!d.isApproved) {
				listOfKeys.push(d.key);
				//}
			});
		}
		if (values.agistmentFacilitiesUploads.value.length > 0) {
			values.agistmentFacilitiesUploads.value.map((d) => {
				//if (!d.isApproved) {
				listOfKeys.push(d.key);
				//}
			});
		}
		if (values.insuranceCertificateOfCurrency.value.length > 0) {
			values.insuranceCertificateOfCurrency.value.forEach((d) => {
				listOfKeys.push(d.key);
			});
		}
		return listOfKeys;
	};

	const populateResidentialAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;

		let items = { ...values };
		items['resAddress'].value = address;
		items['resSuburb'].value = addressDetails.Suburb;
		items['resPostcode'].value = addressDetails.Postcode;
		items['resState'].value = addressDetails.State;
		items['resCountry'].value = addressDetails.Country;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const clearPostalAddressFields = () => {
		let items = { ...values };
		items['posAddress'].value = null;
		items['posSuburb'].value = null;
		items['posPostcode'].value = null;
		items['posState'].value = null;
		items['posCountry'].value = DEFAULT_COUNTRY;
		items['isPostalAddressDifferent'].value = !items['isPostalAddressDifferent']
			.value;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const clearStableAddressFields = () => {
		let items = { ...values };
		items['staAddress'].value = null;
		items['staSuburb'].value = null;
		items['staPostcode'].value = null;
		items['staState'].value = null;
		items['staCountry'].value = DEFAULT_COUNTRY;
		items['isStableSameAsResidential'].value = !items[
			'isStableSameAsResidential'
		].value;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const populatePostalAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;

		let items = { ...values };
		items['posAddress'].value = address;
		items['posSuburb'].value = addressDetails.Suburb;
		items['posPostcode'].value = addressDetails.Postcode;
		items['posState'].value = addressDetails.State;
		items['posCountry'].value = addressDetails.Country;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const populateStableAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;

		let items = { ...values };
		items['staAddress'].value = address;
		items['staSuburb'].value = addressDetails.Suburb;
		items['staPostcode'].value = addressDetails.Postcode;
		items['staState'].value = addressDetails.State;
		items['staCountry'].value = addressDetails.Country;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	useEffect(() => {
		setErrors(validation(values, validationOn, validationFields));
	}, [values]);

	useEffect(() => {
		setIsLoading(true);
		if (currentUser != null) {
			AppSyncService.execute(getAcknowledgedRetirementReferences).then(
				(result) => {
					if (result.data) {
						setReferenceClubMemberships(
							convertToSortedMultiListDataClubs(result.data.listMemberships)
						);

						let modifiedlistAckRetrainerStaffRoles =
							result.data.listAckRetrainerStaffRoles;
						modifiedlistAckRetrainerStaffRoles.unshift({ id: 0, value: '' });
						setReferenceStaffRoles(modifiedlistAckRetrainerStaffRoles);

						let modifiedlistAckRetrainerEmplomentTypes =
							result.data.listAckRetrainerEmplomentTypes;
						modifiedlistAckRetrainerEmplomentTypes.unshift({
							id: 0,
							value: ''
						});
						setReferenceEmploymentType(modifiedlistAckRetrainerEmplomentTypes);

						let modifiedlistAckRetrainerLicenseTypes =
							result.data.listAckRetrainerLicenseTypes;
						modifiedlistAckRetrainerLicenseTypes.unshift({ id: 0, value: '' });
						setReferenceLicenseTypes(modifiedlistAckRetrainerLicenseTypes);

						AppSyncService.execute(getAcknowledgedRetirementForm, {
							memberExternalId: currentUser?.UserId
						}).then((data) => {
							if (data.data?.getAcknowledgedRetirementForm) {
								setIsReadOnly(
									data.data?.getAcknowledgedRetirementForm?.status ===
										APPROVED ||
										data.data?.getAcknowledgedRetirementForm?.status === REVIEW
								);
								if (data.data?.getAcknowledgedRetirementForm?.id != null) {
									setFormId(data.data.getAcknowledgedRetirementForm.id);
								}
								setFormDataReceived(data.data?.getAcknowledgedRetirementForm);
								if (data.data.listBusinessType) {
									setBusinessTypes(
										[BLANK_OPTION].concat(data.data.listBusinessType)
									);
									let modelData = getDataModel(
										values,
										data.data?.getAcknowledgedRetirementForm,
										modifiedlistAckRetrainerStaffRoles
									);
									setValues({
										...modelData,
										additionalOwnAllShares: {
											...modelData.additionalOwnAllShares,
											conditions: [
												{
													condition: DEPENDENT_DROPDOWN_WITH_TOGGLE,
													dependantArray: data.data.listBusinessType,
													dependantField: 'businessStructure',
													value: 2,
													errorMessage: 'Further details are required',
													toggleField: 'ownAllShares',
													toggleValue: false
												}
											]
										},
										additionalTrustOwnAllShares: {
											...modelData.additionalTrustOwnAllShares,
											conditions: [
												{
													condition: DEPENDENT_DROPDOWN_WITH_TOGGLE,
													dependantArray: data.data.listBusinessType,
													dependantField: 'businessStructure',
													value: 3,
													errorMessage: 'Further details are required',
													toggleField: 'trustOwnAllShares',
													toggleValue: false
												}
											]
										},
										trusteeName: {
											...modelData.trusteeName,
											conditions: [
												{
													condition: DEPENDENT_DROPDOWN_WITH_TOGGLE,
													dependantArray: data.data.listBusinessType,
													dependantField: 'businessStructure',
													value: 3,
													errorMessage: 'Trustee Name is required',
													toggleField: 'companyBusiness',
													toggleValue: true
												}
											]
										},
										companyBusinessName: {
											...modelData.companyBusinessName,
											conditions: [
												{
													condition: DEPENDENT_DROPDOWN_NOT_EQUAL_WITH_TOGGLE,
													dependantArray: data.data.listBusinessType,
													dependantField: 'businessStructure',
													value: 1,
													errorMessage: 'Further details are required',
													toggleField: 'companyBusiness',
													toggleValue: true
												}
											]
										}
									});
								}
							}
							setIsLoading(false);
						});
					}
				}
			);
		} else {
			setIsLoading(false);
		}
	}, []);

	const screenClassForRender = useScreenClass();

	const renderAcknowledgedRetirementForm = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Acknowledged Retirement Farm - Supplier Application"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain',
									borderRadius: '5px 5px 0px 0px'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1',
									borderRadius: '5px 5px 0px 0px'
							  }
					}
				>
					<MessageBar>
						<div className={classes.formFieldsContainer}>
							<Row>
								<Col xs={12} lg={12}>
									<div>
										Please note that the following attachments are required in
										order to submit your application: <br />
										{isMobile ? (
											''
										) : (
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
										)}
										&bull;&nbsp;A colour copy of your Driver&apos;s Licence or
										Passport. <br />
										{isMobile ? (
											''
										) : (
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
										)}
										&bull;&nbsp;Three (3) signed credit references from industry
										suppliers. <br />
										{isMobile ? (
											''
										) : (
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
										)}
										&bull;&nbsp;Signed references from at least two (2) people
										you have previously agisted horses for. <br />
										{isMobile ? (
											''
										) : (
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
										)}
										<br />
										If your application is approved, you will be contacted by
										the Equine Welfare Team to organise an onsite meeting which
										includes a property assessment. It may take a few weeks for
										the application to be reviewed so please be patient.
									</div>
								</Col>
							</Row>
						</div>
					</MessageBar>
				</Accordion>
			</>
		);
	};

	const renderPersonalDetails = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Personal Details"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain',
									borderRadius: '5px 5px 0px 0px'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1',
									borderRadius: '5px 5px 0px 0px'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.PERSONAL_DETAILS_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>First Name</div>
									<FormTextField
										name="firstName"
										id={AcknowledgedAppFormKeys.FIRST_NAME}
										value={values.firstName.value}
										canEdit={false}
									/>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Last Name</div>
									<FormTextField
										name="lastName"
										id={AcknowledgedAppFormKeys.LAST_NAME}
										value={values.lastName.value}
										canEdit={false}
									/>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Email</div>
									<FormTextField
										name="email"
										id={AcknowledgedAppFormKeys.EMAIL}
										value={values.email.value}
										canEdit={false}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date of Birth</div>
									{formDataReceived?.member?.dateOfBirth && (
										<FormTextField
											name="dateOfBirth"
											id={AcknowledgedAppFormKeys.DATE_OF_BIRTH}
											value={formatDateReadableVariant(
												values.dateOfBirth.value
											)}
											canEdit={false}
										/>
									)}
									{!formDataReceived?.member?.dateOfBirth && (
										<>
											<FormDatePicker
												id={AcknowledgedAppFormKeys.DATE_OF_BIRTH}
												isError={errors.dateOfBirth ? true : false}
												DateValue={values.dateOfBirth.value}
												updateSelection={(value) =>
													handleDateUpdate(value, 'dateOfBirth')
												}
											/>
											<span className={classes.errorText}>
												{errors.dateOfBirth}
											</span>
										</>
									)}
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Contact Number*</div>
									<FormTextField
										name="contactNumber"
										id={AcknowledgedAppFormKeys.CONTACT_NUMBER}
										value={values.contactNumber.value}
										canEdit={!isReadOnly}
										onChange={handleChange}
										styles={
											errors.contactNumber ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.contactNumber}
									</span>
								</div>
							</Col>
						</Row>
					</div>
					<hr className={classes.IdentificationDivider}></hr>
					<div className={classes.formFieldsContainer}>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Residential Address*</div>
									<AddressAutoSuggestion
										value={values.resAddress.value}
										onChange={(val) => handleChangeAddress(val, 'resAddress')}
										id={AcknowledgedAppFormKeys.RESIDENTIAL_ADDRESS}
										onAddressSelect={populateResidentialAddressFields}
										disableAutoSuggestion={
											values.isResidentialAddressManual.value
										}
										formTextFieldStyle={
											errors.resAddress ? { border: '1px solid red' } : {}
										}
										canEdit={!values.preFilledAddress.value}
									/>
									<span className={classes.errorText}>{errors.resAddress}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Residential Suburb*</div>
									<FormTextField
										name="resSuburb"
										id={AcknowledgedAppFormKeys.RESIDENTIAL_SUBURB}
										onChange={handleChange}
										value={values.resSuburb.value}
										canEdit={!values.preFilledAddress.value}
										isDisabled={!values.isResidentialAddressManual.value}
										styles={errors.resSuburb ? { border: '1px solid red' } : {}}
									/>
									<span className={classes.errorText}>{errors.resSuburb}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Residential State*</div>
									{!values.preFilledAddress.value && (
										<StateSelector
											id={AcknowledgedAppFormKeys.RESIDENTIAL_STATE}
											country={values.resCountry.value}
											value={values.resState.value}
											isError={errors.resState ? true : false}
											changeHandler={(value) =>
												handleMultiFieldChange('resState', value)
											}
											isDisabled={!values.isResidentialAddressManual.value}
										/>
									)}
									{values.preFilledAddress.value && (
										<FormTextField
											name="resSuburb"
											id={AcknowledgedAppFormKeys.RESIDENTIAL_STATE}
											value={values.resState.value}
											canEdit={false}
										/>
									)}
									<span className={classes.errorText}>{errors.resState}</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Residential Postcode*
									</div>
									<FormTextField
										name="resPostcode"
										id={AcknowledgedAppFormKeys.RESIDENTIAL_POSTCODE}
										onChange={handleChange}
										value={values.resPostcode.value}
										canEdit={!values.preFilledAddress.value}
										isDisabled={!values.isResidentialAddressManual.value}
										styles={
											errors.resPostcode ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.resPostcode}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Residential Country*</div>
									{!values.preFilledAddress.value && (
										<CountrySelector
											id={AcknowledgedAppFormKeys.RESIDENTIAL_COUNTRY}
											value={values.resCountry.value}
											isError={errors.resCountry ? true : false}
											changeHandler={(newCountryValue) =>
												handleMultiFieldChange('resCountry', newCountryValue)
											}
											isDisabled={!values.isResidentialAddressManual.value}
										/>
									)}
									{values.preFilledAddress.value && (
										<FormTextField
											name="resSuburb"
											id={AcknowledgedAppFormKeys.RESIDENTIAL_COUNTRY}
											value={values.resCountry.value}
											canEdit={false}
										/>
									)}
									<span className={classes.errorText}>{errors.resCountry}</span>
								</div>
							</Col>
						</Row>
						<Row>
							{!values.preFilledAddress.value && (
								<Col xs={12} lg={4}>
									<div className={classes.checkboxFieldContainer}>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'isResidentialAddressManual')
											}
											custom
											customStyle={{ float: 'left' }}
											data={{
												id: AcknowledgedAppFormKeys.MANUAL_RESIDENTIAL_ADDRESS,
												isSelected: values.isResidentialAddressManual.value
											}}
										/>

										<div
											className={classes.checkboxField}
											style={{ fontSize: 13 }}
										>
											Manually add residential address
										</div>
									</div>
								</Col>
							)}
							{isReadOnly ? null : (
								<Col xs={12} lg={4}>
									<div className={classes.checkboxFieldContainer}>
										<SimpleCheckBox
											changeCheckboxState={() => clearPostalAddressFields()}
											custom
											customStyle={{ float: 'left' }}
											data={{
												id: AcknowledgedAppFormKeys.DIFFERENT_POSTAL_ADDRESS,
												isSelected: values.isPostalAddressDifferent.value
											}}
										/>

										<div
											className={classes.checkboxField}
											style={{ fontSize: 13 }}
										>
											I have a different postal address
										</div>
									</div>
								</Col>
							)}
						</Row>
						{values.isPostalAddressDifferent.value && (
							<>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Postal Address*</div>
											<AddressAutoSuggestion
												value={values.posAddress.value}
												onChange={(val) =>
													handleChangeAddress(val, 'posAddress')
												}
												onAddressSelect={populatePostalAddressFields}
												disableAutoSuggestion={
													values.isPostalAddressManual.value
												}
												id={AcknowledgedAppFormKeys.POSTAL_ADDRESS}
												formTextFieldStyle={
													errors.posAddress ? { border: '1px solid red' } : {}
												}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.posAddress}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Postal Suburb*</div>
											<FormTextField
												name="posSuburb"
												id={AcknowledgedAppFormKeys.POSTAL_SUBURB}
												onChange={handleChange}
												value={values.posSuburb.value}
												canEdit={!isReadOnly}
												isDisabled={!values.isPostalAddressManual.value}
												styles={
													errors.posSuburb ? { border: '1px solid red' } : {}
												}
											/>
											<span className={classes.errorText}>
												{errors.posSuburb}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Postal State*</div>
											<StateSelector
												id={AcknowledgedAppFormKeys.POSTAL_STATE}
												country={values.posCountry.value}
												value={values.posState.value}
												isError={errors.posState ? true : false}
												changeHandler={(value) =>
													handleMultiFieldChange('posState', value)
												}
												isDisabled={
													!values.isPostalAddressManual.value || isReadOnly
												}
											/>
											<span className={classes.errorText}>
												{errors.posState}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Postal Postcode*</div>
											<FormTextField
												name="posPostcode"
												id={AcknowledgedAppFormKeys.POSTAL_POSTCODE}
												value={values.posPostcode.value}
												onChange={handleChange}
												canEdit={true}
												isDisabled={
													!values.isPostalAddressManual.value || isReadOnly
												}
												styles={
													errors.posPostcode ? { border: '1px solid red' } : {}
												}
											/>
											<span className={classes.errorText}>
												{errors.posPostcode}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Postal Country*</div>
											<CountrySelector
												id={AcknowledgedAppFormKeys.POSTAL_COUNTRY}
												value={values.posCountry.value}
												isError={errors.posCountry ? true : false}
												changeHandler={(newCountryValue) =>
													handleMultiFieldChange('posCountry', newCountryValue)
												}
												isDisabled={
													!values.isPostalAddressManual.value || isReadOnly
												}
											/>
											<span className={classes.errorText}>
												{errors.posCountry}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									{isReadOnly ? null : (
										<Col xs={12} lg={4}>
											<div className={classes.checkboxFieldContainer}>
												<SimpleCheckBox
													changeCheckboxState={(id) =>
														handleCheckbox(id, 'isPostalAddressManual')
													}
													custom
													customStyle={{ float: 'left' }}
													data={{
														id: AcknowledgedAppFormKeys.MANUAL_POSTAL_ADDRESS,
														isSelected: values.isPostalAddressManual.value
													}}
												/>

												<div
													className={classes.checkboxField}
													style={{ fontSize: 13 }}
												>
													Manually add postal address
												</div>
											</div>
										</Col>
									)}
								</Row>
							</>
						)}
					</div>
					<hr className={classes.IdentificationDivider}></hr>
					<div className={classes.formFieldsContainer}>
						{!values.isStableSameAsResidential.value && (
							<>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Stable Address*</div>
											<AddressAutoSuggestion
												id={AcknowledgedAppFormKeys.STABLE_ADDRESS}
												value={values.staAddress.value}
												onChange={(val) =>
													handleChangeAddress(val, 'staAddress')
												}
												onAddressSelect={populateStableAddressFields}
												disableAutoSuggestion={
													values.isStableAddressManual.value
												}
												formTextFieldStyle={
													errors.staAddress ? { border: '1px solid red' } : {}
												}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.staAddress}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Stable Suburb*</div>
											<FormTextField
												name="staSuburb"
												id={AcknowledgedAppFormKeys.STABLE_SUBURB}
												onChange={handleChange}
												value={values.staSuburb.value}
												canEdit={true}
												isDisabled={
													!values.isStableAddressManual.value || isReadOnly
												}
												styles={
													errors.staSuburb ? { border: '1px solid red' } : {}
												}
											/>
											<span className={classes.errorText}>
												{errors.staSuburb}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Stable State*</div>
											<StateSelector
												id={AcknowledgedAppFormKeys.STABLE_STATE}
												country={values.staCountry.value}
												value={values.staState.value}
												isError={errors.staState ? true : false}
												changeHandler={(value) =>
													handleMultiFieldChange('staState', value)
												}
												isDisabled={
													!values.isStableAddressManual.value || isReadOnly
												}
											/>
											<span className={classes.errorText}>
												{errors.staState ||
													(values.staState.value &&
														values.staState.value !== 'VIC' &&
														RV_ACKNOWLEDGED_RETIREMENT_RELEVANT_ONLY_VIC_MESSAGE)}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Stable Postcode*</div>
											<FormTextField
												name="staPostcode"
												id={AcknowledgedAppFormKeys.STABLE_POSTCODE}
												onChange={handleChange}
												value={values.staPostcode.value}
												canEdit={true}
												isDisabled={
													!values.isStableAddressManual.value || isReadOnly
												}
												styles={
													errors.staPostcode ? { border: '1px solid red' } : {}
												}
											/>
											<span className={classes.errorText}>
												{errors.staPostcode}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Stable Country*</div>
											<CountrySelector
												id={AcknowledgedAppFormKeys.STABLE_COUNTRY}
												value={values.staCountry.value}
												isError={errors.staCountry ? true : false}
												changeHandler={(newCountryValue) =>
													handleMultiFieldChange('staCountry', newCountryValue)
												}
												isDisabled={
													!values.isStableAddressManual.value || isReadOnly
												}
											/>
											<span className={classes.errorText}>
												{errors.staCountry}
											</span>
										</div>
									</Col>
								</Row>
							</>
						)}
						<Row>
							{isReadOnly
								? null
								: !values.isStableSameAsResidential.value && (
										<Col xs={12} lg={4}>
											<div className={classes.checkboxFieldContainer}>
												<SimpleCheckBox
													changeCheckboxState={(id) =>
														handleCheckbox(id, 'isStableAddressManual')
													}
													custom
													customStyle={{ float: 'left' }}
													data={{
														id: AcknowledgedAppFormKeys.MANUAL_STABLE_ADDRESS,
														isSelected: values.isStableAddressManual.value
													}}
												/>

												<div
													className={classes.checkboxField}
													style={{ fontSize: 13 }}
												>
													Manually add stable address
												</div>
											</div>
										</Col>
								  )}
							{isReadOnly ? null : (
								<Col xs={12} lg={5}>
									<div className={classes.checkboxFieldContainer}>
										<SimpleCheckBox
											changeCheckboxState={() => clearStableAddressFields()}
											custom
											customStyle={{ float: 'left' }}
											data={{
												id:
													AcknowledgedAppFormKeys.SAME_STABLE_AS_RESIDENTIAL_ADDRESS,
												isSelected: values.isStableSameAsResidential.value
											}}
										/>

										<div
											className={classes.checkboxField}
											style={{ fontSize: 13 }}
										>
											Stable address is same as residential address
										</div>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										I own the above stable address
									</div>
									<Toggle
										id={AcknowledgedAppFormKeys.I_OWN_STABLE_ADDRESS}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'propertyRented')}
										value={values.propertyRented.value}
										disabled={isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.propertyRented}
									</span>
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Upload a valid passport or driver&apos;s licence (jpg, png,
										pdf)*
									</div>
									<UploadPhoto
										id={AcknowledgedAppFormKeys.VALID_ID}
										formats={['png', 'jpeg', 'pdf']}
										labelName="Upload Identification"
										error={handleUploadError}
										data={values.passportDriverLicense.value}
										sizeLimitMB={5}
										numberOfImages={2}
										success={(image) =>
											handlePhotoUpload(
												image,
												'passportDriverLicense',
												'PersonalDetails'
											)
										}
										getImage={getImage}
										removeImage={(image) =>
											removeImage(image, 'passportDriverLicense')
										}
										textToRender={
											(values.status.value === REVIEW ||
												values.status.value === APPROVED) &&
											values.passportDriverLicense.value?.length > 0 &&
											values.passportDriverLicense.value?.every(
												(item) =>
													item.sightedBy?.id && item.sightedBy?.id !== -1
											) ? (
												<SightedUploadedText />
											) : null
										}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.passportDriverLicense}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderBusinessDetails = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Business Details"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.BUSINESS_DETAILS_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Is your agistment operation run under a company/business?
									</div>
									<Toggle
										id={AcknowledgedAppFormKeys.OPERATION_RUN_UNDER_COMPANY}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'companyBusiness', [
												{ item: 'businessStructure', value: 0 },
												{ item: 'companyBusinessName', value: null },
												{ item: 'abn', value: null },
												{ item: 'businessName', value: null },
												{ item: 'ownAllShares', value: false },
												{ item: 'trusteeName', value: '' },
												{ item: 'trusteeAcn', value: '' },
												{ item: 'trustOwnAllShares', value: false },
												{
													item: 'partnerList',
													value: [{ partnerName: '', partnerDetails: '' }]
												},
												{ item: 'additionalOwnAllShares', value: '' },
												{ item: 'additionalTrustOwnAllShares', value: '' },
												{ item: 'additionalBusinessDetails', value: '' }
											])
										}
										value={values.companyBusiness.value}
										disabled={isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.companyBusiness}
									</span>
								</div>
							</Col>
						</Row>
						{values.companyBusiness.value && (
							<>
								<Row style={{ alignItems: 'flex-end' }}>
									<Col xs={12} lg={4}>
										<div className={classes.multiLineFieldContainer}>
											<div className={classes.fieldTitle}>
												Please select the business structure that applies to
												you:*
											</div>
											<FormDropDown
												showBlank
												name="businessStructure"
												id={AcknowledgedAppFormKeys.BUSINESS_STRUCTURE}
												items={businessTypes}
												selectedId={values.businessStructure.value}
												onChange={(e) =>
													handleChangeDropDown(e, [
														{ item: 'companyBusinessName', value: null },
														{ item: 'ownAllShares', value: false },
														{ item: 'trusteeName', value: '' },
														{ item: 'trusteeAcn', value: '' },
														{ item: 'trustOwnAllShares', value: false },
														{
															item: 'partnerList',
															value: [{ partnerName: '', partnerDetails: '' }]
														},
														{ item: 'additionalOwnAllShares', value: '' },
														{ item: 'additionalTrustOwnAllShares', value: '' }
													])
												}
												styles={
													errors.businessStructure
														? { border: '1px solid red' }
														: {}
												}
												disabled={isReadOnly}
											/>
											<div className={classes.errorTextDropDown}>
												{errors.businessStructure}
											</div>
										</div>
									</Col>
									{values.businessStructure.value !== 0 &&
										checkNotEqualDropDownValue(
											businessTypes,
											values.businessStructure.value,
											'Individual/Sole Trader'
										) && (
											<Col xs={12} lg={4}>
												<div className={classes.fieldContainer}>
													<div className={classes.fieldTitle}>
														What is the name of the company/trust/partnership?*
													</div>
													<FormTextField
														name="companyBusinessName"
														onChange={handleChange}
														id={AcknowledgedAppFormKeys.COMPANY_BUSINESS_NAME}
														value={values.companyBusinessName.value}
														canEdit={!isReadOnly}
														styles={
															errors.companyBusinessName
																? { border: '1px solid red' }
																: {}
														}
													/>
													<span className={classes.errorText}>
														{errors.companyBusinessName}
													</span>
												</div>
											</Col>
										)}
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>ABN/ACN*</div>
											<FormTextField
												name="abn"
												onChange={handleChange}
												id={AcknowledgedAppFormKeys.ABN}
												value={values.abn.value}
												canEdit={!isReadOnly}
												styles={errors.abn ? { border: '1px solid red' } : {}}
											/>
											<span className={classes.errorText}>{errors.abn}</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Business Name*</div>
											<FormTextField
												name="businessName"
												onChange={handleChange}
												id={AcknowledgedAppFormKeys.BUSINESS_NAME}
												value={values.businessName.value}
												canEdit={!isReadOnly}
												styles={
													errors.businessName ? { border: '1px solid red' } : {}
												}
											/>
											<span className={classes.errorText}>
												{errors.businessName}
											</span>
										</div>
									</Col>
								</Row>
								{values.businessStructure.value !== 0 &&
									checkDropDownValue(
										businessTypes,
										values.businessStructure.value,
										'Company'
									) && (
										<>
											<Row>
												<Col xs={12} lg={4}>
													<div className={classes.fieldContainer}>
														<div className={classes.fieldTitle}>
															Do you own all shares in that Company?
														</div>
														<Toggle
															id={
																AcknowledgedAppFormKeys.OWN_ALL_SHARES_IN_THAT_COMPANY
															}
															labelNegative="No"
															labelPositive="Yes"
															onClick={(val) =>
																handleChangeToggle(val, 'ownAllShares')
															}
															value={values.ownAllShares.value}
															disabled={isReadOnly}
														/>
													</div>
												</Col>
											</Row>
											<Row>
												{!values.ownAllShares.value && (
													<Col xs={12}>
														<div className={classes.fieldContainer}>
															<div
																className={classes.fieldTitle}
																style={{
																	float: `${isReadOnly ? 'none' : 'left'}`
																}}
															>
																If not, please provide further details.*
															</div>
															<ResizingTextArea
																borderColor={
																	errors.additionalOwnAllShares
																		? 'red'
																		: '#c7ced1'
																}
																name="additionalOwnAllShares"
																id={
																	AcknowledgedAppFormKeys.ADDITIONAL_OWN_ALL_SHARES
																}
																value={values.additionalOwnAllShares.value}
																onChange={handleChange}
																maxChars={500}
																styles={{
																	marginLeft: 0,
																	marginRight: 0,
																	padding: 0
																}}
																canEdit={!isReadOnly}
															/>
															<span className={classes.errorText}>
																{errors.additionalOwnAllShares}
															</span>
														</div>
													</Col>
												)}
											</Row>
										</>
									)}
								{values.businessStructure.value !== 0 &&
									checkDropDownValue(
										businessTypes,
										values.businessStructure.value,
										'Partnership'
									) && (
										<>
											<Row>
												<Col>
													<div
														className={classes.fieldTitle}
														style={{ marginTop: 10 }}
													>
														Please provide the name and contact details of all
														other partners.*
													</div>
												</Col>
											</Row>
											<div>
												<FormMultipleInputs
													fieldName="Full name*"
													fieldId={AcknowledgedAppFormKeys.PARTNER_NAME}
													fieldRef="partnerName"
													secondFieldName="Contact Details*"
													secondFieldId={
														AcknowledgedAppFormKeys.PARTNER_DETAILS
													}
													secondFieldRef="partnerDetails"
													value={values.partnerList.value}
													addNew={handleAddNewPartner}
													isError={errors.partnerList}
													onChange={(val) =>
														handleMultiFieldChange('partnerList', val)
													}
													inputName="partnerInformation"
													isMobile={isMobile}
													disabled={isReadOnly}
												/>
												<span className={classes.errorText}>
													{errors.partnerList}
												</span>
											</div>
										</>
									)}
								{values.businessStructure.value !== 0 &&
									checkDropDownValue(
										businessTypes,
										values.businessStructure.value,
										'Trust'
									) && (
										<>
											<Row>
												<Col xs={12} lg={4}>
													<div className={classes.fieldContainer}>
														<div className={classes.fieldTitle}>
															Please provide the name of the Trustee.*
														</div>
														<FormTextField
															name="trusteeName"
															onChange={handleChange}
															id={AcknowledgedAppFormKeys.TRUSTEE_NAME}
															value={values.trusteeName.value}
															canEdit={!isReadOnly}
															styles={
																errors.trusteeName
																	? { border: '1px solid red' }
																	: {}
															}
														/>
														<span className={classes.errorText}>
															{errors.trusteeName}
														</span>
													</div>
												</Col>
												<Col xs={12} lg={4}>
													<div className={classes.fieldContainer}>
														<div className={classes.fieldTitle}>
															Please provide the ACN of the Trustee (if
															applicable).
														</div>
														<FormTextField
															name="trusteeAcn"
															onChange={handleChange}
															id={AcknowledgedAppFormKeys.TRUSTEE_ACN}
															value={values.trusteeAcn.value}
															canEdit={!isReadOnly}
															styles={
																errors.trusteeAcn
																	? { border: '1px solid red' }
																	: {}
															}
														/>
														<span className={classes.errorText}>
															{errors.trusteeAcn}
														</span>
													</div>
												</Col>
											</Row>
											<Row>
												<Col xs={12} lg={4}>
													<div className={classes.fieldContainer}>
														<div className={classes.fieldTitle}>
															If the Trustee is a company, do you own all shares
															in that Company?
														</div>
														<Toggle
															id={
																AcknowledgedAppFormKeys.TRUSTEE_OWN_ALL_SHARES_IN_THAT_COMPANY
															}
															labelNegative="No"
															labelPositive="Yes"
															onClick={(val) =>
																handleChangeToggle(val, 'trustOwnAllShares')
															}
															value={values.trustOwnAllShares.value}
															disabled={isReadOnly}
														/>
													</div>
												</Col>
											</Row>
											<Row>
												{!values.trustOwnAllShares.value && (
													<Col xs={12}>
														<div className={classes.fieldContainer}>
															<div
																className={classes.fieldTitle}
																style={{
																	float: `${isReadOnly ? 'none' : 'left'}`
																}}
															>
																If not, please provide further details.*
															</div>
															<ResizingTextArea
																borderColor={
																	errors.additionalTrustOwnAllShares
																		? 'red'
																		: '#c7ced1'
																}
																name="additionalTrustOwnAllShares"
																id={
																	AcknowledgedAppFormKeys.ADDITIONAL_TRUST_OWN_ALL_SHARES
																}
																value={values.additionalTrustOwnAllShares.value}
																onChange={handleChange}
																maxChars={500}
																styles={{
																	marginLeft: 0,
																	marginRight: 0,
																	padding: 0
																}}
																canEdit={!isReadOnly}
															/>
															<span className={classes.errorText}>
																{errors.additionalTrustOwnAllShares}
															</span>
														</div>
													</Col>
												)}
											</Row>
										</>
									)}

								{/*  */}
								<Row>
									<Col xs={12} lg={12}>
										<div className={classes.fieldContainer}>
											<div
												className={classes.fieldTitle}
												style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
											>
												Additional Details
											</div>
											<ResizingTextArea
												borderColor={
													errors.additionalBusinessDetails ? 'red' : '#c7ced1'
												}
												name="additionalBusinessDetails"
												id={AcknowledgedAppFormKeys.ADDITIONAL_BUSINESS_DETAILS}
												value={values.additionalBusinessDetails.value}
												onChange={handleChange}
												maxChars={200}
												styles={{ marginLeft: 0, marginRight: 0 }}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.additionalBusinessDetails}
											</span>
										</div>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Do you have any additional employment (outside of your
										agistment business)?
									</div>
									<Toggle
										id={AcknowledgedAppFormKeys.ADDITIONAL_EMPLOYMENT}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'additionalEmployment', [
												{ item: 'typeOfEmployment', value: 0 },
												{ item: 'additionalEmploymentTypeDetails', value: '' }
											])
										}
										value={values.additionalEmployment.value}
										disabled={isReadOnly}
									/>
								</div>
							</Col>
						</Row>
						{values.additionalEmployment.value && (
							<>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Full Time or Part Time?*
											</div>
											<FormDropDown
												showBlank
												name="typeOfEmployment"
												id={AcknowledgedAppFormKeys.TYPE_OF_EMPLOYMENT}
												items={referenceEmploymentType}
												selectedId={values.typeOfEmployment.value}
												onChange={handleChangeDropDown}
												styles={
													errors.typeOfEmployment
														? { border: '1px solid red' }
														: {}
												}
												disabled={isReadOnly}
											/>
											<div className={classes.errorTextDropDown}>
												{errors.typeOfEmployment}
											</div>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={12}>
										<div className={classes.fieldContainer}>
											<div
												className={classes.fieldTitle}
												style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
											>
												Please provide additional details (Type of work,
												employer, days/hours worked, paid/voluntary)*
											</div>
											<ResizingTextArea
												borderColor={
													errors.additionalEmploymentTypeDetails
														? 'red'
														: '#c7ced1'
												}
												charLengthStyle={
													isMobile ? { float: 'left', marginBottom: 10 } : {}
												}
												name="additionalEmploymentTypeDetails"
												id={
													AcknowledgedAppFormKeys.ADDITIONAL_EMPLOYMENT_TYPE_DETAILS
												}
												value={values.additionalEmploymentTypeDetails.value}
												onChange={handleChange}
												maxChars={500}
												styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.additionalEmploymentTypeDetails}
											</span>
										</div>
									</Col>
								</Row>{' '}
							</>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Other business activities aside from agistment
									</div>
									<Toggle
										id={AcknowledgedAppFormKeys.OTHER_BUSINESS_ACTIVITIES}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'otherBusinessActivity', [
												{ item: 'otherBusinessActivityDetails', value: '' }
											])
										}
										value={values.otherBusinessActivity.value}
										disabled={isReadOnly}
									/>
								</div>
							</Col>
						</Row>
						{values.otherBusinessActivity.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											Please provide other details*
										</div>
										<ResizingTextArea
											borderColor={errors.injuryNature ? 'red' : '#c7ced1'}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="otherBusinessActivityDetails"
											id={
												AcknowledgedAppFormKeys.OTHER_BUSINESS_ACTIVITY_DETAILS
											}
											value={values.otherBusinessActivityDetails.value}
											onChange={handleChange}
											maxChars={500}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.otherBusinessActivityDetails}
										</span>
									</div>
								</Col>
							</Row>
						)}
					</div>
				</Accordion>
			</>
		);
	};

	const renderBusinessSuppliers = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Business Suppliers"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.BUSINESS_SUPPLIERS_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Veterinary practice name
									</div>
									<FormTextField
										name="veterinaryName"
										id={AcknowledgedAppFormKeys.VETERINARY_NAME}
										value={values.veterinaryName.value}
										onChange={handleChange}
										canEdit={!isReadOnly}
										styles={
											errors.veterinaryName ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.veterinaryName}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Regular veterinarian*
									</div>
									<FormTextField
										name="veterinaryRegular"
										id={AcknowledgedAppFormKeys.VETERINARY_REGULAR}
										value={values.veterinaryRegular.value}
										onChange={handleChange}
										canEdit={!isReadOnly}
										styles={
											errors.veterinaryRegular
												? { border: '1px solid red' }
												: {}
										}
									/>
									<span className={classes.errorText}>
										{errors.veterinaryRegular}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Veterinary address</div>
									<FormTextField
										name="veterinaryAddress"
										id={AcknowledgedAppFormKeys.VETERINARY_ADDRESS}
										onChange={handleChange}
										value={values.veterinaryAddress.value}
										canEdit={!isReadOnly}
										styles={
											errors.veterinaryAddress
												? { border: '1px solid red' }
												: {}
										}
									/>
									<span className={classes.errorText}>
										{errors.veterinaryAddress}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Veterinary phone*</div>
									<FormTextField
										name="veterinaryPhone"
										id={AcknowledgedAppFormKeys.VETERINARY_PHONE}
										value={values.veterinaryPhone.value}
										canEdit={!isReadOnly}
										onChange={handleChange}
										styles={
											errors.veterinaryPhone ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.veterinaryPhone}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Veterinary email</div>
									<FormTextField
										name="veterinaryEmail"
										id={AcknowledgedAppFormKeys.VETERINARY_EMAIL}
										value={values.veterinaryEmail.value}
										onChange={handleChange}
										canEdit={!isReadOnly}
										styles={
											errors.veterinaryEmail ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.veterinaryEmail}
									</span>
								</div>
							</Col>
						</Row>
					</div>
					<hr
						className={classes.IdentificationDivider}
						style={{ marginBottom: 0 }}
					></hr>
					<div className={classes.formFieldsContainer}>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Farrier*</div>
									<FormTextField
										name="farrier"
										id={AcknowledgedAppFormKeys.BUSINESS_FARRIER}
										onChange={handleChange}
										value={values.farrier.value}
										canEdit={!isReadOnly}
										styles={errors.farrier ? { border: '1px solid red' } : {}}
									/>
									<span className={classes.errorText}>{errors.farrier}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Farrier phone*</div>
									<FormTextField
										name="farrierPhone"
										id={AcknowledgedAppFormKeys.FARRIER_PHONE}
										value={values.farrierPhone.value}
										onChange={handleChange}
										canEdit={!isReadOnly}
										styles={
											errors.farrierPhone ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.farrierPhone}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Farrier email</div>
									<FormTextField
										name="farrierEmail"
										id={AcknowledgedAppFormKeys.FARRIER_EMAIL}
										value={values.farrierEmail.value}
										onChange={handleChange}
										canEdit={!isReadOnly}
										styles={
											errors.farrierEmail ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.farrierEmail}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderCreditReference = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Credit References"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.CREDIT_REFERENCES_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={12}>
								<MessageBar type="primary">
									A minimum of three (3) signed credit references from industry
									suppliers e.g. feed suppliers, farriers (one must be from your
									veterinarian) to include:
									<br />
									<br />
									{isMobile ? '' : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
									&bull;&nbsp;That you regularly purchase services/goods from
									the supplier; <br />
									{isMobile ? '' : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
									&bull;&nbsp;That you pay promptly for these services/goods;{' '}
									<br />
									{isMobile ? '' : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
									&bull;&nbsp;That, presently, your accounts are up to date.
								</MessageBar>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Upload credit references (jpg, png, pdf)*
									</div>
									<UploadPhoto
										id={AcknowledgedAppFormKeys.CREDIT_REFERENCES}
										formats={['png', 'jpeg', 'pdf']}
										labelName="Upload credit references"
										error={handleUploadError}
										data={values.creditRef.value}
										sizeLimitMB={5}
										numberOfImages={6}
										success={(image) =>
											handlePhotoUpload(image, 'creditRef', 'CreditReferences')
										}
										getImage={getImage}
										removeImage={(image) => removeImage(image, 'creditRef')}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>{errors.creditRef}</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderStaffDetails = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Staff Details"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.STAFF_DETAILS_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Do you have any staff?
									</div>
									<Toggle
										id={AcknowledgedAppFormKeys.HAVE_ANY_STAFF}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'anyStaff', [
												{
													item: 'staffList',
													value: [{ fullName: '', roleId: 0, roleOther: null }]
												}
											])
										}
										value={values.anyStaff.value}
										disabled={isReadOnly}
									/>
								</div>
							</Col>
						</Row>
						{values.anyStaff.value && (
							<>
								<FormMultipleInputs
									fieldName="Full name*"
									fieldId={AcknowledgedAppFormKeys.STAFF_FULL_NAME}
									listRef="roleId"
									fieldIdOther={AcknowledgedAppFormKeys.STAFF_OTHER_ROLE}
									fieldRefOther="roleOther"
									fieldRef="fullName"
									listName="Role*"
									dropDownList={referenceStaffRoles}
									listId={AcknowledgedAppFormKeys.STAFF_ROLE_OTHER}
									value={values.staffList.value}
									addNew={handleAddNewStaff}
									isError={errors.staffList}
									onChange={(val) => handleMultiFieldChange('staffList', val)}
									disabled={isReadOnly}
								/>
								<span className={classes.errorText}>{errors.staffList}</span>
							</>
						)}
					</div>
				</Accordion>
			</>
		);
	};

	const renderInsuranceDetails = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Insurance Details"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.INSURANCE_DETAILS_FORM}
						className={classes.formFieldsContainer}
					>
						{' '}
						<Row>
							<Col xs={12} lg={5}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Public liability policy number*
									</div>
									<FormTextField
										name="publicLiabilityPolicyNumber"
										id={AcknowledgedAppFormKeys.PUBLIC_LIABILITY_POLICY_NUMBER}
										value={values.publicLiabilityPolicyNumber.value}
										onChange={handleChange}
										canEdit={!isReadOnly}
										styles={
											errors.publicLiabilityPolicyNumber
												? { border: '1px solid red' }
												: {}
										}
									/>
									<span className={classes.errorText}>
										{errors.publicLiabilityPolicyNumber}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Public liability expiry date*{' '}
									</div>
									{isReadOnly ? (
										<FormTextField
											name="publicLiabilityExpiry"
											id={AcknowledgedAppFormKeys.PUBLIC_LIABILITY_EXPIRY}
											value={formatDateReadableVariant(
												values.publicLiabilityExpiry.value
											)}
											canEdit={false}
										/>
									) : (
										<FormDatePicker
											minDate={new Date()}
											noMaxDate
											isError={errors.publicLiabilityExpiry ? true : false}
											id={AcknowledgedAppFormKeys.PUBLIC_LIABILITY_EXPIRY}
											DateValue={values.publicLiabilityExpiry.value}
											updateSelection={(value) =>
												handleDateUpdate(value, 'publicLiabilityExpiry')
											}
										/>
									)}

									<span className={classes.errorText}>
										{errors.publicLiabilityExpiry}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle}`}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										Public liability coverage detail:*
									</div>
									<ResizingTextArea
										borderColor={
											errors.publicLiabilityCoverage ? 'red' : '#c7ced1'
										}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="publicLiabilityCoverage"
										id={AcknowledgedAppFormKeys.PUBLIC_LIABILITY_COVERAGE}
										value={values.publicLiabilityCoverage.value}
										onChange={handleChange}
										maxChars={100}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.publicLiabilityCoverage}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle}`}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										Insurer Name*
									</div>
									<ResizingTextArea
										borderColor={
											errors.publicLiabilityInsurerName ? 'red' : '#c7ced1'
										}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="publicLiabilityInsurerName"
										id={AcknowledgedAppFormKeys.PUBLIC_LIABILITY_INSURER_NAME}
										value={values.publicLiabilityInsurerName?.value}
										onChange={handleChange}
										maxChars={100}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.publicLiabilityInsurerName}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle}`}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										Name of Insured Party or Parties*
									</div>
									<ResizingTextArea
										borderColor={
											errors.publicLiabilityInsuredPartyName ? 'red' : '#c7ced1'
										}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="publicLiabilityInsuredPartyName"
										id={
											AcknowledgedAppFormKeys.PUBLIC_LIABILITY_INSURED_PARTY_NAME
										}
										value={values.publicLiabilityInsuredPartyName?.value}
										onChange={handleChange}
										maxChars={100}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.publicLiabilityInsuredPartyName}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Professional Indemnity
									</div>
									<Toggle
										id={AcknowledgedAppFormKeys.PROFESSIONAL_INDEMNITY}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'professionalIndemnity', [
												{
													item: 'professionalIndemnityPolicyNumber',
													value: null
												},
												{
													item: 'professionalIndemnityExpiry',
													value: null
												},
												{ item: 'professionalIndemnityCoverage', value: '' },
												{ item: 'indemnityInsurerName', value: null },
												{ item: 'indemnityInsuredPartyName', value: null }
											])
										}
										value={values.professionalIndemnity.value}
										disabled={isReadOnly}
									/>
								</div>
							</Col>
						</Row>
						{values.professionalIndemnity.value && (
							<>
								<Row>
									<Col>
										<div className={classes.fieldContainer}>
											<div className={`${classes.fieldTitle} float-left`}>
												Insurer Name*
											</div>
											<ResizingTextArea
												borderColor={
													errors.indemnityInsurerName ? 'red' : '#c7ced1'
												}
												charLengthStyle={
													isMobile ? { float: 'left', marginBottom: 10 } : {}
												}
												name="indemnityInsurerName"
												id={AcknowledgedAppFormKeys.INDEMNITY_INSURER_NAME}
												value={values.indemnityInsurerName?.value}
												onChange={handleChange}
												maxChars={100}
												styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.indemnityInsurerName}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.fieldContainer}>
											<div className={`${classes.fieldTitle} float-left`}>
												Name of Insured Party or Parties*
											</div>
											<ResizingTextArea
												borderColor={
													errors.indemnityInsuredPartyName ? 'red' : '#c7ced1'
												}
												charLengthStyle={
													isMobile ? { float: 'left', marginBottom: 10 } : {}
												}
												name="indemnityInsuredPartyName"
												id={
													AcknowledgedAppFormKeys.INDEMNITY_INSURED_PARTY_NAME
												}
												value={values.indemnityInsuredPartyName?.value}
												onChange={handleChange}
												maxChars={100}
												styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.indemnityInsuredPartyName}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={5}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Professional Indemnity policy number*
											</div>
											<FormTextField
												name="professionalIndemnityPolicyNumber"
												id={
													AcknowledgedAppFormKeys.PROFESSIONAL_INDEMNITY_POLICY_NUMBER
												}
												value={values.professionalIndemnityPolicyNumber.value}
												onChange={handleChange}
												canEdit={!isReadOnly}
												styles={
													errors.professionalIndemnityPolicyNumber
														? { border: '1px solid red' }
														: {}
												}
											/>
											<span className={classes.errorText}>
												{errors.professionalIndemnityPolicyNumber}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Professional Indemnity expiry date*{' '}
											</div>
											{isReadOnly ? (
												<FormTextField
													name="professionalIndemnityExpiry"
													id={
														AcknowledgedAppFormKeys.PROFESSIONAL_INDEMNITY_EXPIRY
													}
													value={formatDateReadableVariant(
														values.professionalIndemnityExpiry.value
													)}
													canEdit={false}
												/>
											) : (
												<FormDatePicker
													noMaxDate
													isError={
														errors.professionalIndemnityExpiry ? true : false
													}
													id={
														AcknowledgedAppFormKeys.PROFESSIONAL_INDEMNITY_EXPIRY
													}
													DateValue={values.professionalIndemnityExpiry.value}
													updateSelection={(value) =>
														handleDateUpdate(
															value,
															'professionalIndemnityExpiry'
														)
													}
												/>
											)}

											<span className={classes.errorText}>
												{errors.professionalIndemnityExpiry}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.fieldContainer}>
											<div
												className={classes.fieldTitle}
												style={{ float: 'left' }}
											>
												Professional Indemnity coverage detail*
											</div>
											<ResizingTextArea
												borderColor={
													errors.professionalIndemnityCoverage
														? 'red'
														: '#c7ced1'
												}
												charLengthStyle={
													isMobile ? { float: 'left', marginBottom: 10 } : {}
												}
												name="professionalIndemnityCoverage"
												id={
													AcknowledgedAppFormKeys.PROFESSIONAL_INDEMNITY_COVERAGE
												}
												value={values.professionalIndemnityCoverage.value}
												onChange={handleChange}
												maxChars={100}
												styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.professionalIndemnityCoverage}
											</span>
										</div>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Workers Compensation*
									</div>
									<Toggle
										id={AcknowledgedAppFormKeys.WORKER_COMPENSATION}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'workerCompensation', [
												{
													item: 'workersCompensationInsuredPartyName',
													value: null
												},
												{ item: 'workerCompensationPolicyNumber', value: null },
												{ item: 'workerCompensationExpiry', value: null },
												{ item: 'workerCompensationCoverage', value: '' }
											])
										}
										value={values.workerCompensation.value}
										disabled={isReadOnly}
									/>
								</div>
							</Col>
						</Row>
						{values.workerCompensation.value && (
							<>
								<Row>
									<Col>
										<div className={classes.fieldContainer}>
											<div className={`${classes.fieldTitle} float-left`}>
												Name of Insured Party or Parties*
											</div>
											<ResizingTextArea
												borderColor={
													errors.workersCompensationInsuredPartyName
														? 'red'
														: '#c7ced1'
												}
												charLengthStyle={
													isMobile ? { float: 'left', marginBottom: 10 } : {}
												}
												name="workersCompensationInsuredPartyName"
												id={
													AcknowledgedAppFormKeys.WORKERS_COMPENSATION_INSURED_PARTY_NAME
												}
												value={
													values.workersCompensationInsuredPartyName?.value
												}
												onChange={handleChange}
												maxChars={100}
												styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.workersCompensationInsuredPartyName}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={5}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Workers Compensation policy number*
											</div>
											<FormTextField
												name="workerCompensationPolicyNumber"
												id={
													AcknowledgedAppFormKeys.WORKER_COMPENSATION_POLICY_NUMBER
												}
												value={values.workerCompensationPolicyNumber.value}
												onChange={handleChange}
												styles={
													errors.workerCompensationPolicyNumber
														? { border: '1px solid red' }
														: {}
												}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.workerCompensationPolicyNumber}
											</span>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Workers Compensation expiry date*{' '}
											</div>
											{isReadOnly ? (
												<FormTextField
													name="workerCompensationExpiry"
													id={
														AcknowledgedAppFormKeys.WORKER_COMPENSATION_EXPIRY
													}
													value={formatDateReadableVariant(
														values.workerCompensationExpiry.value
													)}
													canEdit={false}
												/>
											) : (
												<FormDatePicker
													minDate={new Date()}
													noMaxDate
													isError={
														errors.workerCompensationExpiry ? true : false
													}
													id={
														AcknowledgedAppFormKeys.WORKER_COMPENSATION_EXPIRY
													}
													DateValue={values.workerCompensationExpiry.value}
													updateSelection={(value) =>
														handleDateUpdate(value, 'workerCompensationExpiry')
													}
												/>
											)}

											<span className={classes.errorText}>
												{errors.workerCompensationExpiry}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.fieldContainer}>
											<div
												className={classes.fieldTitle}
												style={{ float: 'left' }}
											>
												Workers Compensation coverage detail*
											</div>
											<ResizingTextArea
												borderColor={
													errors.workerCompensationCoverage ? 'red' : '#c7ced1'
												}
												charLengthStyle={
													isMobile ? { float: 'left', marginBottom: 10 } : {}
												}
												name="workerCompensationCoverage"
												id={
													AcknowledgedAppFormKeys.WORKER_COMPENSATION_COVERAGE
												}
												value={values.workerCompensationCoverage.value}
												onChange={handleChange}
												maxChars={100}
												styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
												canEdit={!isReadOnly}
											/>
											<span className={classes.errorText}>
												{errors.workerCompensationCoverage}
											</span>
										</div>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Upload insurance certificate/s of currency (jpg, png, pdf)*
									</div>
									<UploadPhoto
										id={
											AcknowledgedAppFormKeys.INSURANCE_CERTIFICATE_OF_CURRENCY
										}
										formats={['png', 'jpeg', 'pdf']}
										labelName="Upload Document"
										error={handleUploadError}
										data={values.insuranceCertificateOfCurrency.value}
										sizeLimitMB={5}
										numberOfImages={5}
										success={(image) =>
											handlePhotoUpload(
												image,
												'insuranceCertificateOfCurrency',
												'InsuranceCertificates'
											)
										}
										getImage={getImage}
										removeImage={(image) =>
											removeImage(image, 'insuranceCertificateOfCurrency')
										}
										textToRender={
											(values.status.value === REVIEW ||
												values.status.value === APPROVED) &&
											values.insuranceCertificateOfCurrency.value?.length > 0 &&
											values.insuranceCertificateOfCurrency.value?.every(
												(item) =>
													item.sightedBy?.id && item.sightedBy?.id !== -1
											) ? (
												<SightedUploadedText />
											) : null
										}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.insuranceCertificateOfCurrency}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderExperience = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Experience"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.EXPERIENCE_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle}`}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										Please describe your experience with retired or spelling
										thoroughbreds*{' '}
									</div>
									<ResizingTextArea
										borderColor={errors.ridingComExperience ? 'red' : '#c7ced1'}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="ridingComExperience"
										id={AcknowledgedAppFormKeys.RIDING_EXPERIENCE}
										value={values.ridingComExperience.value}
										onChange={handleChange}
										maxChars={1000}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.ridingComExperience}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<div style={{ padding: '10px 0px 10px 0px' }}>
										<FormLabelField
											title={`Please list any memberships you currently have:`}
											value={values.listMembership.value
												.map((membership) => membership.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									</div>
								) : (
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Please list any memberships you currently have:
										</div>
										<FormMultiSelectList
											id={AcknowledgedAppFormKeys.LIST_MEMBERSHIP}
											name="listMembership"
											listOptions={referenceClubMemberships}
											selectedIds={values.listMembership.value}
											onSelectChange={(val) =>
												handleChangeMultiSelect(val, 'listMembership', [
													{ item: 'otherMembership', value: '' }
												])
											}
											border={errors.listMembership ? 'red' : null}
										/>
										<span className={classes.errorText}>
											{errors.listMembership}
										</span>
									</div>
								)}
							</Col>
						</Row>
						{values.listMembership.value.filter((rec) => rec.label === 'Other')
							.length > 0 && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											Other memberships*
										</div>
										<ResizingTextArea
											borderColor={errors.otherMembership ? 'red' : '#c7ced1'}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="otherMembership"
											id={AcknowledgedAppFormKeys.OTHER_MEMBERSHIP}
											value={values.otherMembership.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.otherMembership}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle} `}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										Why do you want to become an Acknowledged Retirement Farm
										Program supplier?*{' '}
									</div>
									<ResizingTextArea
										borderColor={
											errors.ackFarmSupplierQuestion ? 'red' : '#c7ced1'
										}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="ackFarmSupplierQuestion"
										id={
											AcknowledgedAppFormKeys.ACK_RETIREMENT_FARM_SUPPLIER_QUESTION
										}
										value={values.ackFarmSupplierQuestion.value}
										onChange={handleChange}
										maxChars={1000}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.ackFarmSupplierQuestion}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle}`}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										What are your expectations for support from Racing
										Victoria?*{' '}
									</div>
									<ResizingTextArea
										borderColor={
											errors.expectationFromRVQuestion ? 'red' : '#c7ced1'
										}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="expectationFromRVQuestion"
										id={AcknowledgedAppFormKeys.EXPECTATION_FROM_RV_QUESTION}
										value={values.expectationFromRVQuestion.value}
										onChange={handleChange}
										maxChars={1000}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.expectationFromRVQuestion}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={6}>
								<div className={classes.fieldTitle}>
									Do you have a formal agreement in place for agistment and/or
									retirement services?
								</div>
								<div style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}>
									Racing Victoria strongly encourages you to obtain legal advice
									and have a formalised agreement in place to protect your
									business.
								</div>
							</Col>
							<Col xs={12} lg={2}></Col>
							<Col xs={12} lg={4}>
								<Toggle
									id={AcknowledgedAppFormKeys.FORMAL_AGREEMENT_IN_PLACE}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(val) =>
										handleChangeToggle(
											val,
											'hadFormalAgreementForAgistmentQuestion'
										)
									}
									value={values.hadFormalAgreementForAgistmentQuestion.value}
									disabled={isReadOnly}
								/>
								<span className={classes.errorText}>
									{errors.hadFormalAgreementForAgistmentQuestion}
								</span>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle}`}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										Please outline your approach to letting down thoroughbreds
										when first retiring from racing?*{' '}
									</div>
									<ResizingTextArea
										borderColor={
											errors.outlineApproachQuestion ? 'red' : '#c7ced1'
										}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="outlineApproachQuestion"
										id={AcknowledgedAppFormKeys.OUTLINE_APPROACH_QUESTION}
										value={values.outlineApproachQuestion.value}
										onChange={handleChange}
										maxChars={1000}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.outlineApproachQuestion}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div
										className={`${classes.fieldTitle}`}
										style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
									>
										Please outline who will take responsibility for the horses
										in your care, should you be away or unable to?*{' '}
									</div>
									<ResizingTextArea
										borderColor={
											errors.outlineResponsibilityQuestion ? 'red' : '#c7ced1'
										}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="outlineResponsibilityQuestion"
										id={AcknowledgedAppFormKeys.OUTLINE_RESPONSIBILITY_QUESTION}
										value={values.outlineResponsibilityQuestion.value}
										onChange={handleChange}
										maxChars={1000}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.outlineResponsibilityQuestion}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Please submit at least 2 references from people you have
										agisted horses for, inclusive of dates (jpg, png, pdf)*
									</div>
									<UploadPhoto
										id={AcknowledgedAppFormKeys.CLIENT_REF_UPLOADS}
										formats={['png', 'jpeg', 'pdf']}
										labelName="Upload References"
										error={handleUploadError}
										data={values.clientRefUploads.value}
										sizeLimitMB={5}
										numberOfImages={6}
										success={(image) =>
											handlePhotoUpload(
												image,
												'clientRefUploads',
												'ClientReferences'
											)
										}
										getImage={getImage}
										removeImage={(image) =>
											removeImage(image, 'clientRefUploads')
										}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.clientRefUploads}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Please include a minimum of 4 photos of your agistment
										facilities and fencing (jpg, png, pdf)*
									</div>
									<UploadPhoto
										id={AcknowledgedAppFormKeys.AGISTMENT_FACILITIES_UPLOADS}
										formats={['png', 'jpeg', 'pdf']}
										labelName="Upload Photos"
										error={handleUploadError}
										data={values.agistmentFacilitiesUploads.value}
										sizeLimitMB={5}
										numberOfImages={12}
										success={(image) =>
											handlePhotoUpload(
												image,
												'agistmentFacilitiesUploads',
												'AgistmentFacilities'
											)
										}
										getImage={getImage}
										removeImage={(image) =>
											removeImage(image, 'agistmentFacilitiesUploads')
										}
										canEdit={!isReadOnly}
									/>
									<span className={classes.errorText}>
										{errors.agistmentFacilitiesUploads}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderCurrentRacingVictoriaLicenses = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Current Racing Victoria Licences/Registrations"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.CURRENT_RV_LICENCES_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Licence/Registration Type
									</div>
									<FormDropDown
										showBlank
										name="currentLicenseType"
										id={AcknowledgedAppFormKeys.CURRENT_LICENSE_TYPE}
										items={referenceLicenseTypes}
										selectedId={values.currentLicenseType.value}
										onChange={handleChangeDropDown}
										styles={
											errors.currentLicenseType
												? { border: '1px solid red' }
												: {}
										}
										disabled={isReadOnly}
									/>
									<div className={classes.errorTextDropDown}>
										{errors.currentLicenseType}
									</div>
								</div>
							</Col>
							<Col xs={12} lg={4} style={!isMobile ? { paddingLeft: 0 } : {}}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Licence/Registration Number
									</div>
									<FormTextField
										name="currentLicenseNumber"
										id={AcknowledgedAppFormKeys.CURRENT_LICENSE_NUMBER}
										onChange={handleChange}
										value={values.currentLicenseNumber.value}
										canEdit={!isReadOnly}
										styles={
											errors.currentLicenseNumber
												? { border: '1px solid red' }
												: {}
										}
									/>
									<span className={classes.errorText}>
										{errors.currentLicenseNumber}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4} style={!isMobile ? { paddingLeft: 0 } : {}}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Licence/Registration Start Date
									</div>
									{isReadOnly ? (
										<FormTextField
											name="currentLicenseStartDate"
											id={AcknowledgedAppFormKeys.CURRENT_LICENSE_START_DATE}
											value={formatDateReadableVariant(
												values.currentLicenseStartDate.value
											)}
											canEdit={false}
										/>
									) : (
										<FormDatePicker
											noMaxDate
											id={AcknowledgedAppFormKeys.CURRENT_LICENSE_START_DATE}
											DateValue={values.currentLicenseStartDate.value}
											updateSelection={(value) =>
												handleDateUpdate(value, 'currentLicenseStartDate')
											}
										/>
									)}

									<span className={classes.errorText}>
										{errors.currentLicenseStartDate}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderPreviousRacingVictoriaLicenses = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Previous Racing Victoria Licences/Registrations"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.PREVIOUS_RV_LICENCES_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Previous Licence/Registration Type
									</div>
									<FormDropDown
										showBlank
										name="previousLicenseType"
										id={AcknowledgedAppFormKeys.PREVIOUS_LICENSE_TYPE}
										items={referenceLicenseTypes}
										selectedId={values.previousLicenseType.value}
										onChange={handleChangeDropDown}
										styles={
											errors.previousLicenseType
												? { border: '1px solid red' }
												: {}
										}
										disabled={isReadOnly}
									/>
									<div className={classes.errorTextDropDown}>
										{errors.previousLicenseType}
									</div>
								</div>
							</Col>
							<Col xs={12} lg={4} style={!isMobile ? { paddingLeft: 0 } : {}}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Previous Licence/Registration Number
									</div>
									<FormTextField
										name="previousLicenseNumber"
										id={AcknowledgedAppFormKeys.PREVIOUS_LICENSE_NUMBER}
										onChange={handleChange}
										value={values.previousLicenseNumber.value}
										canEdit={!isReadOnly}
										styles={
											errors.previousLicenseNumber
												? { border: '1px solid red' }
												: {}
										}
									/>
									<span className={classes.errorText}>
										{errors.previousLicenseNumber}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4} style={!isMobile ? { paddingLeft: 0 } : {}}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Previous Licence/Registration Start Date
									</div>
									{isReadOnly ? (
										<FormTextField
											name="previousLicenseStartDate"
											id={AcknowledgedAppFormKeys.PREVIOUS_LICENSE_START_DATE}
											value={formatDateReadableVariant(
												values.previousLicenseStartDate.value
											)}
											canEdit={false}
										/>
									) : (
										<FormDatePicker
											noMaxDate
											id={AcknowledgedAppFormKeys.PREVIOUS_LICENSE_START_DATE}
											DateValue={values.previousLicenseStartDate.value}
											updateSelection={(value) =>
												handleDateUpdate(value, 'previousLicenseStartDate')
											}
										/>
									)}
									<span className={classes.errorText}>
										{errors.previousLicenseStartDate}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderDeclaration = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Declarations"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.DECLARATIONS_FORM}
						className={classes.formFieldsContainer}
						style={{ marginTop: 20 }}
					>
						<Row>
							<Col xs={12} lg={6}>
								<div className={classes.fieldTitle}>
									Have you ever been the subject of an animal welfare or animal
									cruelty investigation by RSPCA or any other organisation?
								</div>
							</Col>
							<Col xs={12} lg={2}></Col>
							<Col xs={12} lg={4}>
								<Toggle
									id={AcknowledgedAppFormKeys.SUBJECTED_TO_ANIMAL_WELFARE}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(val) =>
										handleChangeToggle(val, 'subjectedToAnimalWelfare', [
											{ item: 'subjectedToAnimalWelfareDetails', value: '' }
										])
									}
									value={values.subjectedToAnimalWelfare.value}
									disabled={isReadOnly}
								/>
							</Col>
						</Row>
						{values.subjectedToAnimalWelfare.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											If yes, please provide more details*
										</div>
										<ResizingTextArea
											borderColor={
												errors.subjectedToAnimalWelfareDetails
													? 'red'
													: '#c7ced1'
											}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="subjectedToAnimalWelfareDetails"
											id={
												AcknowledgedAppFormKeys.SUBJECTED_TO_ANIMAL_WELFARE_DETAILS
											}
											value={values.subjectedToAnimalWelfareDetails.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.subjectedToAnimalWelfareDetails}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row style={{ marginTop: 20 }}>
							<Col xs={12} lg={6}>
								<div className={classes.fieldTitle}>
									Are you currently under any ban/restriction imposed by any
									Racing Authority (including Racing Victoria), either in
									Australia or overseas?
								</div>
							</Col>
							<Col xs={12} lg={2}></Col>
							<Col xs={12} lg={4}>
								<Toggle
									id={AcknowledgedAppFormKeys.ANY_BAN}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(val) =>
										handleChangeToggle(val, 'anyBan', [
											{ item: 'anyBanDetails', value: '' }
										])
									}
									value={values.anyBan.value}
									disabled={isReadOnly}
								/>
							</Col>
						</Row>
						{values.anyBan.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											If yes, please provide more details*
										</div>
										<ResizingTextArea
											borderColor={errors.anyBanDetails ? 'red' : '#c7ced1'}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="anyBanDetails"
											id={AcknowledgedAppFormKeys.ANY_BAN_DETAILS}
											value={values.anyBanDetails.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.anyBanDetails}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row style={{ marginTop: 20 }}>
							<Col xs={12} lg={6}>
								<div className={classes.fieldTitle}>
									Have you ever been disqualified or refused a licence, permit
									or registration by any Racing Authority or Racing code
									(including Racing Victoria), either in Australia, or overseas?
								</div>
							</Col>
							<Col xs={12} lg={2}></Col>
							<Col xs={12} lg={4}>
								<Toggle
									id={AcknowledgedAppFormKeys.DISQUALIFIED}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(val) =>
										handleChangeToggle(val, 'disqualified', [
											{ item: 'disqualifiedDetails', value: '' }
										])
									}
									value={values.disqualified.value}
									disabled={isReadOnly}
								/>
							</Col>
						</Row>
						{values.disqualified.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											If yes, please provide more details*
										</div>
										<ResizingTextArea
											borderColor={
												errors.disqualifiedDetails ? 'red' : '#c7ced1'
											}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="disqualifiedDetails"
											id={AcknowledgedAppFormKeys.DISQUALIFIED_DETAILS}
											value={values.disqualifiedDetails.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.disqualifiedDetails}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row style={{ marginTop: 20 }}>
							<Col xs={12} lg={6}>
								<div className={classes.fieldTitle}>
									Have you, in the last 10 years, been:* <br />
									<div className="md:ml-5">
										(a) found guilty of a criminal offence? <br />
										(b) imprisoned? and/or <br />
										(c) placed on parole?
									</div>
								</div>
							</Col>
							<Col xs={12} lg={2}></Col>
							<Col xs={12} lg={4}>
								<Toggle
									id={AcknowledgedAppFormKeys.GUILTY_OF_OFFENSE}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(val) =>
										handleChangeToggle(val, 'guiltyOfOffense', [
											{ item: 'guiltyOfOffenseDetails', value: '' }
										])
									}
									value={values.guiltyOfOffense.value}
									disabled={isReadOnly}
								/>
							</Col>
						</Row>
						{values.guiltyOfOffense.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											If yes, please provide more details*
										</div>
										<ResizingTextArea
											borderColor={
												errors.guiltyOfOffenseDetails ? 'red' : '#c7ced1'
											}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="guiltyOfOffenseDetails"
											id={AcknowledgedAppFormKeys.GUILTY_OF_OFFENSE_DETAILS}
											value={values.guiltyOfOffenseDetails.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.guiltyOfOffenseDetails}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row style={{ marginTop: 20 }}>
							<Col xs={12} lg={6}>
								<div className={classes.fieldTitle}>
									Are there any current criminal proceedings (or charges)
									pending against you?*
								</div>{' '}
							</Col>
							<Col xs={12} lg={2}></Col>
							<Col xs={12} lg={4}>
								<Toggle
									id={AcknowledgedAppFormKeys.ANY_CRIMINAL_CHARGES}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(val) =>
										handleChangeToggle(val, 'anyCriminalCharges', [
											{ item: 'anyCriminalChargesDetails', value: '' }
										])
									}
									value={values.anyCriminalCharges.value}
									disabled={isReadOnly}
								/>
							</Col>
						</Row>
						{values.anyCriminalCharges.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											If yes, please provide more details*
										</div>
										<ResizingTextArea
											borderColor={
												errors.anyCriminalChargesDetails ? 'red' : '#c7ced1'
											}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="anyCriminalChargesDetails"
											id={AcknowledgedAppFormKeys.ANY_CRIMINAL_CHARGES_DETAILS}
											value={values.anyCriminalChargesDetails.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.anyCriminalChargesDetails}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row style={{ marginTop: 20 }}>
							<Col xs={12} lg={6}>
								<div className={classes.fieldTitle}>
									Personal Bankruptcy and/or Company Insolvency:*
									<br />
									Have you, in the last 10 years, <br />
									<div className="md:ml-5">
										(a) been declared bankrupt or insolvent? and/or <br />
										(b) Do you currently have any bankruptcy action or <br />
										proceedings pending against you? and/or <br />
										(c) Has any Company which you use or have used as part of
										your retirement farm function/business, and/or which employs
										you (or has employed you) as a retirement farm provider,
										become insolvent (including: Voluntary Administration,
										Liquidation or Receivership?)
									</div>
								</div>
							</Col>
							<Col xs={12} lg={2}></Col>
							<Col xs={12} lg={4}>
								<Toggle
									id={AcknowledgedAppFormKeys.BANKRUPTCY}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(val) =>
										handleChangeToggle(val, 'bankruptcy', [
											{ item: 'bankruptcyDetails', value: '' }
										])
									}
									value={values.bankruptcy.value}
									disabled={isReadOnly}
								/>
							</Col>
						</Row>
						{values.bankruptcy.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={`${classes.fieldTitle}`}
											style={{ float: `${isReadOnly ? 'none' : 'left'}` }}
										>
											If yes, please provide more details*
										</div>
										<ResizingTextArea
											borderColor={errors.bankruptcyDetails ? 'red' : '#c7ced1'}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="bankruptcyDetails"
											id={AcknowledgedAppFormKeys.BANKRUPTCY_DETAILS}
											value={values.bankruptcyDetails.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
											canEdit={!isReadOnly}
										/>
										<span className={classes.errorText}>
											{errors.bankruptcyDetails}
										</span>
									</div>
								</Col>
							</Row>
						)}
					</div>
				</Accordion>
			</>
		);
	};

	const renderAcknowledgements = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Acknowledgements"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={AcknowledgedAppFormKeys.ACKNOWLEDGEMENTS_FORM}
						className={classes.formFieldsContainer}
						style={{ marginTop: 20 }}
					>
						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<>
										<BasicCheckbox
											value={values.readAndAgree.value}
											disabled={isReadOnly}
											handleChange={() => {}}
											label={
												'I confirm that I have read and agree to abide by:*'
											}
										/>
										<div className="md:ml-10">
											(1){' '}
											<a
												href="https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:13c06de5-5b29-3244-a0ca-76ce1faf1284"
												target="_blank"
												rel="noreferrer"
												className={classes.mailLink}
											>
												{' '}
												Acknowledged Retirement Farm Program Terms and
												Conditions;
											</a>
										</div>
									</>
								) : (
									<>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'readAndAgree')
											}
											custom
											customStyle={
												errors.readAndAgree
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: AcknowledgedAppFormKeys.READ_AND_AGREE,
												isSelected: values.readAndAgree.value
											}}
										/>
										<div
											className={classes.checkboxField}
											style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
										>
											<b>I confirm that I have read and agree to abide by:*</b>
											<div className="md:ml-5">
												(1){' '}
												<a
													href="https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:2fe3eba4-f7e9-38a0-b609-2291e2b93995"
													target="_blank"
													rel="noreferrer"
													className={classes.mailLink}
												>
													{' '}
													Acknowledged Retirement Farm Program Terms and
													Conditions;
												</a>
											</div>
										</div>
									</>
								)}
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<>
										<BasicCheckbox
											value={values.supportPromoteEquineWelfare.value}
											disabled={isReadOnly}
											handleChange={() => {}}
											label={'Equine Welfare:*'}
										/>
										<span style={{ paddingLeft: 30, fontWeight: 500 }}>
											I acknowledge that I:
										</span>{' '}
										<br />
										<div className="md:ml-10">
											(1) Support and promote good equine welfare practices;{' '}
											<br />
											(2) Agree to comply with the{' '}
											<NewTabLink url="https://agriculture.vic.gov.au/livestock-and-animals/animal-welfare-victoria/pocta-act-1986">
												Prevention of Cruelty to Animals Act 1986 (Vic)
											</NewTabLink>{' '}
											and specifically the{' '}
											<NewTabLink url="https://agriculture.vic.gov.au/__data/assets/pdf_file/0008/538271/Code-of-Practice-for-the-Welfare-of-Horses.pdf">
												Code of Practice for the Welfare of Horses
											</NewTabLink>
											{''}
											;
											<br />
											(3) Agree to promptly notify the Racing Victoria Equine
											Welfare Team of any equine welfare concerns that I may
											witness; and
											<br />
											(4) Agree to complete all Off The Track equine welfare
											training programs within the relevant time frame.
											<br />
										</div>
									</>
								) : (
									<>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'supportPromoteEquineWelfare')
											}
											custom
											customStyle={
												errors.supportPromoteEquineWelfare
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id:
													AcknowledgedAppFormKeys.SUPPORT_PROMOTE_EQUINE_WELFARE,
												isSelected: values.supportPromoteEquineWelfare.value
											}}
										/>
										<div
											className={classes.checkboxField}
											style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
										>
											<strong>Equine Welfare:*</strong>
											<br />
											<strong>I acknowledge that I:</strong> <br />
											<div className="md:ml-5">
												(1) Support and promote good equine welfare practices;{' '}
												<br />
												(2) Agree to comply with the{' '}
												<NewTabLink url="https://agriculture.vic.gov.au/livestock-and-animals/animal-welfare-victoria/pocta-act-1986">
													Prevention of Cruelty to Animals Act 1986 (Vic)
												</NewTabLink>{' '}
												and specifically the{' '}
												<NewTabLink url="https://agriculture.vic.gov.au/__data/assets/pdf_file/0008/538271/Code-of-Practice-for-the-Welfare-of-Horses.pdf">
													Code of Practice for the Welfare of Horses
												</NewTabLink>
												{''}
												;
												<br />
												(3) Agree to promptly notify the Racing Victoria Equine
												Welfare Team of any equine welfare concerns that I may
												witness; and
												<br />
												(4) Agree to complete all Off The Track equine welfare
												training programs within the relevant time frame.
												<br />
											</div>
										</div>
									</>
								)}
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<>
										<BasicCheckbox
											value={values.policeRecordCert.value}
											disabled={isReadOnly}
											handleChange={() => {}}
											label={'National Police Record Certificate:*'}
										/>
										<div className="md:ml-9">
											In circumstances where Racing Victoria&apos;s Intelligence
											Unit sees fit, I consent to obtaining a National Police
											Record Certificate and providing it to Racing Victoria (if
											contacted by Racing Victoria or an appointed CrimTrac
											accredited agency).
										</div>
									</>
								) : (
									<>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'policeRecordCert')
											}
											custom
											customStyle={
												errors.policeRecordCert
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: AcknowledgedAppFormKeys.POLICE_RECORD_CERT,
												isSelected: values.policeRecordCert.value
											}}
										/>
										<div
											className={classes.checkboxField}
											style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
										>
											<b>National Police Record Certificate:*</b> <br />
											In circumstances where Racing Victoria&apos;s Intelligence
											Unit sees fit, I consent to obtaining a National Police
											Record Certificate and providing it to Racing Victoria (if
											contacted by Racing Victoria or an appointed CrimTrac
											accredited agency).
										</div>
									</>
								)}
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<>
										<BasicCheckbox
											value={values.criminalCharges.value}
											disabled={isReadOnly}
											handleChange={() => {}}
											label={'Criminal Charges:*'}
										/>
										<div className="md:ml-9">
											After submitting this Application, I acknowledge that I
											must inform Racing Victoria&apos;s Intelligence Unit of
											any criminal charges laid against me within seven days of
											any charges being laid. Updates must be sent by email to
											<a
												className={classes.mailLink}
												href="mailto:icu@racingvictoria.net.au"
											>
												{' '}
												icu@racingvictoria.net.au.
											</a>
										</div>
									</>
								) : (
									<>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'criminalCharges')
											}
											custom
											customStyle={
												errors.criminalCharges
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: AcknowledgedAppFormKeys.CRIMINAL_CHARGES,
												isSelected: values.criminalCharges.value
											}}
										/>
										<div
											className={classes.checkboxField}
											style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
										>
											<b>Criminal Charges:*</b>
											<br />
											After submitting this Application, I acknowledge that I
											must inform Racing Victoria&apos;s Intelligence Unit of
											any criminal charges laid against me within seven days of
											any charges being laid. Updates must be sent by email to
											<a
												className={classes.mailLink}
												href="mailto:icu@racingvictoria.net.au"
											>
												{' '}
												icu@racingvictoria.net.au.
											</a>
										</div>
									</>
								)}
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<>
										<BasicCheckbox
											value={values.personalBankruptcy.value}
											disabled={isReadOnly}
											handleChange={() => {}}
											label={'Personal Bankruptcy and/or Company Insolvency:*'}
										/>
										<div className="md:ml-10">
											After submitting this Application, I acknowledge that I
											must inform Racing Victoria&apos;s Intelligence Unit
											within 7 days by email to{' '}
											<a
												className={classes.mailLink}
												href="mailto:icu@racingvictoria.net.au"
											>
												icu@racingvictoria.net.au
											</a>{' '}
											if:
										</div>
										<div className="md:ml-10">
											(a) I am declared Bankrupt; and/or <br />
											(b) A Company which I use as part of my retirement farm
											function/business, and/or which employs me as a retirement
											farm provider, becomes insolvent (including: Voluntary
											Administration, Liquidation or Receivership).
										</div>
									</>
								) : (
									<>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'personalBankruptcy')
											}
											custom
											customStyle={
												errors.personalBankruptcy
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: AcknowledgedAppFormKeys.PERSONAL_BANKRUPTCY,
												isSelected: values.personalBankruptcy.value
											}}
										/>
										<div
											className={classes.checkboxField}
											style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
										>
											<b>Personal Bankruptcy and/or Company Insolvency:*</b>{' '}
											<br />
											After submitting this Application, I acknowledge that I
											must inform Racing Victoria&apos;s Intelligence Unit
											within 7 days by email to{' '}
											<a
												className={classes.mailLink}
												href="mailto:icu@racingvictoria.net.au"
											>
												icu@racingvictoria.net.au
											</a>{' '}
											if:
											<div className="md:ml-5">
												(a) I am declared Bankrupt; and/or <br />
												(b) A Company which I use as part of my retirement farm
												function/business, and/or which employs me as a
												retirement farm provider, becomes insolvent (including:
												Voluntary Administration, Liquidation or Receivership).
											</div>
										</div>
									</>
								)}
							</Col>
						</Row>

						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<div style={{ paddingTop: 10 }}>
										<BasicCheckbox
											value={values.utiliseToSubmitReport.value}
											disabled={isReadOnly}
											handleChange={() => {}}
											label={`If my application is successful, I agree to utilise the OTT
											Community to submit reporting of all OTTs in my care on a
											minimum of a quarterly basis, this includes updating the
											Report to advise when a thoroughbred is no longer in my
											care.*`}
										/>
									</div>
								) : (
									<>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'utiliseToSubmitReport')
											}
											custom
											customStyle={
												errors.utiliseToSubmitReport
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: AcknowledgedAppFormKeys.UTILISE_TO_SUBMIT_REPORT,
												isSelected: values.utiliseToSubmitReport.value
											}}
										/>
										<div
											className={classes.checkboxField}
											style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
										>
											<b>
												If my application is successful, I agree to utilise the
												OTT Community to submit reporting of all OTTs in my care
												on a minimum of a quarterly basis, this includes
												updating the Report to advise when a thoroughbred is no
												longer in my care.*
											</b>
										</div>
									</>
								)}
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								{isReadOnly ? (
									<>
										<BasicCheckbox
											value={values.declareApplicationTrueAndCorrect.value}
											disabled={isReadOnly}
											handleChange={() => {}}
											label={'I, the applicant hereby:*'}
										/>
										<div className="md:ml-10">
											&bull;&nbsp;Declare that all details in my application are
											true and correct; <br />
											&bull;&nbsp;Acknowledge that Racing Victoria may request
											further information from me in respect of this
											application; <br />
											&bull;&nbsp;Consent to Racing Victoria obtaining personal
											information about me from others and using & disclosing my
											personal information as noted in the attached
											<a
												href={
													ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
												}
												target="_blank"
												rel="noreferrer"
												className={classes.mailLink}
											>
												{' '}
												Privacy Collection Statement.
											</a>
											<br />
											&bull;&nbsp;Consent to Racing Victoria handling my
											personal information, which includes the collection and
											disclosure of my personal information (including
											sensitive) to/from third parties as set out in the{' '}
											<NewTabLink
												url={
													ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
												}
												className={classes.mailLink}
											>
												Privacy Collection Statement
											</NewTabLink>
											, Racing Victoria&apos;s{' '}
											<NewTabLink
												url="https://www.racingvictoria.com.au/privacy-policy"
												className={classes.mailLink}
											>
												Privacy Policy
											</NewTabLink>
											{'  '}
											or otherwise permitted by law.
										</div>
									</>
								) : (
									<>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'declareApplicationTrueAndCorrect')
											}
											custom
											customStyle={
												errors.declareApplicationTrueAndCorrect
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id:
													AcknowledgedAppFormKeys.DECLARE_APPLICATION_TRUE_AND_CORRECT,
												isSelected:
													values.declareApplicationTrueAndCorrect.value
											}}
										/>
										<div
											className={classes.checkboxField}
											style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
										>
											<b>I, the applicant hereby:*</b>
											<div className="md:ml-5">
												&bull;&nbsp;Declare that all details in my application
												are true and correct; <br />
												&bull;&nbsp;Acknowledge that Racing Victoria may request
												further information from me in respect of this
												application; <br />
												&bull;&nbsp;Consent to Racing Victoria obtaining
												personal information about me from others and using &
												disclosing my personal information as noted in the
												attached
												<a
													href={
														ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
													}
													target="_blank"
													rel="noreferrer"
													className={classes.mailLink}
												>
													{' '}
													Privacy Collection Statement.
												</a>
												<br />
												&bull;&nbsp;Consent to Racing Victoria handling my
												personal information, which includes the collection and
												disclosure of my personal information (including
												sensitive) to/from third parties as set out in the{' '}
												<NewTabLink
													url={
														ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
													}
													className={classes.mailLink}
												>
													Privacy Collection Statement
												</NewTabLink>
												, Racing Victoria&apos;s{' '}
												<NewTabLink
													url="https://www.racingvictoria.com.au/privacy-policy"
													className={classes.mailLink}
												>
													Privacy Policy
												</NewTabLink>
												{'  '}
												or otherwise permitted by law.
											</div>
										</div>
									</>
								)}
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			let acknowledgedFormData = setDataModel(values, currentUser?.UserId);
			AppSyncService.execute(saveAcknowledgedRetirementForm, {
				acknowledgedFormData
			}).then((data) => {
				AppSyncService.execute(updateAcknowledgedRetirementFormStatus, {
					id: data.data?.saveAcknowledgedRetirementForm?.id,
					status: 'Review'
				}).then((data) => {
					if (data.data?.saveAcknowledgedRetirementFormStatus?.success) {
						addToast({ Message: 'Form Submitted', IsSuccess: true });
						navigate(DASHBOARD_ROUTE);
					} else {
						addToast({ Message: 'Failed to submit form', IsSuccess: true });
					}
				});
			});
		}
	};

	const handleDraft = (e) => {
		e.preventDefault();
		let acknowledgedFormData = setDataModel(values, currentUser?.UserId);
		AppSyncService.execute(saveAcknowledgedRetirementForm, {
			acknowledgedFormData
		}).then((data) => {
			if (
				!data.exception &&
				!data.data?.saveAcknowledgedRetirementForm?.error?.errorMessage
			) {
				addToast({ Message: 'Form Saved', IsSuccess: true });
				navigate(DASHBOARD_ROUTE);
			} else {
				addToast({ Message: 'Error saving form', IsSuccess: false });
			}
		});
	};

	const handleCancel = (e) => {
		e.preventDefault();
		let listOfKeys = getListOfKeys();
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					removeAcknowledgedRetirementForm();
				}
			});
		} else {
			removeAcknowledgedRetirementForm();
		}
	};

	const removeAcknowledgedRetirementForm = () => {
		if (formId) {
			AppSyncService.execute(deleteAcknowledgedRetirementForm, {
				id: formId
			}).then((data) => {
				if (data.data != null) {
					onCancelSuccess();
				} else {
					addToast({ Message: 'Error Cancelling Form', IsSuccess: false });
				}
			});
		} else {
			onCancelSuccess();
		}
	};

	const onCancelSuccess = () => {
		addToast({ Message: 'Request cancelled', IsSuccess: true });
		setConfirmationModal(false);
		navigate(DASHBOARD_ROUTE);
	};

	return (
		<>
			<SEO title="Acknowledged Retirement Farm - Supplier Application" />
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			{!isLoading && (
				<Container
					className={
						['xs', 'sm', 'md'].includes(screenClassForRender)
							? classes.mobileContainer
							: classes.container
					}
				>
					<form>
						<Row>
							<Col>{renderAcknowledgedRetirementForm()}</Col>
						</Row>
						<div
							className={classes.formContainer}
							style={
								['xs', 'sm', 'md'].includes(screenClassForRender)
									? { border: 'none' }
									: {}
							}
						>
							<Row>
								<Col>{renderPersonalDetails()}</Col>
							</Row>
							<Row>
								<Col>{renderBusinessDetails()}</Col>
							</Row>
							<Row>
								<Col>{renderBusinessSuppliers()}</Col>
							</Row>
							<Row>
								<Col>{renderCreditReference()}</Col>
							</Row>
							<Row>
								<Col>{renderStaffDetails()}</Col>
							</Row>
							<Row>
								<Col>{renderInsuranceDetails()}</Col>
							</Row>
							<Row>
								<Col>{renderExperience()}</Col>
							</Row>
							<Row>
								<Col>{renderCurrentRacingVictoriaLicenses()}</Col>
							</Row>
							<Row>
								<Col>{renderPreviousRacingVictoriaLicenses()}</Col>
							</Row>
							<Row>
								<Col>{renderDeclaration()}</Col>
							</Row>
							<Row>
								<Col>{renderAcknowledgements()}</Col>
							</Row>
							<hr className={classes.IdentificationDivider}></hr>
							{!['xs', 'sm', 'md'].includes(screenClassForRender) && (
								<Row>
									<Col>
										<div
											className={classes.buttonContainer}
											style={
												['xs', 'sm', 'md'].includes(screenClassForRender)
													? { padding: 0 }
													: {}
											}
										>
											<div
												className={classNames({
													[classes.cancelRequest]: true,
													invisible: isReadOnly
												})}
												onClick={() => {
													if (
														values.status.value !== APPROVED &&
														values.status.value !== REVIEW
													) {
														setConfirmationModal(true);
													}
												}}
												id={CommonKeys.CANCEL_APPLICATION}
											>
												Cancel application
											</div>
											<div className={classes.saveRequest}>
												<span
													style={!isReadOnly ? {} : { visibility: 'hidden' }}
													className={classes.saveButton}
													onClick={handleDraft}
													id={CommonKeys.SAVE_DRAFT}
												>
													Save Draft
												</span>
												<span>
													<PrimaryButton
														onClick={handleSubmit}
														id={CommonKeys.SUBMIT}
														style={{ width: '150px', marginTop: 0 }}
														disabled={isReadOnly}
													>
														Submit
													</PrimaryButton>
												</span>
											</div>
										</div>
									</Col>
								</Row>
							)}
							{['xs', 'sm', 'md'].includes(screenClassForRender) && (
								<Row>
									<Col xs={3}>
										<div
											style={
												values.status.value !== APPROVED &&
												values.status.value !== REVIEW
													? {}
													: { visibility: 'hidden' }
											}
											className={classes.cancelRequestMobile}
											onClick={() => setConfirmationModal(true)}
											id={CommonKeys.CANCEL_APPLICATION}
										>
											Cancel application
										</div>
									</Col>

									<Col xs={2}>
										<div className={classes.saveRequest}>
											<div
												style={!isReadOnly ? {} : { visibility: 'hidden' }}
												className={classes.saveButton}
												onClick={handleDraft}
												id={CommonKeys.SAVE_DRAFT}
											>
												Save Draft
											</div>
										</div>
									</Col>

									<Col xs={7}>
										<PrimaryButton
											onClick={handleSubmit}
											id={CommonKeys.SUBMIT}
											style={{ width: '100%', marginTop: 0 }}
											disabled={isReadOnly}
										>
											Submit
										</PrimaryButton>
									</Col>
								</Row>
							)}
							<Row>
								<Col>
									{' '}
									{Object.keys(errors).length > 0 && (
										<div
											className={classes.errorMessage}
											style={
												['xs', 'sm', 'md'].includes(screenClassForRender)
													? {}
													: { marginRight: 20, marginTop: 0, marginBottom: 20 }
											}
										>
											Please complete all required fields
										</div>
									)}
								</Col>
							</Row>
						</div>
					</form>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Cancel Acknowledged Retirement Farm - Supplier Application"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Are you sure you wish to delete this form?
					</ConfirmationModal>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
					{imgSrcUrl && (
						<Lightbox
							mainSrc={imgSrcUrl}
							onCloseRequest={() => setImgSrcUrl(false)}
						/>
					)}
					<a
						style={{ visibility: 'hidden', cursor: 'pointer' }}
						href={imgSrcPdf}
						ref={hrefLink}
						target="_blank"
						rel="noreferrer"
					>
						Pdf Link
					</a>
				</Container>
			)}
		</>
	);
};

export default AcknowledgedRetirementForm;
