export const tableHeadings = [
	'',
	'Name',
	'Colour',
	'Sex',
	'Stable Address',
	'Date Stabled',
	'In Full Circle'
];

export const mobileHeadings = ['', 'Name', 'Colour'];
