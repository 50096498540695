import React, { useState, useEffect } from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { getRetrainingRehabSubsidyById } from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import RehabilitationSubsidyReviewInfo from '../RehabilitationSubsidyReviewInfo';
import { addNameToUnnamedHorse, parseUTCToLocal } from '../../utils/helpers';

const RehabilitationSubsidyReview = () => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getRetrainingRehabSubsidyById, { id }).then(
			(result) => {
				if (result?.data?.getRetrainingRehabSubsidyById) {
					setData(result.data.getRetrainingRehabSubsidyById);
					setIsLoading(false);
				} else {
					setIsLoading(false);
				}
			}
		);
	}, []);

	return (
		<Container
			className={
				['xs', 'sm', 'md'].includes(screenClassForRender)
					? classes.pageContainerMobile
					: classes.pageContainer
			}
		>
			<SEO title="Rehabilitation Subsidy Review" />
			<HeaderBanner
				title={`Application for Rehabilitation Subsidy - ${addNameToUnnamedHorse(
					data?.horse?.name
				)}`}
				top
				mobile={['xs', 'sm'].includes(screenClassForRender)}
				type="primary"
				rightTitle={`Status: ${data?.status}`}
				styles={{ borderRadius: '5px 5px 5px 5px', fontSize: 13 }}
				dateText={
					data?.created ? (
						<span style={{ fontSize: 13 }}>
							Date Submitted:{' '}
							{parseUTCToLocal(data.created).format('DD/MM/YYYY')}
						</span>
					) : null
				}
			/>
			<div>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
			</div>
			{data != null && (
				<RehabilitationSubsidyReviewInfo
					data={data}
					mobile={['xs', 'sm'].includes(screenClassForRender)}
				/>
			)}
		</Container>
	);
};
export default RehabilitationSubsidyReview;
