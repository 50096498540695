export const vetReportOpts = [
	{
		id: 0,
		value: ''
	},
	{
		id: 1,
		value: 'Myself'
	},
	{
		id: 2,
		value: 'Trainer'
	},
	{
		id: 3,
		value: 'Owner'
	},
	{
		id: 4,
		value: 'Other'
	}
];
