import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	claimThorougbredLinkContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		boxSizing: 'border-box',
		backgroundColor: '#f5f5f5',
		padding: '15px 5px 5px 15px',
		borderRadius: 5,
		'& > div': {
			marginBottom: 10
		},
		'@media (min-width:992px)': {
			flexDirection: 'row',
			'& > div': {
				marginBottom: 0
			}
		}
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 14,
		marginTop: 10,
		cursor: 'pointer'
	},
	claimButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 400,
		height: 38,
		width: '100%',
		fontSize: 15,
		'-webkitAppearance': 'none',
		'&:focus': {
			outline: 'none'
		},
		'&:disabled': {
			backgroundColor: '#d8d8d8',
			color: '#afafaf'
		},
		cursor: 'pointer'
	},
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	rowNoMargin: {
		marginLeft: '0 !important',
		marginRight: '0 !important'
	},
	divider: {
		background: '#edf5f9',
		height: 2,
		border: 'none'
	},
	submitContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderTop: '1px solid #e5f0f6',
		borderBottom: '1px solid #b3bcc1',
		padding: 15,
		marginTop: 10
	},
	errorText: {
		color: '#db0000',
		fontSize: 15
	},
	saveButton: {
		marginRight: 10
	},
	fieldContainer: {
		padding: '20px 18px 0 18px'
	},
	fieldContainerWithoutSidePadding: {
		padding: '20px 0 20px 0'
	},
	clearFormLink: {
		color: '#0085ca',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	saveRequest: {
		display: 'flex',
		alignItems: 'center',
		color: '#0085ca',
		fontSize: 14
	},
	saveRequestMobile: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		color: '#0085ca',
		fontSize: 14
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	uploadFieldTitle: {
		color: '#464749',
		fontSize: 15,
		fontWeight: 600,
		marginBottom: 7
	},
	sectionContainer: {
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		borderBottom: 'none'
	},
	sectionContainerMobile: {
		border: 'none'
	}
}));
