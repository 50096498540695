import React from 'react';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const ResponsiveDialog = ({
	openDialog,
	handleClose,
	title,
	contentChildren = () => {},
	actionButtons = () => {},
	fullWidth = true,
	maxWidth = 'sm',
	diagContentStyle
}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={openDialog}
				onClose={handleClose}
				fullWidth={fullWidth}
				maxWidth={maxWidth}
			>
				<DialogTitle>
					<span style={{ fontWeight: 'bold' }}>{title}</span>
				</DialogTitle>
				<Divider variant="middle" />
				<DialogContent style={diagContentStyle}>
					<DialogContentText>{contentChildren()}</DialogContentText>
				</DialogContent>
				<DialogActions>{actionButtons()}</DialogActions>
			</Dialog>
		</div>
	);
};

export default ResponsiveDialog;
