import React from 'react';
import { Col, Row } from 'react-grid-system';
import Divider from '../Divider';
import FormLabelField from '../FormLabelField';
import FormTagsListField from '../FormTagsListField';
import HeaderBanner from '../HeaderBanner';
import { formatDateReadableVariant } from '../../utils/helpers';
import { StyledItalic, StyledSectionContainer } from './styles';

const MemberDetailsAdminView = ({ memberData }) => {
	const otherInterests = [];
	const memberships = memberData.memberships.filter(
		({ name }) => !/other|race clubs/i.test(name)
	);
	const raceClubMemberships = [];
	const otherMemberships = [];

	if (memberData.otherInterests) {
		otherInterests.push(...memberData.otherInterests.split(','));
	} else if (memberData?.interests?.find(({ title }) => /other/i.test(title))) {
		otherInterests.push(
			memberData?.interests?.find(({ title }) => /other/i.test(title))?.title
		);
	}

	if (memberData.raceClubMemberships.length > 0) {
		raceClubMemberships.push(
			...memberData.raceClubMemberships.map(({ name }) => name)
		);
	} else if (
		memberData?.memberships?.find(({ name }) => /race clubs/i.test(name))
	) {
		raceClubMemberships.push(
			memberData?.memberships?.find(({ name }) => /race clubs/i.test(name))
				?.name
		);
	}

	if (
		memberData.otherMemberships &&
		memberData.otherMemberships.replace('[]', '')
	) {
		otherMemberships.push(
			...memberData.otherMemberships.replace('[]', '').split(',')
		);
	} else if (memberData?.memberships?.find(({ name }) => /other/i.test(name))) {
		otherMemberships.push(
			memberData?.memberships?.find(({ name }) => /other/i.test(name))?.name
		);
	}

	return (
		<>
			<HeaderBanner
				title="Personal Details"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<StyledSectionContainer>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField title="First Name" value={memberData.firstName} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Last Name" value={memberData.lastName} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Preferred Name"
							value={memberData.preferredName}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField title="Email" value={memberData.email} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Phone (Personal)" value={memberData.phone} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Phone (Business)"
							value={memberData.businessPhone}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Date of Birth"
							value={
								formatDateReadableVariant(memberData.dateOfBirth) ||
								'Not Specified'
							}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Gender" value={memberData.gender} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="How many years have you been riding?"
							value={memberData.yearsRiding?.value ?? 'Not Specified'}
						/>
					</Col>
				</Row>
				<Divider />
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Address"
							value={memberData.residentialAddress?.address}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Suburb"
							value={memberData.residentialAddress?.suburb}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential State"
							value={memberData.residentialAddress?.state}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Postcode"
							value={memberData.residentialAddress?.postcode}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Country"
							value={memberData.residentialAddress?.country}
						/>
					</Col>
					<Col></Col>
				</Row>
				<Divider />
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Postal Address"
							value={memberData.postalAddress?.address}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Postal Suburb"
							value={memberData.postalAddress?.suburb}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Postal State"
							value={memberData.postalAddress?.state}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Postal Postcode"
							value={memberData.postalAddress?.postcode}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Postal Country"
							value={memberData.postalAddress?.country}
						/>
					</Col>
					<Col></Col>
				</Row>
				<Divider />
				<Row>
					<Col xs={12} lg={4}>
						<FormTagsListField
							title="Content Preferences"
							value={memberData.secondaryMemebershipTypes.map(
								({ name }) => name
							)}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormTagsListField title="Interests">
							{[
								...memberData?.interests
									?.filter(({ title }) => !/other/i.test(title))
									.map(({ title }) => <span key={title}>{title}</span>),
								...otherInterests.map((name) => (
									<span key={name}>
										{!/other/i.test(name) && `${name} `}
										<StyledItalic>(Other)</StyledItalic>
									</span>
								))
							]}
						</FormTagsListField>
					</Col>
					<Col xs={12} lg={4}>
						<FormTagsListField title="Memberships">
							{[
								...memberships.map(({ name }) => (
									<span key={name}>{name}</span>
								)),
								...raceClubMemberships.map((name) => (
									<span key={name}>
										{!/Race Clubs/i.test(name) && `${name} `}
										<StyledItalic>(Race Clubs)</StyledItalic>
									</span>
								)),
								...otherMemberships.map((name) => (
									<span key={name}>
										{!/other/i.test(name) && `${name} `}
										<StyledItalic>(Other)</StyledItalic>
									</span>
								))
							]}
						</FormTagsListField>
					</Col>
				</Row>
				<Divider />
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Primary Role"
							value={memberData.primaryMembershipType?.name}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormTagsListField
							title="Admin Assigned Role"
							value={memberData.roles.map(({ name }) => name)}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Event Organiser"
							value={`${
								memberData.eventOrganiser?.value
									? memberData.eventOrganiser?.value
									: ''
							} ${
								memberData.eventOrganiser?.id == 12
									? `- ${memberData.eventOrganiser?.organiserOther}`
									: ''
							}`}
						/>
						{}
					</Col>
				</Row>
			</StyledSectionContainer>
		</>
	);
};
export default MemberDetailsAdminView;
