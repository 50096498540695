export const NEW_ARRIVAL = 'New arrival';
export const ANNUAL_UPDATE = 'Annual update';

export const FORM_CAUSE_OPTIONS = [
	{
		id: ANNUAL_UPDATE,
		value: ANNUAL_UPDATE
	},
	{
		id: NEW_ARRIVAL,
		value: NEW_ARRIVAL
	}
];
