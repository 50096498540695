export const claimList = `
query approvedClaimedThroughbredList($memberExternalId: String!) {
  getHorseClaims(memberExternalId: $memberExternalId, status: [Approved], orderBy: "last_updated", order: DESC) {
    horse {
      name
      colour
      sex
      age
      horseCode
      location {
        address
        postcode
        suburb
        state
        country
        stabledDate
      }
      ottInformation {
        paddockName
        profilePhoto {
          key
        }
      }
    }
    fullCircles {
				id
        status
			}
  }
}`;

export const getFullCircleOpts = `
query getFullCircleOpts {
  listFullCircleRelationships {
    id
    value
  }
  listFullCircleTypesOfAssistance {
    id
    value
  }
}`;

export const saveFullCircles = `
mutation saveFullCircles($input: FullCirclesInput!) {
	saveFullCircles(
		input: $input
	) {
      id
      success
      error {
        errorCode
        errorDetails
        errorMessage
      }
	  }
  }
  `;
