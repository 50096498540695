import React, { useEffect, useState } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import { useParams, navigate } from '@reach/router';
import { useToast } from '../../hooks/useToast';
import { useAuth } from '../../hooks/useAuth';
import AppSyncService from '../../graphql/AppSyncService';
import PageLoadSpinner from '../PageLoadSpinner';
import SEO from '../seo';
import MessageBar from '../MessageBar';
import {
	checkUserAdmin,
	addNameToUnnamedHorse,
	checkIsSmallScreen,
	isUserRehomer
} from '../../utils/helpers';
import {
	APPROVED,
	HorseProfileKeys,
	ROLE_RV_EQUINE_WELFARE,
	REVIEW,
	FOSTER_FORMS,
	RETIREMENT_FORMS,
	RESET_FORMS,
	OHEP_FORMS,
	SHARE_INFORMATION_FORM,
	FULL_CIRCLE_FORM,
	REMOVED,
	TRANSITION_REPORT,
	REVIEWED,
	RETRAINING_FORMS,
	SAFETY_NET_PORGRAM_FORMS
} from '../../utils/constants';
import FormSummary from './components/FormSummary';
import {
	AdditionalOptionsContainer,
	AdditionalOption
} from '../AdditionalOptions';
import FormToolTip from '../FormToolTip';
import Tabs from '../Tabs';
import {
	ALLOWED_RETRAINING_FORM_ACCESS_MEMBER_TYPES,
	COMPANION_HORSE_REPORT_DETAILS,
	COMPANION_HORSE_REPORT_TEXT,
	REHOMED,
	RETIRED_THOROUGHBRED_COMPANION
} from './constants';
import PageContainer from '../PageContainer';
import HorseUpdates from './components/HorseUpdates';
import HorseInfoPaneHeader from './components/HorseInfoPaneHeader';
import {
	getHorseProfileData,
	getHorseDataForUser,
	followHorse,
	checkIfFollowingHorse,
	unfollowHorse,
	getHorseProfileDataUnauthenticated,
	getHorseRelatedForms
} from './graphql/queries';
import HorseProfileDataTab from './components/HorseProfileDataTab';
import OTTInformationTab from './components/OTTInformationTab';
import {
	HeaderActionsContainer,
	HeaderAction
} from './components/HeaderActions';
import FollowConfirmModal from './components/FollowConfirmModal';
import { shouldShowDetail, shouldShowOTTInfoTab } from './helpers';
import { OHEP_PROVIDERS_ROUTE } from '../../utils/constants/routes';

const HorseProfile = () => {
	const { addToast } = useToast();
	const { horseCode } = useParams();
	const classes = useStyles();
	const { currentUser } = useAuth();
	const [horseProfileData, setHorseProfileData] = useState({});
	const [horseProfileDataRetrieved, setHorseProfileDataRetrieved] = useState(
		false
	);
	const [horseDataForUserRetreived, setHorseDataForUserRetrieved] = useState(
		false
	);
	const [isOwner, setIsOwner] = useState(false);
	const [existingClaimForm, setExistingClaimForm] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [horseHasInReviewResetNom, setHorseHasInReviewResetNom] = useState(
		false
	);
	const [
		horseHasInReviewOhepNomination,
		setHorseHasInReviewOhepNomination
	] = useState(false);
	const [memberData, setMemberData] = useState(null);
	const screenSize = useScreenClass();
	const isMobile = checkIsSmallScreen(screenSize);
	const isAdminUser = currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE);
	const [followingHorse, setFollowingHorse] = useState(false);
	const [showFollowConfirmModal, setShowFollowConfirmModal] = useState(false);
	const [existingFullCircleForm, setExistingFullCircleForm] = useState(false);
	const [horseProfileRetirement, setHorseProfileRetirement] = useState({});

	const concatAllFormsRelatedToHorse = (horseRelatedFormsResult) => {
		const {
			data: {
				getFosterInitialAssessmentForms,
				getFullCircles,
				getHorsePlacementFormsByHorseCode,
				getOhepNominationForms,
				getResetExpressInitialAssessmentForms,
				getResetExpressRehabilitationConclusionForms,
				getResetExpressRehomeAssessmentForms,
				getResetInitialAssessmentForms,
				getResetNominationForms,
				getRetirementInitialAssessmentForms,
				getShareInformationForms,
				getTransitionReports,
				getRetrainingRehabSubsidyListings,
				getCompanionHorseReports
			}
		} = horseRelatedFormsResult;

		let horseRelatedFormsList = [];
		horseRelatedFormsList = horseRelatedFormsList.concat(
			getFosterInitialAssessmentForms.map((item) => ({
				created: item.created,
				subType: FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getFullCircles.map((item) => ({
				created: item.createDatetime,
				subType: FULL_CIRCLE_FORM,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getHorsePlacementFormsByHorseCode.map((item) => ({
				created: item.created,
				subType: RETRAINING_FORMS.REQUEST_FOR_RETRAINING,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getRetrainingRehabSubsidyListings.map((item) => ({
				created: item.created,
				subType: RETRAINING_FORMS.REHABILITATION_SUBSIDY_APPLICATION,
				id: item.horseStatusId,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getOhepNominationForms.map((item) => ({
				created: item.created,
				subType: OHEP_FORMS.OHEP_NOMINATION,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getResetExpressInitialAssessmentForms.map((item) => ({
				created: item.created,
				subType: RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getResetExpressRehabilitationConclusionForms.map((item) => ({
				created: item.created,
				subType: RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getResetExpressRehomeAssessmentForms.map((item) => ({
				created: item.created,
				subType: RESET_FORMS.RESET_EXPRESS_REHOME_REPORT,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getResetInitialAssessmentForms.map((item) => ({
				created: item.created,
				subType: RESET_FORMS.RESET_INITIAL_ASSESSMENT,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getResetNominationForms.map((item) => ({
				created: item.created,
				subType: RESET_FORMS.RESET_NOMINATION,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getRetirementInitialAssessmentForms.map((item) => ({
				created: item.created,
				subType: RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getShareInformationForms.map((item) => ({
				created: item.created,
				subType: SHARE_INFORMATION_FORM,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getTransitionReports.map((item) => ({
				created: item.created,
				subType: TRANSITION_REPORT,
				...item
			}))
		);

		horseRelatedFormsList = horseRelatedFormsList.concat(
			getCompanionHorseReports.map((item) => ({
				created: item.created,
				subType: SAFETY_NET_PORGRAM_FORMS.RTCP_HORSE_WELFARE_REPORT,
				...item
			}))
		);

		return horseRelatedFormsList;
	};

	const fetchHorseProfileData = async () => {
		let query = getHorseProfileData;
		if (!currentUser) {
			query = getHorseProfileDataUnauthenticated;
		}
		const result = await AppSyncService.execute(query, {
			horseCode
		});
		const horseProfileData = result?.data?.getHorseProfile;
		let horseRelatedFormsList = [];
		if (isAdminUser) {
			const horseRelatedFormsResult = await AppSyncService.execute(
				getHorseRelatedForms,
				{
					status: [APPROVED, REVIEW, REMOVED, REVIEWED],
					horseCode: horseCode
				}
			);

			horseRelatedFormsList = concatAllFormsRelatedToHorse(
				horseRelatedFormsResult
			);
		}

		if (horseProfileData) {
			if (horseProfileData.claims.length > 0 && currentUser) {
				const claimsSource =
					result.data.listHorseClaimSources.find(
						(item) => item.id === horseProfileData.claims[0].claimsSource
					)?.value || '';
				horseProfileData['claimsSource'] = claimsSource;
				if (
					horseProfileData.claims[0].claimsSourceOther &&
					horseProfileData.claims[0].claimsSourceOther.length > 0
				) {
					horseProfileData['claimsSourceOther'] =
						horseProfileData.claims[0].claimsSourceOther;
				}
			}
			setHorseProfileData({ ...horseProfileData, horseRelatedFormsList });
		} else {
			addToast({
				Message: 'Failed to retrieve horse data',
				IsSuccess: false
			});
		}
		if (result?.data?.getResetNominationForms) {
			setHorseHasInReviewResetNom(
				result.data.getResetNominationForms.length > 0
			);
		}
		if (result?.data?.getHorseProfileRetirement) {
			setHorseProfileRetirement(result?.data?.getHorseProfileRetirement);
		}
		setHorseProfileDataRetrieved(true);
	};

	const fetchHorseDataForUser = async () => {
		const result = await AppSyncService.execute(getHorseDataForUser, {
			horseCode,
			memberExternalId: currentUser?.UserId
		});
		if (result?.data?.getExistingNonRejectedForms) {
			result?.data?.getExistingNonRejectedForms.forEach((item) => {
				switch (item.formName) {
					case 'horse_claims':
						setExistingClaimForm(item.nonRejectedFormExists);
						break;
				}
			});
		}
		if (result?.data?.getFullCircles) {
			setExistingFullCircleForm(result?.data?.getFullCircles?.length > 0);
		}
		if (result?.data?.isClaimedByMember) {
			setIsOwner(true);
		}
		if (result?.data?.getMember) {
			setMemberData(result.data.getMember);
		}
		if (result?.data?.getOhepNominationForm) {
			setHorseHasInReviewOhepNomination(
				result.data.getOhepNominationForm?.status === 'Review'
			);
		}
		setHorseDataForUserRetrieved(true);
	};

	useEffect(() => {
		fetchHorseProfileData();
	}, []);

	useEffect(() => {
		if (currentUser) {
			fetchHorseDataForUser();
			fetchHorseProfileData();
		}
	}, [currentUser]);

	useEffect(() => {
		if (memberData?.id) {
			AppSyncService.execute(checkIfFollowingHorse, {
				horse_code: +horseCode,
				member_id: memberData.id
			}).then((result) => {
				setFollowingHorse(!!result?.data?.getPrpHorseFollows?.horse_code);
			});
		}
	}, [memberData]);

	const followClickHandler = () => {
		if (currentUser) {
			if (!followingHorse) {
				const followHorseInput = {
					horseCode: +horseCode
				};
				AppSyncService.execute(followHorse, followHorseInput)
					.then((result) => {
						if (result?.data?.createPrpHorseFollows.horse_code) {
							setFollowingHorse(true);
							setShowFollowConfirmModal(true);
							addToast({ Message: 'Following horse', IsSuccess: true });
						} else {
							addToast({ Message: 'Failed to follow horse', IsSuccess: false });
						}
					})
					.then(() => {
						fetchHorseProfileData();
					});
			} else {
				const unfollowHorseInput = {
					horseCode: +horseCode
				};
				AppSyncService.execute(unfollowHorse, unfollowHorseInput)
					.then((result) => {
						if (result?.data?.deletePrpHorseFollows?.horse_code) {
							setFollowingHorse(false);
							addToast({ Message: 'Unfollowed horse', IsSuccess: true });
						} else {
							addToast({
								Message: 'Failed to Unfollow horse',
								IsSuccess: false
							});
						}
					})
					.then(() => {
						fetchHorseProfileData();
					});
			}
		} else {
			navigate('/login');
		}
	};

	const AdditionalOptions = (
		<AdditionalOptionsContainer>
			<AdditionalOption
				idPrefix={HorseProfileKeys.UNCLAIM_THOROUGHBRED}
				description={`Is this horse no longer in your care?`}
				buttonText="Unclaim Thoroughbred"
				onButtonClick={() => navigate(`/unclaim-thoroughbred/${horseCode}`)}
				displayOption={
					isOwner || (currentUser && checkUserAdmin(currentUser?.Roles))
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.FULL_CIRCLE}
				description={`Interested in becoming an emergency contact?`}
				buttonText="Register For Full Circle"
				onButtonClick={() => navigate(`/full-circle-form/${horseCode}`)}
				displayOption={!existingFullCircleForm}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.SHARE_INFO}
				description={`Can you provide updated information for ${addNameToUnnamedHorse(
					horseProfileData.horse?.name
				)}?`}
				buttonText="Share Information"
				onButtonClick={() => navigate(`/share-information-form/${horseCode}`)}
				displayOption={currentUser}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.REQUEST_RETRAINING}
				description={
					<>
						{`Would you like assistance transitioning ${addNameToUnnamedHorse(
							horseProfileData.horse?.name
						)} into a second career? `}
						<FormToolTip
							toolTipContainerStyle={{
								left: 35,
								width: 235,
								padding: 15,
								...(isMobile && { width: 170 })
							}}
							type="questionMark"
						>
							Once completed, this form will be circulated to Acknowledged
							Retrainers
						</FormToolTip>
					</>
				}
				buttonText="Request Retraining"
				onButtonClick={() => navigate(`/horse-placement-form/${horseCode}`)}
				displayOption={
					currentUser &&
					(ALLOWED_RETRAINING_FORM_ACCESS_MEMBER_TYPES.includes(
						memberData?.primaryMembershipType?.name
					) ||
						checkUserAdmin(currentUser?.Roles))
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.NOMINATE_RESET}
				description={`${addNameToUnnamedHorse(
					horseProfileData.horse?.name
				)} is now eligible for the RESET program.`}
				buttonText="Nominate for RESET"
				onButtonClick={() => navigate(`/reset-form/${horseCode}`)}
				displayOption={
					currentUser &&
					!horseHasInReviewResetNom &&
					horseProfileData.program?.value === 'RESET' &&
					horseProfileData.status?.status?.value === 'Eligible'
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.CONCLUDE_RESET}
				description={`${addNameToUnnamedHorse(
					horseProfileData.horse?.name
				)} is in the RESET program.`}
				buttonText="Conclude RESET"
				onButtonClick={() => navigate(`/reset-concluded-form/${horseCode}`)}
				displayOption={
					currentUser &&
					checkUserAdmin(currentUser?.Roles) &&
					horseProfileData?.program?.value === 'RESET' &&
					horseProfileData?.status.status.value !== 'Concluded'
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.NOMINATE_OHEP}
				description={`${addNameToUnnamedHorse(
					horseProfileData.horse?.name
				)} is now eligible for the OHEP program.`}
				buttonText="Nominate for OHEP"
				onButtonClick={() => navigate(`/ohep-form/${horseCode}`)}
				displayOption={
					currentUser &&
					!horseHasInReviewOhepNomination &&
					horseProfileData.program?.value === 'OHEP' &&
					horseProfileData.status?.status?.value === 'Eligible'
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.CONCLUDE_OHEP}
				description={`${addNameToUnnamedHorse(
					horseProfileData.horse?.name
				)} is in the OHEP program.`}
				buttonText="Conclude OHEP"
				onButtonClick={() => navigate(`/conclude-ohep/${horseCode}`)}
				displayOption={
					currentUser &&
					checkUserAdmin(currentUser?.Roles) &&
					horseProfileData?.program?.value === 'OHEP' &&
					horseProfileData.status?.status?.value === 'Approved'
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.NOTICE_TO_PROCEED}
				description={'Create an OHEP Notice to Proceed form.'}
				buttonText="Notice to Proceed"
				onButtonClick={() => navigate(`/notice-to-proceed/${horseCode}`)}
				displayOption={
					currentUser &&
					checkUserAdmin(currentUser?.Roles) &&
					horseProfileData.program?.value === 'OHEP' &&
					horseProfileData.status?.status?.value === 'Approved'
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.OHEP_PROVIDERS}
				description="Find a registered OHEP provider."
				buttonText="OHEP Providers"
				onButtonClick={() => navigate(OHEP_PROVIDERS_ROUTE)}
				displayOption={
					currentUser &&
					horseProfileData.program?.value === 'OHEP' &&
					horseProfileData.status?.status?.value === APPROVED
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.TRANSITION_REPORT}
				description={`Create a transition report.`}
				buttonText="Transition Report"
				onButtonClick={() => navigate(`/transition-report/${horseCode}`)}
				displayOption={
					currentUser &&
					(isUserRehomer(currentUser?.Roles) ||
						checkUserAdmin(currentUser?.Roles))
				}
			/>
			<AdditionalOption
				idPrefix={HorseProfileKeys.COMPANION_HORSE_REPORT}
				description={COMPANION_HORSE_REPORT_DETAILS}
				buttonText={COMPANION_HORSE_REPORT_TEXT}
				onButtonClick={() => navigate(`/companion-horse-report/${horseCode}`)}
				displayOption={
					currentUser &&
					isOwner &&
					horseProfileData.program?.value === RETIRED_THOROUGHBRED_COMPANION &&
					horseProfileData.status?.status?.value === REHOMED
				}
			/>
		</AdditionalOptionsContainer>
	);

	const HeaderActions = (
		<HeaderActionsContainer>
			<HeaderAction
				id={HorseProfileKeys.EDIT}
				label="Edit"
				onClick={() => navigate(`/horse-profile-edit/${horseCode}`)}
				showAction={(isAdminUser || isOwner) && tabIndex !== 2}
			/>
			<HeaderAction
				id={HorseProfileKeys.FOLLOW}
				label={followingHorse ? 'Following' : 'Follow'}
				onClick={followClickHandler}
				showAction={tabIndex === 0}
				variant={followingHorse ? 'unfilled' : 'filled'}
			/>
		</HeaderActionsContainer>
	);
	const horseAvatarVisible = shouldShowDetail(
		horseProfileData?.horse?.ottInformation?.profilePhotoVisibility,
		followingHorse,
		isAdminUser,
		isOwner
	);
	return (
		<PageContainer>
			<SEO title="Horse Profile" />
			<PageLoadSpinner
				isLoading={
					!currentUser
						? !horseProfileDataRetrieved
						: !(horseProfileDataRetrieved && horseDataForUserRetreived)
				}
			>
				<Row>
					<Col sm={12} lg={7}>
						<div className={classes.boxContainer}>
							<HorseInfoPaneHeader
								horseProfile={horseProfileData}
								headerActions={HeaderActions}
								avatarVisible={horseAvatarVisible}
								showFollowersCount={isAdminUser || isOwner}
							/>
							<Tabs
								id="horseprofile"
								index={tabIndex}
								setIndex={setTabIndex}
								tabs={[
									{ title: 'Horse Details', showTab: true },
									{
										title: 'OTT Information',
										showTab: shouldShowOTTInfoTab(
											horseProfileData?.horse?.ottInformation,
											followingHorse,
											isAdminUser,
											isOwner
										)
									},
									{
										title: 'Form Summary',
										showTab: isOwner || isAdminUser
									}
								]}
							/>
							{tabIndex === 0 && (
								<HorseProfileDataTab
									horseProfile={horseProfileData}
									isAdmin={isAdminUser}
									isOwner={isOwner}
									isFollower={followingHorse}
									isMobile={isMobile}
									horseProfileRetirement={horseProfileRetirement}
								/>
							)}
							{tabIndex === 1 && (
								<OTTInformationTab
									horseProfile={horseProfileData}
									isAdmin={isAdminUser}
									isOwner={isOwner}
									isFollower={followingHorse}
								/>
							)}
							{tabIndex === 2 && (
								<FormSummary
									formSummary={horseProfileData.horse?.racingSummary}
								/>
							)}
						</div>
					</Col>
					<Col sm={12} lg={5} style={{ paddingTop: 15 }}>
						{!isOwner && !existingClaimForm && (
							<MessageBar style={{ borderRadius: 5, marginBottom: 10 }}>
								<Col style={{ paddingLeft: 0, paddingRight: 0 }}>
									<div className={classes.claimText}>
										Fill in this form if you own, ride or have{' '}
										{addNameToUnnamedHorse(horseProfileData.horse?.name)} in
										your care currently.
									</div>
									<input
										id={HorseProfileKeys.CLAIM_THOROUGHBRED}
										type="button"
										disabled={!currentUser?.IsVerified}
										value="Claim Thoroughbred"
										className={classes.claimButton}
										onClick={() =>
											navigate(`/claim-thoroughbred-form/${horseCode}`)
										}
									/>
								</Col>
							</MessageBar>
						)}
						{AdditionalOptions}
						{(followingHorse || isOwner) && memberData && (
							<HorseUpdates
								horseAvatarVisible={horseAvatarVisible}
								horseProfile={horseProfileData}
								memberData={memberData}
								isOwner={isOwner}
							/>
						)}
					</Col>
				</Row>
			</PageLoadSpinner>
			<FollowConfirmModal
				horseName={horseProfileData?.horse?.name}
				showModal={showFollowConfirmModal}
				closeModal={() => setShowFollowConfirmModal(false)}
			/>
		</PageContainer>
	);
};

export default HorseProfile;
