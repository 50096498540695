import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import RotatingBanner from '../RotatingBanner';
import Programs from '../Programs';
import { ScreenClassRender } from 'react-grid-system';
import PictureArticle from '../PictureArticle';
import TwoColumArticle from '../TwoColumArticle';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import PartnersWidget from '../PartnersWidget';
import EventsAndMoreWidget from '../EventsAndMoreWidget';
import OTTNewsHomePage from '../OTTNewsHomePage';

const HomePage = ({
	rotatingBannerData,
	programData,
	ottSummary,
	ottImage,
	homePageArticle,
	ottContentType
}) => {
	const classes = useStyles();
	const [stats, setStats] = useState([]);

	const infoSVGContent = useStaticQuery(
		graphql`
			query allContentfulInfoSVG {
				allContentfulInfoSvg {
					nodes {
						title
						body {
							raw
						}
						tags
					}
				}
			}
		`
	);

	const contents = infoSVGContent.allContentfulInfoSvg?.nodes?.filter(
		(content) => content?.tags.includes('Each Year We Support With Image')
	);

	// Note: A couple of things we can do here. Load the data on the client so it forces a re-render
	useEffect(() => {
		let stats = [];
		contents
			?.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
			?.forEach((content) => {
				const description = content?.title;
				const body = content?.body;
				const data = body.raw;

				const parsed = JSON.parse(data);
				const image = parsed.content[0].content[0].value;
				const imgSrc = `data:image/svg+xml;utf8,${encodeURIComponent(image)}`;
				stats.push({
					description,
					src: imgSrc
				});
			});
		setStats(stats);
	}, []);

	const desktopComponent = (
		<div className={classes.container}>
			<PictureArticle
				text={ottSummary}
				title="Overview of OTT"
				imageSrc={ottImage}
				ottContentType={ottContentType}
			/>
			{programData.length > 3 && (
				<Programs data={programData} heading="Featured Programs" />
			)}
			<RotatingBanner data={rotatingBannerData} stats={stats} />
			{homePageArticle.length > 1 && (
				<TwoColumArticle
					buttonOneTitle={homePageArticle[0].buttonTitle}
					buttonTwoTitle={homePageArticle[1].buttonTitle}
					buttonOneClick={() => navigate(`/${homePageArticle[0].buttonLink}`)}
					buttonTwoClick={() => navigate(`/${homePageArticle[1].buttonLink}`)}
					textOne={homePageArticle[0].text}
					textTwo={homePageArticle[1].text}
					titleOne={homePageArticle[0].title}
					titleTwo={homePageArticle[1].title}
				/>
			)}
			<div className={classes.row}>
				<div className={classes.col8}>
					<OTTNewsHomePage />
				</div>
				<div className={classes.col4}>
					<EventsAndMoreWidget />
				</div>
			</div>
			<PartnersWidget />
		</div>
	);

	const mobileComponent = (
		<>
			<PictureArticle
				text={ottSummary}
				title="Overview of OTT"
				mobile
				imageSrc={ottImage}
				ottContentType={ottContentType}
			/>
			{programData.length > 2 && (
				<Programs data={programData} mobile heading="Featured Programs" />
			)}
			<RotatingBanner data={rotatingBannerData} stats={stats} mobile />
			{homePageArticle.length > 1 && (
				<TwoColumArticle
					buttonOneTitle={homePageArticle[0].buttonTitle}
					buttonTwoTitle={homePageArticle[1].buttonTitle}
					buttonOneClick={() => navigate(`/${homePageArticle[0].buttonLink}`)}
					buttonTwoClick={() => navigate(`/${homePageArticle[1].buttonLink}`)}
					textOne={homePageArticle[0].text}
					textTwo={homePageArticle[1].text}
					titleOne={homePageArticle[0].title}
					titleTwo={homePageArticle[1].title}
					mobile
				/>
			)}
			<OTTNewsHomePage mobile />
			<EventsAndMoreWidget mobile />
			<PartnersWidget />
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default HomePage;
