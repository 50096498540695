import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import useStyles from './styles';
import { Row, Col, useScreenClass } from 'react-grid-system';
import FormMultiSelectList from '../FormMultiSelectList';
import {
	convertToMultiListData,
	getHorseDisplayName,
	checkIsSmallScreen,
	formatDateReadableVariant,
	isOptionOther,
	sortArrayByDate
} from '../../utils/helpers';
import Accordion from '../Accordion';
import Sorting_Down_icon from '../../assets/icons/Sorting_Down_icon.svg';
import Sorting_Up_icon from '../../assets/icons/Sorting_Up_icon.svg';
import { useAuth } from '../../hooks/useAuth';
// import FormTextField from '../FormTextField';
import {
	getHorseResetForms,
	getHorseResetWeeklyForms,
	getHorseResetInitialForms,
	listFullCircleForms,
	getShareInformationForms,
	getHorsePlacementForms,
	listThoroughbredForms,
	getRoleList,
	assignRoles,
	getMemberByEmail,
	getTransitionGuides,
	getContactUsForms,
	getOHEPNominationForms,
	getOhepEoiForms,
	getEventDropdowns,
	updateLastUpdatedByUser,
	saveCompetitionAdminDismissableNotif,
	updateCompetitionAdminDismissableNotif,
	listBusinessProfilesAdminAction,
	getSafetyProgramReports,
	saveOTTStatusAndProgram,
	getDeceasedThoroughbredForms,
	getThoroughbredUnregisteredForms,
	getResetExpressInitialForms,
	getResetExpressRehomeAssessmentForms,
	getResetExpressRehabilitationConclusionForms,
	getRetrainingRehabSubsidyListings
} from '../../graphql/custom.queries';
import { getMemberData } from '../Dashboard/graphql/queries';
import AppSyncService from '../../graphql/AppSyncService';
import { useToast } from '../../hooks/useToast';
import { navigate } from '@reach/router';
import SEO from '../seo';
// import MultiDropDown from '../MultiDropDown';
import FormButton from '../FormButton';
import {
	OHEP_FORMS,
	RESET_FORMS,
	ROLE_COMPETITION_ADMIN,
	BLANK_OPTION,
	ReviewListKeys,
	BusinessProfileType,
	SafetyNetProgramReportsType,
	COMPETITION_ADMIN,
	COMPETITION_ADMIN_EXIST,
	IS_REMOVE_IDENTIFIER,
	REMOVE_COMPETITION_ADMIN,
	COMPETITION_ADMIN_LINK,
	RETIREMENT_FORMS,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	REMOVE_ACK_FOSTER,
	ACK_FOSTER,
	ACK_FOSTER_EXIST,
	FOSTER_FORMS,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	TRACEABILITY_FORMS,
	ROLE_OTT_COMMUNITY,
	ROLE_ACKNOWLEDGED_RESET_RETRAINER,
	ROLE_ID,
	RETRAINING_FORMS,
	SAFETY_NET_PORGRAM_FORMS
} from '../../utils/constants';
import { ADMIN_TAB_LIST } from './constants';
import ScrollMenu from '../ScrollMenu';
import UserSearch from '../UserSearch';
import PageContainer from '../PageContainer';
import AcknowledgedRetrainerFormsReview from './components/AcknowledgedRetrainerFormsReview';
import { ColumnContent, ResultHeading } from './components/ResultComponents';
import AddCompetitionPage from '../AddCompetitionPage';
import ClaimThoroughbredFormReview from './components/ClaimThoroughbredFormReview';
import AdminHorseUpdatesTab from '../AdminHorseUpdatesTab';
import { Link } from 'gatsby';
import SelectInputField from '../FormComponents/SelectInputField';
import TextInputField from '../FormComponents/TextInputField';
import { logToServer } from '../../utils/logger';
import SearchField from '../SearchField';
import BasicCheckbox from '../BasicCheckbox';
import LoaderSpinner from '../LoaderSpinner';

const ReviewList = () => {
	const { currentUser } = useAuth();
	const classes = useStyles();
	const { addToast } = useToast();
	const [sortOrder, setSortOrder] = useState('DESC');
	const [
		selectedClaimStatusBusiness,
		setSelectedClaimStatusBusiness
	] = useState([{ label: 'Review', value: 2 }]);
	const [
		selectedFullCircleFormStatus,
		setSelectedFullCircleFormStatus
	] = useState([{ label: 'Review', value: 2 }]);
	const [
		selectedThoroughbredFormStatus,
		setSelectedThoroughbredFormStatus
	] = useState([{ label: 'Review', value: 2 }]);
	const [
		selectedHorsePlacementStatus,
		setSelectedHorsePlacementStatus
	] = useState([{ label: 'Review', value: 2 }]);
	const [selectedResetFormStatus, setSelectedResetFormStatus] = useState([
		{ label: 'Review', value: 2 }
	]);
	const [selectedOhepFormStatus, setSelectedOhepFormStatus] = useState([
		{ label: 'Review', value: 2 }
	]);
	const [selectedOhepFormNames, setSelectedOhepFormNames] = useState([]);
	const [
		selectedTraceabilityFormNames,
		setSelectedTraceabilityFormNames
	] = useState([]);
	const [selectedResetFormNames, setSelectedResetFormNames] = useState([]);
	const [
		selectedShareInformationFormStatus,
		setSelectedShareInformationFormStatus
	] = useState([{ label: 'Review', value: 2 }]);
	const [
		selectedTransitionSupportFormStatus,
		setSelectedTransitionSupportFormStatus
	] = useState([{ label: 'Review', value: 1 }]);
	const [selectedContactFormStatus, setSelectedContactFormStatus] = useState([
		{ label: 'Review', value: 1 }
	]);
	const [contactStatusList, setContactStatusList] = useState([]);
	const [transitionStatusList, setTransitionStatusList] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [ohepStatusList, setOhepStatusList] = useState([]);
	const [ohepFormTypeList, setOhepFormTypeList] = useState([]);
	const [traceabilityFormTypeList, setTraceabilityFormTypeList] = useState([]);
	const [resetFormTypeList, setResetFormTypeList] = useState([]);
	const [fullCirclestatusList, setFullCircleStatusList] = useState([]);
	const [thoroughbredStatusList, setThoroughbredStatusList] = useState([]);
	const [
		availableForRetrainingStatusList,
		setAvailableForRetrainingStatusList
	] = useState([]);
	const [bussinessProfileList, setBusinessProfileList] = useState([]);
	const [businessProfileTypeList, setBusinessProfileTypeList] = useState([]);
	const [
		selectedBusinessProfileTypeName,
		setSelectedBusinessProfileTypeName
	] = useState([]);

	const [
		selectedSafelyNetProgramStatus,
		setSelectedSafelyNetProgramStatus
	] = useState([{ label: 'Review', value: 2 }]);
	const [safetyNetProgramReportList, setSafetyNetProgramReportList] = useState(
		[]
	);
	const [
		safetyNetProgramReportTypeList,
		setSafetyNetProgramReportTypeList
	] = useState([]);
	const [
		selectedSafetyNetProgramReportTypeName,
		setSelectedSafetyNetProgramReportTypeName
	] = useState([]);
	const [retrainingFormTypeList, setRetrainingFormTypeList] = useState([]);
	const [
		selectedRetrainingFormNames,
		setSelectedRetrainingFormNames
	] = useState([]);

	const [fullCircleFormList, setFullCircleFormList] = useState([]);
	const [horsePlacementList, setHorsePlacementList] = useState([]);
	const [ohepFormList, setOhepFormList] = useState([]);
	const [traceabilityFormList, setTraceabilityFormList] = useState([]);
	const [resetFormList, setResetFormList] = useState([]);
	const [shareInformationFormList, setShareInformationFormList] = useState([]);
	const [transitionSupportFormList, setTransitionSupportFormList] = useState(
		[]
	);
	const [contactFormList, setContactFormList] = useState([]);
	const [rolesList, setRolesList] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [selectedTab, setSelectedTab] = useState(
		ReviewListKeys.FORM_MANAGEMENT
	);
	const [email, setEmail] = useState('');
	const [contactReasons, setContactReasons] = useState([]);
	const [isCompetitionAdminSelected, setCompetitionAdminSelected] = useState(
		false
	);
	const [eventOrganisers, setEventOrganisers] = useState([]);
	const [values, setValues] = useState({
		eventOrganiser: {
			value: 0,
			isRequired: true,
			errorMessage: 'Event Organiser is required'
		},
		organiserOther: {
			value: '',
			isRequired: false
		}
	});
	const [isCompetitionDisable, setIsCompetitionDisable] = useState(true);
	const FormTypeEnum = Object.freeze({
		horseClaim: 'horseClaim',
		businessProfile: 'businessProfile',
		fullCircleForm: 'fullCircleForm',
		thoroughbredRegistrationForm: 'thoroughbredRegistrationForm',
		horsePlacementForm: 'horsePlacementForm',
		resetForm: 'resetForm',
		shareInformationForm: 'shareInformationForm',
		transitionSupportForm: 'transitionSupportForm',
		contactForm: 'contactForm',
		ohepForm: 'ohepForm',
		safetyNetProgramReportsForm: 'safetyNetProgramReportsForm'
	});
	const screenSize = useScreenClass();
	const mobile = checkIsSmallScreen(screenSize);
	const [searchByEmailValidation, setSearchByEmailValidation] = useState(null);
	const [selectedUserByEmail, setSelectedUserByEmail] = useState(null);
	const [isLoading, setIsloading] = useState(false);

	const arrayList = [
		{
			id: 1,
			value: 'Draft'
		},
		{
			id: 2,
			value: 'Review'
		},
		{
			id: 3,
			value: 'Approved'
		},
		{
			id: 4,
			value: 'Rejected'
		}
	];

	const ohepList = [
		{
			id: 1,
			value: 'Draft'
		},
		{
			id: 2,
			value: 'Review'
		},
		{
			id: 3,
			value: 'Approved'
		},
		{
			id: 4,
			value: 'Rejected'
		},
		{
			id: 5,
			value: 'Reviewed'
		}
	];

	const arrayAvailForRetrainingList = [
		{
			id: 1,
			value: 'Draft'
		},
		{
			id: 2,
			value: 'Review'
		},
		{
			id: 3,
			value: 'Approved'
		},
		{
			id: 4,
			value: 'Removed'
		},
		{
			id: 5,
			value: 'Rejected'
		}
	];

	const arrayReviewList = [
		{
			id: 1,
			value: 'Draft'
		},
		{
			id: 2,
			value: 'Review'
		},
		{
			id: 3,
			value: 'Reviewed'
		}
	];

	const arrayTransitionList = [
		{
			id: 1,
			value: 'Review'
		},
		{
			id: 2,
			value: 'Reviewed'
		}
	];

	const ohepFormTypesList = [
		{
			id: 1,
			value: OHEP_FORMS.OHEP_NOMINATION
		},
		{
			id: 2,
			value: OHEP_FORMS.OHEP_EOI
		}
	];

	const traceabilityFormTypesList = [
		{
			id: 1,
			value: TRACEABILITY_FORMS.FIND_YOUR_THOROUGHBRED
		},
		{
			id: 2,
			value: TRACEABILITY_FORMS.THOROUGHBRED_UNREGISTERED_IN_AUSTRALIA
		},
		{
			id: 3,
			value: TRACEABILITY_FORMS.DECEASED_THOROUGHBRED_UPDATE
		}
	];

	const resetFormTypesList = [
		{
			id: 1,
			value: RESET_FORMS.RESET_NOMINATION
		},
		{
			id: 2,
			value: RESET_FORMS.RESET_WEEKLY_PROGRESS
		},
		{
			id: 3,
			value: RESET_FORMS.RESET_INITIAL_ASSESSMENT
		},
		{
			id: 4,
			value: RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT
		},
		{
			id: 5,
			value: RESET_FORMS.RESET_EXPRESS_REHOME_REPORT
		},
		{
			id: 6,
			value: RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT
		}
	];

	const retrainingFormTypesList = [
		{
			id: 1,
			value: RETRAINING_FORMS.REQUEST_FOR_RETRAINING
		},
		{
			id: 2,
			value: RETRAINING_FORMS.REHABILITATION_SUBSIDY_APPLICATION
		}
	];

	const fetchRetrainingFormData = async (status, formTypes) => {
		let retrainingFormListData = [];
		if (
			formTypes.length === 0 ||
			formTypes.includes(RETRAINING_FORMS.REQUEST_FOR_RETRAINING)
		) {
			try {
				const data = await AppSyncService.execute(getHorsePlacementForms, {
					status
				});
				retrainingFormListData = retrainingFormListData.concat(
					data.data.getHorsePlacementForms.map((item) => ({
						subType: RETRAINING_FORMS.REQUEST_FOR_RETRAINING,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Retraining Records',
					IsSuccess: false
				});
			}
		}
		if (
			formTypes.length === 0 ||
			formTypes.includes(RETRAINING_FORMS.REHABILITATION_SUBSIDY_APPLICATION)
		) {
			try {
				const data = await AppSyncService.execute(
					getRetrainingRehabSubsidyListings,
					{
						status
					}
				);
				retrainingFormListData = retrainingFormListData.concat(
					data.data.getRetrainingRehabSubsidyListings.map((item) => ({
						subType: RETRAINING_FORMS.REHABILITATION_SUBSIDY_APPLICATION,
						id: item.horseStatusId,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Rehab Subsidy Records',
					IsSuccess: false
				});
			}
		}
		setHorsePlacementList(retrainingFormListData);
	};

	const fetchTraceabilityFormData = async (status, formTypes) => {
		let traceabilityFormListData = [];
		if (
			formTypes.length === 0 ||
			formTypes.includes(TRACEABILITY_FORMS.FIND_YOUR_THOROUGHBRED)
		) {
			try {
				const data = await AppSyncService.execute(listThoroughbredForms, {
					status
				});
				traceabilityFormListData = traceabilityFormListData.concat(
					data.data.getThoroughbredForms.map((item) => ({
						subType: TRACEABILITY_FORMS.FIND_YOUR_THOROUGHBRED,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Traceability Records',
					IsSuccess: false
				});
			}
		}
		if (
			formTypes.length === 0 ||
			formTypes.includes(TRACEABILITY_FORMS.DECEASED_THOROUGHBRED_UPDATE)
		) {
			try {
				const data = await AppSyncService.execute(
					getDeceasedThoroughbredForms,
					{
						status
					}
				);
				traceabilityFormListData = traceabilityFormListData.concat(
					data.data.getDeceasedThoroughbredForms.map((item) => ({
						subType: TRACEABILITY_FORMS.DECEASED_THOROUGHBRED_UPDATE,
						horseName: item.horse?.name,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Traceability Records',
					IsSuccess: false
				});
			}
		}

		if (
			formTypes.length === 0 ||
			formTypes.includes(
				TRACEABILITY_FORMS.THOROUGHBRED_UNREGISTERED_IN_AUSTRALIA
			)
		) {
			try {
				const data = await AppSyncService.execute(
					getThoroughbredUnregisteredForms,
					{
						status
					}
				);
				traceabilityFormListData = traceabilityFormListData.concat(
					data.data.getThoroughbredUnregisteredForms.map((item) => ({
						subType: TRACEABILITY_FORMS.THOROUGHBRED_UNREGISTERED_IN_AUSTRALIA,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Traceability Records',
					IsSuccess: false
				});
			}
		}

		let sortedTraceabilityArr = [];
		if (traceabilityFormListData && traceabilityFormListData.length > 0) {
			const tempArray = traceabilityFormListData.map((item) => {
				return { ...item, sortDate: item.lastUpdated };
			});
			sortedTraceabilityArr = sortArrayByDate(
				tempArray,
				'sortDate',
				'DESC',
				true
			);
		}

		setTraceabilityFormList(sortedTraceabilityArr);
	};

	const fetchOhepFormData = async (status, formTypes) => {
		let ohepFormListData = [];
		if (
			formTypes.length === 0 ||
			formTypes.includes(OHEP_FORMS.OHEP_NOMINATION)
		) {
			try {
				const data = await AppSyncService.execute(getOHEPNominationForms, {
					status
				});
				ohepFormListData = ohepFormListData.concat(
					data.data.getOhepNominationForms.map((item) => ({
						subType: OHEP_FORMS.OHEP_NOMINATION,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching OHEP Records',
					IsSuccess: false
				});
			}
		}
		if (formTypes.length === 0 || formTypes.includes(OHEP_FORMS.OHEP_EOI)) {
			try {
				const data = await AppSyncService.execute(getOhepEoiForms, {
					status
				});
				ohepFormListData = ohepFormListData.concat(
					data.data.getOhepEoiForms.map((item) => ({
						id: item.guid,
						subType: OHEP_FORMS.OHEP_EOI,
						horse: { name: item.horseName },
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching OHEP Records',
					IsSuccess: false
				});
			}
		}
		setOhepFormList(ohepFormListData);
	};

	const fetchResetFormData = async (status, formTypes) => {
		let resetFormListData = [];
		if (
			formTypes.length === 0 ||
			formTypes.includes(RESET_FORMS.RESET_NOMINATION)
		) {
			try {
				const data = await AppSyncService.execute(getHorseResetForms, {
					status
				});
				resetFormListData = resetFormListData.concat(
					data.data.getResetNominationForms.map((item) => ({
						subType: RESET_FORMS.RESET_NOMINATION,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Reset Rec..',
					IsSuccess: false
				});
			}
		}

		if (
			formTypes.length === 0 ||
			formTypes.includes(RESET_FORMS.RESET_WEEKLY_PROGRESS)
		) {
			try {
				const data = await AppSyncService.execute(getHorseResetWeeklyForms, {
					status
				});
				resetFormListData = resetFormListData.concat(
					data.data.getResetWeeklyProgressForms.map((item) => ({
						subType: RESET_FORMS.RESET_WEEKLY_PROGRESS,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Reset Rec..',
					IsSuccess: false
				});
			}
		}

		if (
			formTypes.length === 0 ||
			formTypes.includes(RESET_FORMS.RESET_EXPRESS_REHOME_REPORT)
		) {
			try {
				const data = await AppSyncService.execute(
					getResetExpressRehomeAssessmentForms,
					{
						status
					}
				);
				resetFormListData = resetFormListData.concat(
					data.data.getResetExpressRehomeAssessmentForms.map((item) => ({
						subType: RESET_FORMS.RESET_EXPRESS_REHOME_REPORT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Reset express Rec..',
					IsSuccess: false
				});
			}
		}

		if (
			formTypes.length === 0 ||
			formTypes.includes(RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT)
		) {
			try {
				const data = await AppSyncService.execute(
					getResetExpressRehabilitationConclusionForms,
					{
						status
					}
				);
				resetFormListData = resetFormListData.concat(
					data.data.getResetExpressRehabilitationConclusionForms.map(
						(item) => ({
							subType: RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT,
							...item
						})
					)
				);
			} catch {
				addToast({
					Message: 'Error Fetching Reset express Rec..',
					IsSuccess: false
				});
			}
		}

		if (
			formTypes.length === 0 ||
			formTypes.includes(RESET_FORMS.RESET_INITIAL_ASSESSMENT)
		) {
			try {
				const data = await AppSyncService.execute(getHorseResetInitialForms, {
					status
				});
				resetFormListData = resetFormListData.concat(
					data.data.getResetInitialAssessmentForms.map((item) => ({
						subType: RESET_FORMS.RESET_INITIAL_ASSESSMENT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Reset Rec..',
					IsSuccess: false
				});
			}
		}

		if (
			formTypes.length === 0 ||
			formTypes.includes(RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT)
		) {
			try {
				const data = await AppSyncService.execute(getResetExpressInitialForms, {
					status
				});
				resetFormListData = resetFormListData.concat(
					data.data.getResetExpressInitialAssessmentForms.map((item) => ({
						subType: RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Reset Rec..',
					IsSuccess: false
				});
			}
		}
		setResetFormList(resetFormListData);
	};

	const fetchBusinessProfileFormData = async (status, formTypes) => {
		try {
			const data = await AppSyncService.execute(
				listBusinessProfilesAdminAction,
				{
					status: status,
					businessProfileTypeId: formTypes
				}
			);
			setBusinessProfileList(data.data.listBusinessProfilesAdminAction);
		} catch {
			addToast({
				Message: 'Error Fetching Business Profile Rec..',
				IsSuccess: false
			});
		}
	};

	const fetchSafetyNetProgramFormData = async (status, formTypes) => {
		let safetyNetProgramListData = [];
		if (formTypes.length === 0) {
			try {
				const data = await AppSyncService.execute(getSafetyProgramReports, {
					status
				});

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getRetirementInitialAssessmentForms.map((item) => ({
						subType: RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT,
						...item
					}))
				);

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getRetirementQuarterlyAssessmentForms.map((item) => ({
						subType: RETIREMENT_FORMS.RETIREMENT_QUARTERLY_ASSESSMENT,
						...item
					}))
				);

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getFosterInitialAssessmentForms.map((item) => ({
						subType: FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT,
						...item
					}))
				);

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getFosterWeeklyAssessmentForms?.map((item) => ({
						subType: FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT,
						...item
					}))
				);

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getCompanionHorseReports.map((item) => ({
						subType: SAFETY_NET_PORGRAM_FORMS.RTCP_HORSE_WELFARE_REPORT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Safety Net Rec..',
					IsSuccess: false
				});
			}
		}

		if (formTypes.includes(RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT)) {
			try {
				const data = await AppSyncService.execute(getSafetyProgramReports, {
					status
				});

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getRetirementInitialAssessmentForms.map((item) => ({
						subType: RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Safety Net Rec..',
					IsSuccess: false
				});
			}
		}

		if (formTypes.includes(RETIREMENT_FORMS.RETIREMENT_QUARTERLY_ASSESSMENT)) {
			try {
				const data = await AppSyncService.execute(getSafetyProgramReports, {
					status
				});
				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getRetirementQuarterlyAssessmentForms.map((item) => ({
						subType: RETIREMENT_FORMS.RETIREMENT_QUARTERLY_ASSESSMENT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Safety Net Rec..',
					IsSuccess: false
				});
			}
		}

		if (formTypes.includes(FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT)) {
			try {
				const data = await AppSyncService.execute(getSafetyProgramReports, {
					status
				});

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getFosterInitialAssessmentForms.map((item) => ({
						subType: FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Safety Net Rec..',
					IsSuccess: false
				});
			}
		}

		if (formTypes.includes(FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT)) {
			try {
				const data = await AppSyncService.execute(getSafetyProgramReports, {
					status
				});

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getFosterWeeklyAssessmentForms?.map((item) => ({
						subType: FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Safety Net Rec..',
					IsSuccess: false
				});
			}
		}

		if (
			formTypes.includes(SAFETY_NET_PORGRAM_FORMS.RTCP_HORSE_WELFARE_REPORT)
		) {
			try {
				const data = await AppSyncService.execute(getSafetyProgramReports, {
					status
				});

				safetyNetProgramListData = safetyNetProgramListData.concat(
					data.data.getCompanionHorseReports.map((item) => ({
						subType: SAFETY_NET_PORGRAM_FORMS.RTCP_HORSE_WELFARE_REPORT,
						...item
					}))
				);
			} catch {
				addToast({
					Message: 'Error Fetching Safety Net Rec..',
					IsSuccess: false
				});
			}
		}

		let sortedSafetyNetArr = [];
		if (safetyNetProgramListData && safetyNetProgramListData.length > 0) {
			const tempArray = safetyNetProgramListData.map((item) => {
				return { ...item, sortDate: item.dateOfAssessment ?? item.lastUpdated };
			});
			sortedSafetyNetArr = sortArrayByDate(tempArray, 'sortDate', 'DESC', true);
		}

		setSafetyNetProgramReportList(sortedSafetyNetArr);
	};

	useEffect(() => {
		AppSyncService.execute(getRoleList).then((data) => {
			try {
				let roleList = [];
				let count = 0;
				data.data.listRoles.map((rec) => {
					roleList.push({
						id: count,
						key: rec.id,
						title: rec.id === 6 ? 'ROLE_ACKNOWLEDGED_STAR_CARER' : rec.code,
						selected: false,
						name: rec.name
					});
					count++;
				});
				setRolesList(roleList);
			} catch {
				addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
			}
		});
		AppSyncService.execute(listBusinessProfilesAdminAction, {
			status: selectedClaimStatusBusiness.map(({ label }) => label)
		}).then((data) => {
			try {
				setBusinessProfileList(data.data.listBusinessProfilesAdminAction);
			} catch {
				addToast({
					Message: 'Error Fetching Rec..',
					IsSuccess: false
				});
			}
		});
		AppSyncService.execute(getEventDropdowns).then((data) => {
			if (data?.data?.listEventDisciplines) {
				setEventOrganisers(
					[BLANK_OPTION].concat(data.data.listEventOrganisers)
				);
			}
		});
		fetchSafetyNetProgramFormData(
			selectedSafelyNetProgramStatus.map(({ label }) => label),
			[]
		);
		fetchResetFormData(
			selectedResetFormStatus.map(({ label }) => label),
			[]
		);
		fetchOhepFormData(
			selectedOhepFormStatus.map(({ label }) => label),
			[]
		);

		fetchTraceabilityFormData(
			selectedThoroughbredFormStatus.map(({ label }) => label),
			[]
		);
		fetchRetrainingFormData(
			selectedHorsePlacementStatus.map(({ label }) => label),
			[]
		);

		setTransitionStatusList(
			convertToMultiListData(arrayTransitionList, 'value')
		);
		setContactStatusList(convertToMultiListData(arrayTransitionList, 'value'));
		setOhepStatusList(convertToMultiListData(ohepList, 'value'));
		setStatusList(convertToMultiListData(arrayList, 'value'));
		setOhepFormTypeList(convertToMultiListData(ohepFormTypesList, 'value'));
		setTraceabilityFormTypeList(
			convertToMultiListData(traceabilityFormTypesList, 'value')
		);
		setResetFormTypeList(convertToMultiListData(resetFormTypesList, 'value'));
		setFullCircleStatusList(convertToMultiListData(arrayReviewList, 'value'));
		setThoroughbredStatusList(convertToMultiListData(arrayReviewList, 'value'));
		setAvailableForRetrainingStatusList(
			convertToMultiListData(arrayAvailForRetrainingList, 'value')
		);
		setBusinessProfileTypeList(
			convertToMultiListData(BusinessProfileType, 'value')
		);
		setSafetyNetProgramReportTypeList(
			convertToMultiListData(SafetyNetProgramReportsType, 'value')
		);
		setRetrainingFormTypeList(
			convertToMultiListData(retrainingFormTypesList, 'value')
		);
		// Fetch full circle forms
		fetchFullCircleForms(selectedFullCircleFormStatus.map((s) => s.label));
		fetchShareInformationForms(
			selectedShareInformationFormStatus.map((s) => s.label)
		);
		fetchTransitionSupportForms(
			selectedTransitionSupportFormStatus.map((s) => s.label)
		);
		fetchContactForms(selectedContactFormStatus.map((s) => s.label));
	}, []);

	const fetchContactForms = (status) => {
		AppSyncService.execute(getContactUsForms, { status }).then((data) => {
			if (data?.data?.getContactUsForms) {
				setContactFormList(data.data.getContactUsForms);
			}
			if (data?.data?.listContactReasons) {
				setContactReasons(data?.data?.listContactReasons);
			}
		});
	};

	const fetchTransitionSupportForms = (status) => {
		AppSyncService.execute(getTransitionGuides, { status }).then((data) => {
			if (data?.data?.getTransitionGuides) {
				setTransitionSupportFormList(data.data.getTransitionGuides);
			}
		});
	};

	const gotoForm = (id, type, subType = null) => {
		switch (type) {
			case FormTypeEnum.businessProfile:
				navigate(`/business-profile-review/${id}`);
				break;

			case FormTypeEnum.fullCircleForm:
				navigate(`/full-circle-review/${id}`);
				break;

			case FormTypeEnum.thoroughbredRegistrationForm:
				if (subType === TRACEABILITY_FORMS.FIND_YOUR_THOROUGHBRED)
					navigate(`/thoroughbred-registration-review/${id}`);
				else if (subType === TRACEABILITY_FORMS.DECEASED_THOROUGHBRED_UPDATE)
					navigate(`/deceased-thoroughbred-form/${id}`);
				else if (
					subType === TRACEABILITY_FORMS.THOROUGHBRED_UNREGISTERED_IN_AUSTRALIA
				)
					navigate(`/thoroughbred-unregistered-form-review/${id}`);
				break;
			case FormTypeEnum.horsePlacementForm:
				if (subType === RETRAINING_FORMS.REQUEST_FOR_RETRAINING) {
					navigate(`/available-for-retraining/${id}`);
				} else if (
					subType === RETRAINING_FORMS.REHABILITATION_SUBSIDY_APPLICATION
				) {
					navigate(`/rehabilitation-subsidy/${id}`);
				}
				break;
			case FormTypeEnum.resetForm:
				return `/reset-form-review/${id}${
					subType ? '?' + qs.stringify({ subType: subType }) : ''
				}`;
			case FormTypeEnum.shareInformationForm:
				navigate(`/share-information-form-review/${id}`, {
					state: { subType }
				});
				break;
			case FormTypeEnum.transitionSupportForm:
				navigate(`/transition-guide-form/${id}`);
				break;
			case FormTypeEnum.contactForm:
				navigate(`/contact-us-form/${id}`);
				break;
			case FormTypeEnum.ohepForm:
				if (subType === OHEP_FORMS.OHEP_EOI) {
					return `/ohep-eoi-form-review/${id}`;
				} else if (subType === OHEP_FORMS.OHEP_NOMINATION) {
					return `/ohep-form-review/${id}`;
				}
				break;
			case FormTypeEnum.safetyNetProgramReportsForm:
				if (subType === SAFETY_NET_PORGRAM_FORMS.RTCP_HORSE_WELFARE_REPORT)
					navigate(`/companion-horse-report-by-id/${id}`);
				else
					navigate(
						`/safety-net-program-reports/${id}?${qs.stringify({
							subType: subType
						})}`
					);
				break;
			default:
				break;
		}
	};

	const renderResults = (data, type, isMobile) => {
		if (data.length > 0) {
			if (type === 'businessProfile') {
				return data.map((d) => {
					return (
						<div className={classes.singleResultContainer} key={d.id}>
							<ColumnContent style={{ maxWidth: 200 }}>
								<strong
									className={classes.longText}
								>{`${d.firstName} ${d.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent>{d.status}</ColumnContent>
							<ColumnContent>
								<div className={classes.button}>
									<div
										className={classes.actionButton}
										onClick={() => gotoForm(d.id, type)}
									>
										Review
									</div>
								</div>
							</ColumnContent>
						</div>
					);
				});
			}
			if (
				type === FormTypeEnum.fullCircleForm ||
				type === FormTypeEnum.shareInformationForm
			) {
				return data.map((d) => {
					return (
						<div className={classes.singleResultContainer} key={d.id}>
							<ColumnContent style={{ maxWidth: 200 }}>
								<strong
									className={classes.longText}
								>{`${d.member.firstName} ${d.member.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent>
								<span className={classes.longText}>{d.horse.name}</span>
							</ColumnContent>
							<ColumnContent>{d.status}</ColumnContent>
							<ColumnContent>
								<div className={classes.button}>
									<div
										className={classes.actionButton}
										onClick={() => gotoForm(d.id, type)}
									>
										Review
									</div>
								</div>
							</ColumnContent>
						</div>
					);
				});
			}
			if (type === FormTypeEnum.thoroughbredRegistrationForm) {
				return data.map((d) => {
					const id =
						d.subType === TRACEABILITY_FORMS.FIND_YOUR_THOROUGHBRED
							? d.guid
							: d.id;
					return (
						<div className={classes.singleResultContainer} key={d.guid}>
							<ColumnContent
								style={isMobile ? { maxWidth: 200 } : { width: '18%' }}
							>
								<strong
									className={
										isMobile ? classes.longText : classes.longTextWithHeading
									}
								>{`${d.member.firstName} ${d.member.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent style={isMobile ? {} : { width: '18%' }}>
								<span className={classes.longText}>{d.horseName}</span>
							</ColumnContent>
							<ColumnContent style={isMobile ? {} : { width: '18%' }}>
								{d.subType}
							</ColumnContent>
							<ColumnContent style={isMobile ? {} : { width: '18%' }}>
								{formatDateReadableVariant(d.lastUpdated)}
							</ColumnContent>
							<ColumnContent style={isMobile ? {} : { width: '18%' }}>
								{d.status}
							</ColumnContent>
							<ColumnContent style={isMobile ? {} : { width: '18%' }}>
								<div className={classes.button}>
									<div
										className={classes.actionButton}
										onClick={() => gotoForm(id, type, d.subType)}
									>
										Review
									</div>
								</div>
							</ColumnContent>
						</div>
					);
				});
			}
			if (type === FormTypeEnum.horsePlacementForm) {
				return data.map((d) => {
					return (
						<div className={classes.singleResultContainer} key={d.id}>
							<ColumnContent style={{ maxWidth: 200 }}>
								<strong
									className={classes.longText}
								>{`${d.member.firstName} ${d.member.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent>
								<span className={classes.longText}>{d.horse.name}</span>
							</ColumnContent>
							<ColumnContent>{d.subType}</ColumnContent>
							<ColumnContent>{d.status}</ColumnContent>
							<ColumnContent>
								<div className={classes.button}>
									<div
										className={classes.actionButton}
										onClick={() => gotoForm(d.id, type, d.subType)}
									>
										Review
									</div>
								</div>
							</ColumnContent>
						</div>
					);
				});
			}
			if (type === FormTypeEnum.ohepForm) {
				return data.map((d) => {
					return (
						<div className={classes.singleResultContainer} key={d.id}>
							<ColumnContent style={{ maxWidth: 200 }}>
								<strong
									className={classes.longText}
								>{`${d.member.firstName} ${d.member.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent>
								<span className={classes.longText}>{d.horse.name}</span>
							</ColumnContent>
							<ColumnContent>{d.subType}</ColumnContent>
							<ColumnContent>{d.status}</ColumnContent>
							<ColumnContent>
								<Link to={gotoForm(d.id, type, d.subType)}>
									<div className={classes.button}>
										<div className={classes.actionButton}>Review</div>
									</div>
								</Link>
							</ColumnContent>
						</div>
					);
				});
			}
			if (type === FormTypeEnum.resetForm) {
				return data.map((d) => {
					return (
						<div
							className={classes.singleResultContainer}
							style={isMobile ? { width: 650 } : { width: '100%' }}
							key={d.subType + d.id}
						>
							<ColumnContent style={{ maxWidth: 200 }}>
								<strong
									className={classes.longText}
								>{`${d.member?.firstName} ${d.member?.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent style={{ maxWidth: 200 }}>
								<span className={classes.longText}>
									{getHorseDisplayName(
										d.horse?.name,
										d.horse?.ottInformation?.paddockName
									)}
								</span>
							</ColumnContent>
							<ColumnContent style={{ maxWidth: 200 }}>
								<span className={classes.longText}>{d.subType}</span>
							</ColumnContent>
							<ColumnContent
								style={
									isMobile
										? { paddingLeft: 0, minWidth: 75 }
										: { maxWidth: 200 }
								}
							>
								{d.weekId ? `Week: ${d.weekId}` : ''}
							</ColumnContent>
							<ColumnContent
								style={
									isMobile
										? { paddingLeft: 0, minWidth: 75 }
										: { maxWidth: 200 }
								}
							>
								{d.status}
							</ColumnContent>
							<ColumnContent>
								<Link to={gotoForm(d.id, type, d.subType)}>
									<div className={classes.button}>
										<div className={classes.actionButton}>Review</div>
									</div>
								</Link>
							</ColumnContent>
						</div>
					);
				});
			}
			if (type === FormTypeEnum.transitionSupportForm) {
				return data.map((d) => {
					return (
						<div className={classes.singleResultContainer} key={d.id}>
							<ColumnContent style={{ maxWidth: 200 }}>
								<strong
									className={classes.longText}
								>{`${d.member.firstName} ${d.member.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent>
								<span className={classes.longText}>
									{d.horseName || 'No name provided'}
								</span>
							</ColumnContent>
							<ColumnContent>{d.status}</ColumnContent>
							<ColumnContent>
								{formatDateReadableVariant(d.created)}
							</ColumnContent>
							<ColumnContent>
								<div className={classes.button}>
									<div
										className={classes.actionButton}
										onClick={() => gotoForm(d.id, type)}
									>
										Review
									</div>
								</div>
							</ColumnContent>
						</div>
					);
				});
			}
			if (type === FormTypeEnum.contactForm) {
				return data.map((d) => {
					return (
						<div className={classes.singleResultContainer} key={d.id}>
							<ColumnContent style={{ maxWidth: 200 }}>
								<strong className={classes.longText}>{`${
									d.member?.firstName || d?.firstName
								} ${d.member?.lastName || d?.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent>
								<span className={classes.longText}>
									{
										contactReasons.find((item) => item.id == d.contactReason)
											?.value
									}
								</span>
							</ColumnContent>
							<ColumnContent>
								{formatDateReadableVariant(d.created)}
							</ColumnContent>
							<ColumnContent>{d.status}</ColumnContent>
							<ColumnContent>
								<div className={classes.button}>
									<div
										className={classes.actionButton}
										onClick={() => gotoForm(d.id, type)}
									>
										Review
									</div>
								</div>
							</ColumnContent>
						</div>
					);
				});
			}

			if (type === FormTypeEnum.safetyNetProgramReportsForm) {
				return data.map((d) => {
					const { member } = d;
					const { horse } = d;
					const keyItem = d.id + d.subType;
					const weekId = d.weekId;
					return (
						<div className={classes.singleResultContainer} key={keyItem}>
							<ColumnContent style={{ maxWidth: 160 }}>
								<strong
									className={classes.longText}
								>{`${member?.firstName} ${member?.lastName}`}</strong>
							</ColumnContent>
							<ColumnContent style={{ maxWidth: 160 }}>
								<span className={classes.longText}>
									{horse.name || 'No name provided'}
								</span>
							</ColumnContent>
							<ColumnContent style={{ maxWidth: 160 }}>
								<span className={classes.longText}> {d.subType}</span>
							</ColumnContent>
							<ColumnContent style={{ maxWidth: 100 }}>
								<span className={classes.longText}>
									{weekId ? `Week: ${weekId}` : ''}
								</span>
							</ColumnContent>
							<ColumnContent>
								{formatDateReadableVariant(d.dateOfAssessment || d.lastUpdated)}
							</ColumnContent>
							<ColumnContent>{d.status}</ColumnContent>
							<ColumnContent>
								<div className={classes.button}>
									<div
										className={classes.actionButton}
										onClick={() => gotoForm(d.id, type, d.subType)}
									>
										Review
									</div>
								</div>
							</ColumnContent>
						</div>
					);
				});
			}
		}
	};

	const renderTraceabilityHeadings = (headings) => {
		return (
			<ResultHeading key={`review-list-table-header`}>
				{headings.map((heading, index) => (
					<ColumnContent
						key={`review-list-heading-${index}`}
						style={
							heading.label === 'Name' ? { width: '18%' } : { width: '18%' }
						}
					>
						{heading.label}
						{heading.isSortable && (
							<span
								onClick={() =>
									heading.sortFunction(
										traceabilityFormList,
										setTraceabilityFormList
									)
								}
								className={classes.sortIcon}
							>
								{sortOrder === 'DESC' && <img src={Sorting_Down_icon} />}
								{sortOrder === 'ASC' && <img src={Sorting_Up_icon} />}
							</span>
						)}
					</ColumnContent>
				))}
			</ResultHeading>
		);
	};

	const updateBusinessProfiles = (value) => {
		setSelectedClaimStatusBusiness(value);
		fetchBusinessProfileFormData(
			value.map((d) => d.label),
			selectedBusinessProfileTypeName.map((d) => d.value)
		);
	};

	const updateSafetyNetPrograms = (value) => {
		setSelectedSafelyNetProgramStatus(value);
		fetchSafetyNetProgramFormData(
			value.map((d) => d.label),
			selectedSafetyNetProgramReportTypeName.map((d) => d.label)
		);
	};

	const updateTraceabilityForms = (value) => {
		setSelectedThoroughbredFormStatus(value);
		fetchTraceabilityFormData(
			value.map((d) => d.label),
			selectedTraceabilityFormNames.map((d) => d.label)
		);
	};

	const updateFullCircleForms = (value) => {
		setSelectedFullCircleFormStatus(value);
		fetchFullCircleForms(value.map((d) => d.label));
	};

	const fetchFullCircleForms = (status) => {
		AppSyncService.execute(listFullCircleForms, { status }).then((data) => {
			try {
				setFullCircleFormList(data.data.getFullCircles);
			} catch {
				addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
			}
		});
	};

	const updateHorsePlacementForms = (value) => {
		setSelectedHorsePlacementStatus(value);
		fetchRetrainingFormData(
			value.map((d) => d.label),
			selectedRetrainingFormNames.map((d) => d.label)
		);
	};

	const updateResetForms = (value) => {
		setSelectedResetFormStatus(value);
		fetchResetFormData(
			value.map((d) => d.label),
			selectedResetFormNames.map((d) => d.label)
		);
	};

	const updateOhepForms = (value) => {
		setSelectedOhepFormStatus(value);
		fetchOhepFormData(
			value.map((d) => d.label),
			selectedOhepFormNames.map((d) => d.label)
		);
	};

	const updateOhepFormsByName = (ohepFormType) => {
		setSelectedOhepFormNames(ohepFormType);
		fetchOhepFormData(
			selectedOhepFormStatus.map((d) => d.label),
			ohepFormType.map((d) => d.label)
		);
	};

	const updateTraceabilityFormsByName = (traceabilityFormType) => {
		setSelectedTraceabilityFormNames(traceabilityFormType);
		fetchTraceabilityFormData(
			selectedThoroughbredFormStatus.map((d) => d.label),
			traceabilityFormType.map((d) => d.label)
		);
	};

	const updateResetFormsByName = (resetFormType) => {
		setSelectedResetFormNames(resetFormType);
		fetchResetFormData(
			selectedResetFormStatus.map((d) => d.label),
			resetFormType.map((d) => d.label)
		);
	};

	const updateBusinessProfileFormsByName = (businessProfileType) => {
		setSelectedBusinessProfileTypeName(businessProfileType);
		fetchBusinessProfileFormData(
			selectedClaimStatusBusiness.map((d) => d.label),
			businessProfileType.map((d) => d.value)
		);
	};

	const updateSafetyNetProgramFormsByName = (safetyNetProgramType) => {
		setSelectedSafetyNetProgramReportTypeName(safetyNetProgramType);
		fetchSafetyNetProgramFormData(
			selectedSafelyNetProgramStatus.map((d) => d.label),
			safetyNetProgramType.map((d) => d.label)
		);
	};

	const updateShareInformationForms = (value) => {
		setSelectedShareInformationFormStatus(value);
		fetchShareInformationForms(value.map((d) => d.label));
	};

	const updateSelectedTransitionSupportForms = (value) => {
		setSelectedTransitionSupportFormStatus(value);
		fetchTransitionSupportForms(value.map((d) => d.label));
	};

	const updateSelectedContactForms = (value) => {
		setSelectedContactFormStatus(value);
		fetchContactForms(value.map((d) => d.label));
	};

	const updateRetrainingFormsByName = (retrainingFormType) => {
		setSelectedRetrainingFormNames(retrainingFormType);
		fetchRetrainingFormData(
			selectedHorsePlacementStatus.map((d) => d.label),
			retrainingFormType.map((d) => d.label)
		);
	};

	const fetchShareInformationForms = (status) => {
		AppSyncService.execute(getShareInformationForms, { status }).then(
			(data) => {
				try {
					setShareInformationFormList(data.data.getShareInformationForms);
				} catch {
					addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
				}
			}
		);
	};

	const evaluateDisableUpdateButton = (email, roles) => {
		let emailVal = email.length !== 0 ? email : email;
		if (emailVal.length !== 0 && roles.length !== 0) {
			if (roles.some((role) => role.value === ROLE_COMPETITION_ADMIN)) {
				if (values.eventOrganiser.value !== 0) {
					if (
						values.eventOrganiser.value === 12 &&
						values.organiserOther.value.length === 0
					) {
						setIsCompetitionDisable(true);
					} else {
						setIsCompetitionDisable(false);
					}
				} else {
					setIsCompetitionDisable(true);
				}
			} else {
				setIsCompetitionDisable(false);
			}
		} else if (emailVal.length !== 0 && values.eventOrganiser === 12) {
			if (!values.organiserOther.value || !email) {
				setIsCompetitionDisable(false);
			} else {
				setIsCompetitionDisable(true);
			}
		} else {
			setIsCompetitionDisable(true);
		}
	};

	const resetRole = (id) => {
		const temp = rolesList;
		const selectedItem = temp.find((item) => item.key === id);
		selectedItem.selected = !selectedItem.selected;

		let roles = Object.assign([], selectedRoles);
		let hasRoleCompetitionAdmin = false;
		let hasRoleResetRetrainer = false;
		let hasRoleRetrainer = false;
		if (selectedItem.selected) {
			roles.push({
				key: selectedItem.key,
				value:
					id === ROLE_ID.ACK_STAR_CARER
						? ROLE_ACKNOWLEDGED_FOSTER_CARER
						: selectedItem.title
			});
		}

		if (!selectedItem.selected) {
			const key = selectedItem.key;
			const filterIndex = roles
				.map(function (e) {
					return e.key;
				})
				.indexOf(key);
			if (filterIndex > -1) {
				roles.splice(filterIndex, 1);
			}
		}
		hasRoleRetrainer = roles.some(
			(role) => role.value === ROLE_RV_ACKNOWLEDGED_RETRAINER
		);
		hasRoleResetRetrainer = roles.some(
			(role) => role.value === ROLE_ACKNOWLEDGED_RESET_RETRAINER
		);
		if (hasRoleResetRetrainer && !hasRoleRetrainer) {
			roles = roles.filter(
				(item) => item.value !== ROLE_ACKNOWLEDGED_RESET_RETRAINER
			);
			temp.map((item) => {
				if (item.key == ROLE_ID.ACK_RESET_RETRAINER) {
					item.selected = false;
				}
				return item;
			});
		}
		hasRoleCompetitionAdmin = roles.some(
			(role) => role.value === ROLE_COMPETITION_ADMIN
		);
		if (!hasRoleCompetitionAdmin) {
			values.eventOrganiser.value = 0;
		}
		//iterate and make OTT Community role default to selected
		temp.map((item) => {
			if (item.title == ROLE_OTT_COMMUNITY) {
				item.selected = true;
			}
			return item;
		});
		const ottcRoleExist = roles.some(
			(role) => role.value == ROLE_OTT_COMMUNITY
		);
		if (!ottcRoleExist) {
			roles = [
				...roles,
				{ key: ROLE_ID.OTT_COMMUNITY, value: ROLE_OTT_COMMUNITY }
			];
		}
		setRolesList(temp);
		setSelectedRoles(roles);
		setCompetitionAdminSelected(hasRoleCompetitionAdmin);
		evaluateDisableUpdateButton(email, roles);
	};

	const updateLastUpdatedBy = async (externalId) => {
		try {
			const result = await AppSyncService.execute(updateLastUpdatedByUser, {
				memberExternalId: externalId,
				fullName: currentUser?.Name
			});
			if (result?.data) {
				console.log('');
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`ReviewList => updateLastUpdatedBy`
			);
		}
	};

	const addAckFosterNotif = async (memberInfo) => {
		const { id } = memberInfo;
		try {
			const result = await AppSyncService.execute(
				saveCompetitionAdminDismissableNotif,
				{
					memberId: id,
					source: REMOVE_ACK_FOSTER,
					link: COMPETITION_ADMIN_LINK
				}
			);
			if (result?.data) {
				console.log('notification');
			}
		} catch (error) {
			logToServer(currentUser?.UserId, error, `Dashboard => addAckFosterNotif`);
		}
	};

	const updateAckFosterNofitcation = async (id) => {
		try {
			const result = await AppSyncService.execute(
				updateCompetitionAdminDismissableNotif,
				{
					memberId: id,
					source: REMOVE_ACK_FOSTER,
					link: COMPETITION_ADMIN_LINK
				}
			);
			console.log(result, 'UPDATED');
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => updateAckFosterNofitcation`
			);
		}
	};

	const addCompetitionAdminNotif = async (memberInfo) => {
		const { id } = memberInfo;
		try {
			const result = await AppSyncService.execute(
				saveCompetitionAdminDismissableNotif,
				{
					memberId: id,
					source: REMOVE_COMPETITION_ADMIN,
					link: COMPETITION_ADMIN_LINK
				}
			);
			if (result?.data) {
				console.log('notification');
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => addCompetitionAdminNotif`
			);
		}
	};

	const updateCompetitionAdminNofitcation = async (id) => {
		try {
			const result = await AppSyncService.execute(
				updateCompetitionAdminDismissableNotif,
				{
					memberId: id,
					source: REMOVE_COMPETITION_ADMIN,
					link: COMPETITION_ADMIN_LINK
				}
			);
			console.log(result, 'UPDATED');
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => updateCompetitionAdminNofitcation`
			);
		}
	};

	const evaluateAddingNotifAckFoster = (dataNotif) => {
		let ackFosterNotifExist = false;
		let ackFosterNotifRemove = false;
		dataNotif.notifications.forEach((memberNotif) => {
			if (memberNotif.type === ACK_FOSTER) {
				let ackFosterExistTempVal = memberNotif.identifiers.find(
					(memberIdentifier) => memberIdentifier.id === ACK_FOSTER_EXIST
				)?.value;
				ackFosterNotifExist = ackFosterExistTempVal === 'true';
				let ackFosterNotifRemoveTempVal = memberNotif.identifiers.find(
					(memberIdentifier) => memberIdentifier.id === IS_REMOVE_IDENTIFIER
				)?.value;
				ackFosterNotifRemove = ackFosterNotifRemoveTempVal === 'true';
			}
		});
		if (!ackFosterNotifExist && !ackFosterNotifRemove) {
			addAckFosterNotif(dataNotif);
		} else {
			updateAckFosterNofitcation(dataNotif?.id);
		}
	};

	const fetchMemberData = async (data) => {
		const { getMemberByEmail } = data;
		const result = await AppSyncService.execute(getMemberData, {
			externalId: getMemberByEmail.externalId
		});
		if (result?.data?.getMember) {
			const competitionRoleAdminExist = result.data.getMember.roles.some(
				(memberRole) => memberRole.code === ROLE_COMPETITION_ADMIN
			);
			const ackFosterRoleExist = result.data.getMember.roles.some(
				(memberRole) => memberRole.code === ROLE_ACKNOWLEDGED_FOSTER_CARER
			);
			let competitionAdminNotifExist = false;
			let competitionAdminNotifRemove = false;
			result.data.getMember.notifications.forEach((memberNotif) => {
				if (memberNotif.type === COMPETITION_ADMIN) {
					let competitionAdminExistTempVal = memberNotif.identifiers.find(
						(memberIdentifier) =>
							memberIdentifier.id === COMPETITION_ADMIN_EXIST
					)?.value;
					competitionAdminNotifExist = competitionAdminExistTempVal === 'true';
					let competitionAdminNotifRemoveTempVal = memberNotif.identifiers.find(
						(memberIdentifier) => memberIdentifier.id === IS_REMOVE_IDENTIFIER
					)?.value;
					competitionAdminNotifRemove =
						competitionAdminNotifRemoveTempVal === 'true';
				}
			});

			if (competitionRoleAdminExist) {
				if (!competitionAdminNotifExist && !competitionAdminNotifRemove) {
					addCompetitionAdminNotif(result.data.getMember);
				} else {
					updateCompetitionAdminNofitcation(result.data.getMember.id);
				}
			}
			if (ackFosterRoleExist) {
				evaluateAddingNotifAckFoster(result.data.getMember);
			}
		}
	};

	const onSearchUserByEmail = async () => {
		if (email && email.length > 0) {
			const result = await AppSyncService.execute(getMemberByEmail, { email });
			if (result.data) {
				setSearchByEmailValidation(null);
				setSelectedUserByEmail(result.data?.getMemberByEmail);
				let currentUserRoles = result.data?.getMemberByEmail.roles.map(
					(item) => {
						if (
							item.code == 'ROLE_ACKNOWLEDGED_FOSTER_CARER' ||
							item.code == 'ROLE_ACKNOWLEDGED_STAR_CARER'
						) {
							item.code = 'ROLE_ACKNOWLEDGED_STAR_CARER';
						}
						return item.code;
					}
				);
				let hasRoleCompetitionAdmin = false;
				let tempRoles = rolesList;
				//reset first to unselected
				tempRoles.map((item) => {
					item.selected = false;
					return item;
				});
				tempRoles.map((item) => {
					if (currentUserRoles.includes(item.title)) {
						item.selected = true;
					}
					return item;
				});
				let roles = tempRoles
					.filter((role) => role.selected)
					.map((item) => ({
						key: item.key,
						value:
							item.title == 'ROLE_ACKNOWLEDGED_STAR_CARER'
								? 'ROLE_ACKNOWLEDGED_FOSTER_CARER'
								: item.title
					}));
				hasRoleCompetitionAdmin = roles.some(
					(role) => role.value === ROLE_COMPETITION_ADMIN
				);
				if (!hasRoleCompetitionAdmin) {
					values.eventOrganiser.value = 0;
				}
				setCompetitionAdminSelected(hasRoleCompetitionAdmin);
				const eventOrganiserVal = {
					eventOrganiser: {
						value: result.data?.getMemberByEmail?.eventOrganiser
							? result.data?.getMemberByEmail?.eventOrganiser?.id
							: 0
					},
					organiserOther: {
						value: result.data?.getMemberByEmail?.eventOrganiser?.organiserOther
							? result.data?.getMemberByEmail?.eventOrganiser?.organiserOther
							: ''
					}
				};
				if (result.data?.getMemberByEmail?.eventOrganiser?.id) {
					setValues({ ...eventOrganiserVal });
				}
				setSelectedRoles([...roles]);
				setRolesList([...tempRoles]);
			} else {
				setSearchByEmailValidation({ invalid: true, message: 'No user found' });
			}
		}
	};

	const updateRoles = () => {
		setIsloading(true);
		let roles = [];
		if (selectedRoles.length > 0 && email != '') {
			selectedRoles.map((d) => {
				roles.push(d.value);
			});
			getMemberByEmail;
			AppSyncService.execute(getMemberByEmail, { email }).then((data) => {
				if (data.data != null) {
					fetchMemberData(data.data);
					const currentUserRetrainerRoleExist = data.data?.getMemberByEmail?.roles.some(
						(currentRole) => currentRole.code === ROLE_RV_ACKNOWLEDGED_RETRAINER
					);
					const retrainerRoleExistSelected = !selectedRoles.some(
						(newSelectedRole) =>
							newSelectedRole.value === ROLE_RV_ACKNOWLEDGED_RETRAINER
					);
					if (currentUserRetrainerRoleExist && retrainerRoleExistSelected) {
						const retraininingHorsesCodedForSelectedUser = data?.data?.getMemberByEmail?.retrainingData?.retrainingThoroughbreds.map(
							(retrainingHorses) => retrainingHorses?.horse?.horseCode
						);
						const externalId = data.data?.getMemberByEmail?.externalId;
						const payload = {
							asOfDate: new Date(),
							horseCode: retraininingHorsesCodedForSelectedUser,
							programId: 2,
							statusId: 5,
							memberExternalId: externalId
						};
						AppSyncService.execute(saveOTTStatusAndProgram, payload);
					}
					const externalId = data.data?.getMemberByEmail?.externalId;
					const eventOrganiserId = values?.eventOrganiser
						? values?.eventOrganiser?.value
						: null;
					const eventOrganiserOther = values?.organiserOther
						? values?.organiserOther?.value
						: null;
					AppSyncService.execute(assignRoles, {
						roles,
						email,
						...(eventOrganiserId && { eventOrganiserId }),
						...(eventOrganiserOther && { eventOrganiserOther })
					}).then((data) => {
						try {
							if (data.data.assignRoles === true) {
								addToast({ Message: 'Role Updated', IsSuccess: true });
								updateLastUpdatedBy(externalId);
							} else {
								addToast({ Message: 'Error Updating', IsSuccess: false });
							}
							setIsloading(false);
						} catch {
							addToast({ Message: 'Error Updating', IsSuccess: false });
							setIsloading(false);
						}
					});
				} else {
					addToast({ Message: 'No Email Found', IsSuccess: false });
					setIsloading(false);
				}
			});
		} else {
			addToast({ Message: 'Form Error', IsSuccess: false });
			setIsloading(false);
		}
	};

	const handleChangeDropDown = (e, fieldsToClear) => {
		let items = {};
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value)
		};
		items[e.target.name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}

		setValues(items);
		setIsCompetitionDisable(
			items.eventOrganiser.value === 0 || items.eventOrganiser.value === 12
		);
	};

	const handleChange = (e, field) => {
		let items = { ...values };
		let item = {
			...items[field],
			value: e.target.value
		};
		items[field] = item;
		setValues(items);
		setIsCompetitionDisable(!items.organiserOther.value || !email);
	};

	const sortAsOfDate = (list, setList) => {
		const newList = [...list];
		newList.sort((a, b) => {
			if (sortOrder === 'ASC')
				return a['lastUpdated'] > b['lastUpdated'] ? -1 : 1;
			else return a['lastUpdated'] > b['lastUpdated'] ? 1 : -1;
		});
		setList(newList);
		setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
	};

	const traceabilityHeadings = [
		{
			label: 'Name',
			isSortable: false
		},
		{
			label: 'Horse',
			isSortable: false
		},
		{
			label: 'Form',
			isSortable: false
		},
		{
			label: 'As Of Date',
			isSortable: true,
			sortFunction: (list, setList) => sortAsOfDate(list, setList)
		},
		{
			label: 'Status',
			isSortable: false
		},
		{
			label: '',
			isSortable: false
		}
	];

	const keyCheck = (key) => {
		if (key === 13) {
			onSearchUserByEmail();
		}
	};
	return (
		<PageContainer>
			<SEO title="Forms List" />
			<div className={classes.hello}></div>
			<div className={classes.header}>Welcome {currentUser?.Name}</div>
			<div className={classes.scrollableTabsContainer}>
				<ScrollMenu
					menuList={ADMIN_TAB_LIST}
					selectedSearch={selectedTab}
					onClick={(newTabSelection) => setSelectedTab(newTabSelection)}
				/>
			</div>
			{selectedTab === ReviewListKeys.FORM_MANAGEMENT && (
				<>
					<ClaimThoroughbredFormReview />
					<Accordion
						id={ReviewListKeys.CONTACT_FORMS}
						title={`Contact Forms (${contactFormList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={contactStatusList}
									selectedIds={selectedContactFormStatus}
									onSelectChange={updateSelectedContactForms}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.CONTACT_FORMS_RESULT_CONTAINER}
							className={classes.resultContainer}
							style={contactFormList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(contactFormList, FormTypeEnum.contactForm, mobile)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.BUSINESS_PROFILE_ACKNOWLEDGED_RETRAINER_FORMS}
						title={`Business Profile Requests (${bussinessProfileList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={statusList}
									selectedIds={selectedClaimStatusBusiness}
									onSelectChange={updateBusinessProfiles}
								/>
							</div>
						</div>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Name</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterFormName"
									listOptions={businessProfileTypeList}
									selectedIds={selectedBusinessProfileTypeName}
									onSelectChange={updateBusinessProfileFormsByName}
								/>
							</div>
						</div>
						<div
							id={
								ReviewListKeys.BUSINESS_PROFILE_ACKNOWLEDGED_RETRAINER_FORMS_RESULT_CONTAINER
							}
							className={classes.resultContainer}
							style={bussinessProfileList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(bussinessProfileList, 'businessProfile', mobile)}
						</div>
					</Accordion>
					<AcknowledgedRetrainerFormsReview />
					<Accordion
						id={ReviewListKeys.FULL_CIRCLE_FORMS}
						title={`Full Circle (${fullCircleFormList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={fullCirclestatusList}
									selectedIds={selectedFullCircleFormStatus}
									onSelectChange={updateFullCircleForms}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.FULL_CIRCLE_FORMS_RESULT_CONTAINER}
							className={classes.resultContainer}
							style={fullCircleFormList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(
								fullCircleFormList,
								FormTypeEnum.fullCircleForm,
								mobile
							)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.AVAILABLE_FOR_RETRAINING_FORMS}
						title={`Available for Retraining (${horsePlacementList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={availableForRetrainingStatusList}
									selectedIds={selectedHorsePlacementStatus}
									onSelectChange={updateHorsePlacementForms}
								/>
							</div>
						</div>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Form</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterFormName"
									listOptions={retrainingFormTypeList}
									selectedIds={selectedRetrainingFormNames}
									onSelectChange={updateRetrainingFormsByName}
								/>
							</div>
						</div>
						<div
							id={
								ReviewListKeys.AVAILABLE_FOR_RETRAINING_FORMS_RESULT_CONTAINER
							}
							className={classes.resultContainer}
							style={horsePlacementList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(
								horsePlacementList,
								FormTypeEnum.horsePlacementForm,
								mobile
							)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.OHEP_FORMS}
						title={`OHEP (${ohepFormList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={ohepStatusList}
									selectedIds={selectedOhepFormStatus}
									onSelectChange={updateOhepForms}
								/>
							</div>
						</div>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Name</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterFormName"
									listOptions={ohepFormTypeList}
									selectedIds={selectedOhepFormNames}
									onSelectChange={updateOhepFormsByName}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.OHEP_FORMS_RESULT_CONTAINER}
							className={classes.resultContainer}
							style={ohepFormList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(ohepFormList, FormTypeEnum.ohepForm, mobile)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.RESET_FORMS}
						title={`RESET (${resetFormList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={statusList}
									selectedIds={selectedResetFormStatus}
									onSelectChange={updateResetForms}
								/>
							</div>
						</div>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Name</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterFormName"
									listOptions={resetFormTypeList}
									selectedIds={selectedResetFormNames}
									onSelectChange={updateResetFormsByName}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.RESET_FORMS_RESULT_CONTAINER}
							className={classes.resultContainer}
							style={resetFormList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(resetFormList, FormTypeEnum.resetForm, mobile)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.SAFE_NET_PROGRAM_REPORTS_FORMS}
						title={`Safety Net Program Reports (${safetyNetProgramReportList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={statusList}
									selectedIds={selectedSafelyNetProgramStatus}
									onSelectChange={updateSafetyNetPrograms}
								/>
							</div>
						</div>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Name</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterFormName"
									listOptions={safetyNetProgramReportTypeList}
									selectedIds={selectedSafetyNetProgramReportTypeName}
									onSelectChange={updateSafetyNetProgramFormsByName}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.SAFE_NET_PROGRAM_REPORTS_CONTAINER}
							className={classes.resultContainer}
							style={
								safetyNetProgramReportList.length > 0 ? {} : { display: 'none' }
							}
						>
							{renderResults(
								safetyNetProgramReportList,
								FormTypeEnum.safetyNetProgramReportsForm,
								mobile
							)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.SHARE_INFORMATION_FORMS}
						title={`Share Information (${shareInformationFormList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={statusList}
									selectedIds={selectedShareInformationFormStatus}
									onSelectChange={updateShareInformationForms}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.SHARE_INFORMATION_FORMS_RESULT_CONTAINER}
							className={classes.resultContainer}
							style={
								shareInformationFormList.length > 0 ? {} : { display: 'none' }
							}
						>
							{renderResults(
								shareInformationFormList,
								FormTypeEnum.shareInformationForm,
								mobile
							)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.TRACEABILITY_REQUESTS}
						title={`Traceability Requests (${traceabilityFormList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={thoroughbredStatusList}
									selectedIds={selectedThoroughbredFormStatus}
									onSelectChange={updateTraceabilityForms}
								/>
							</div>
						</div>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Form</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterFormName"
									listOptions={traceabilityFormTypeList}
									selectedIds={selectedTraceabilityFormNames}
									onSelectChange={updateTraceabilityFormsByName}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.FIND_YOUR_THOROUGHBRED_FORMS_RESULT_CONTAINER}
							className={classes.resultContainer}
							style={traceabilityFormList.length > 0 ? {} : { display: 'none' }}
						>
							{!mobile && renderTraceabilityHeadings(traceabilityHeadings)}
							{renderResults(
								traceabilityFormList,
								FormTypeEnum.thoroughbredRegistrationForm,
								mobile
							)}
						</div>
					</Accordion>
					<Accordion
						id={ReviewListKeys.TRANSITION_SUPPORT_FORMS}
						title={`Transition Support (${transitionSupportFormList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Status</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={transitionStatusList}
									selectedIds={selectedTransitionSupportFormStatus}
									onSelectChange={updateSelectedTransitionSupportForms}
								/>
							</div>
						</div>
						<div
							id={ReviewListKeys.TRANSITION_SUPPORT_FORMS_RESULT_CONTAINER}
							className={classes.resultContainer}
							style={
								transitionSupportFormList.length > 0 ? {} : { display: 'none' }
							}
						>
							{renderResults(
								transitionSupportFormList,
								FormTypeEnum.transitionSupportForm,
								mobile
							)}
						</div>
					</Accordion>
				</>
			)}
			{selectedTab === ReviewListKeys.ROLE_MANAGEMENT && (
				<>
					<Row>
						<Col xs={12}>
							<SearchField
								onInputChange={(val) => {
									setSelectedUserByEmail(null);
									setEmail(val);
								}}
								title="Email"
								onSearchClick={() => onSearchUserByEmail()}
								keyCheck={keyCheck}
								validation={searchByEmailValidation}
								inputValue={email}
							/>
						</Col>
					</Row>
					{selectedUserByEmail !== null ? (
						<Row style={{ marginTop: '20px' }}>
							<Col xs={12} lg={2}>
								<div className={classes.roles}>Roles</div>
							</Col>
							<Col xs={12} lg={10}>
								<div className={classes.buttonContainer}>
									{isLoading ? (
										<LoaderSpinner status={true} />
									) : (
										<FormButton
											id={ReviewListKeys.UPDATE_ROLE}
											title="Update Role"
											onClick={updateRoles}
											isDisabled={isCompetitionDisable}
										/>
									)}
								</div>
							</Col>
							<Col xs={12} lg={4}>
								{rolesList
									.filter((item) => item.key == ROLE_ID.OTT_COMMUNITY)
									.concat(
										rolesList
											.filter((item) => item.key !== ROLE_ID.OTT_COMMUNITY)
											.sort((a, b) => a.name.localeCompare(b.name))
									)
									.slice(0, 5)
									.map((item) =>
										item.key == ROLE_ID.OTT_COMMUNITY ||
										item.key == ROLE_ID.ACK_RESET_RETRAINER ? (
											<BasicCheckbox
												value={item.selected}
												disabled={
													item.key == ROLE_ID.OTT_COMMUNITY ||
													!rolesList.find(
														(item) => item.key == ROLE_ID.ACK_RETRAINER
													)?.selected
												}
												handleChange={() =>
													item.key == ROLE_ID.OTT_COMMUNITY
														? {}
														: resetRole(item.key)
												}
												label={item.name}
												key={item.key}
											/>
										) : (
											<BasicCheckbox
												value={item.selected}
												disabled={false}
												handleChange={() => resetRole(item.key)}
												label={
													item.name == 'Competition Admin.'
														? 'Competition Admin'
														: item.name
												}
												key={item.key}
											/>
										)
									)}
							</Col>
							<Col xs={12} lg={4}>
								{rolesList
									.filter((item) => item.key == ROLE_ID.OTT_COMMUNITY)
									.concat(
										rolesList
											.filter((item) => item.key !== ROLE_ID.OTT_COMMUNITY)
											.sort((a, b) => a.name.localeCompare(b.name))
									)
									.slice(5, 10)
									.map((item) =>
										item.key == ROLE_ID.OTT_COMMUNITY ? (
											<BasicCheckbox
												value={item.key == ROLE_ID.OTT_COMMUNITY}
												disabled={item.key == ROLE_ID.OTT_COMMUNITY}
												handleChange={() => {}}
												label={item.name}
												key={item.key}
											/>
										) : (
											<BasicCheckbox
												value={item.selected}
												disabled={false}
												handleChange={() => resetRole(item.key)}
												label={
													item.name == 'Competition Admin.'
														? 'Competition Admin'
														: item.name
												}
												key={item.key}
											/>
										)
									)}
							</Col>
							<Col xs={12} lg={4}>
								{rolesList
									.filter((item) => item.key == ROLE_ID.OTT_COMMUNITY)
									.concat(
										rolesList
											.filter((item) => item.key !== ROLE_ID.OTT_COMMUNITY)
											.sort((a, b) => a.name.localeCompare(b.name))
									)
									.slice(10, rolesList.length)
									.map((item) => (
										<BasicCheckbox
											value={item.selected}
											disabled={false}
											handleChange={() => resetRole(item.key)}
											label={
												item.name == 'Competition Admin.'
													? 'Competition Admin'
													: item.name
											}
											key={item.key}
										/>
									))}
							</Col>
						</Row>
					) : null}
					{/* 
					<Row>
						<Col>
							<div className={classes.email}>Email Id</div>
							<FormTextField
								id={ReviewListKeys.EMAIL}
								onChange={(e) => {
									setEmail(e.target.value);
									evaluateDisableUpdateButton(e.target.value, selectedRoles);
								}}
								value={email}
								canEdit
							/>
						</Col>
						<Col>
							<MultiDropDown
								id={ReviewListKeys.ROLES}
								list={rolesList}
								resetThenSet={resetRole}
								headTitle="Role List"
							/>
						</Col>
					</Row> */}
					{selectedUserByEmail && isCompetitionAdminSelected ? (
						<Row className={classes.eventOrganizer}>
							<SelectInputField
								label="Event Organiser"
								id={ReviewListKeys.EVENT_ORGANISER}
								options={eventOrganisers}
								name={`eventOrganiser`}
								value={values.eventOrganiser.value}
								isRequired
								fullWidth
								onChange={(e) =>
									handleChangeDropDown(e, [
										{ item: 'organiserOther', value: '' }
									])
								}
							/>
						</Row>
					) : null}
					{isOptionOther(eventOrganisers, values.eventOrganiser.value) && (
						<Row className={classes.eventOrganizer}>
							<TextInputField
								id={ReviewListKeys.OTHER_ORGANISER}
								label="Other Event Organiser Information*"
								value={values.organiserOther.value}
								onChange={(e) => {
									handleChange(e, 'organiserOther');
								}}
								fullWidth
							/>
						</Row>
					)}
					{/* <div className={classes.buttonContainer}>
						<FormButton
							id={ReviewListKeys.UPDATE_ROLE}
							title="Update Role"
							onClick={updateRoles}
							isDisabled={isCompetitionDisable}
						/>
					</div> */}
				</>
			)}
			{selectedTab === ReviewListKeys.USER && <UserSearch />}
			{selectedTab === ReviewListKeys.COMPETITIONS && <AddCompetitionPage />}
			{selectedTab === ReviewListKeys.FOLLOWING_FEED && (
				<AdminHorseUpdatesTab />
			)}
		</PageContainer>
	);
};

export default ReviewList;
