import React, { useState, useEffect, Fragment, useMemo } from 'react';
import HorseDatum from '../HorseDatum';
import AppSyncService from '../../../../graphql/AppSyncService';
import HorseInfoSection from '../HorseInfoSection';
import FormToolTip from '../../../FormToolTip';
import { useScreenClass } from 'react-grid-system';
import { checkIsSmallScreen, sortArrayByDate } from '../../../../utils/helpers';
import { formatDateReadableVariant } from '../../../../utils/helpers';
import useStyles from './styles';
import { Link } from 'gatsby';
import { shouldShowDetail } from '../../helpers';
import { getResetConcludedDetails } from '../../../../graphql/custom.queries';
import {
	retirementCategoryList,
	vulnerabilityTypeList
} from '../../../RetirementCategory/constants';
import { PRINCIPAL_RACING_AUTHORITY } from '../../constants';
import {
	FOSTER_FORMS,
	RETIREMENT_FORMS,
	RESET_FORMS,
	OHEP_FORMS,
	SHARE_INFORMATION_FORM,
	RETRAINING_FORMS,
	FULL_CIRCLE_FORM,
	TRANSITION_REPORT,
	SAFETY_NET_PORGRAM_FORMS
} from '../../../../utils/constants';
import qs from 'query-string';

const OTHER = 'Other';

const HorseProfileDataTab = ({
	horseProfile,
	isAdmin,
	isOwner,
	isFollower,
	isMobile,
	horseProfileRetirement
}) => {
	const [resetConcludedDetails, setResetConcludedDetails] = useState(null);
	//@NOTE: Should collapse every section other than Horse Details section on load
	const [isShowAdditionalDetails, setIsShowAdditionalDetails] = useState(false);
	const [isShowOttStatus, setIsShowOttStatus] = useState(false);
	const [isShowPreviousClaims, setIsShowPreviousClaims] = useState(false);
	const [isRetirementCategory, setIsRetirementCategory] = useState(false);
	// const [isShowRetirementCategory, setIsShowRetirementCategory] = useState(false);
	const {
		horse: {
			location: horseLocationInfo,
			ottInformation: ottInfo,
			...horseInfo
		},
		fullCircles,
		claims,
		unclaims,
		program,
		status,
		horseRelatedFormsList
	} = horseProfile;
	const horseRelatedFormsListFinal = [...unclaims, ...horseRelatedFormsList];
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const classes = useStyles();
	const HorseClaims = claims?.map(
		({ id, member: { firstName, lastName }, ownershipType }) => (
			<HorseDatum
				key={id}
				label={<strong>{`${firstName} ${lastName}, ${ownershipType}`}</strong>}
				value={
					<Link
						to={`/claim-thoroughbred-review/${id}`}
						className={classes.viewClaimFormLink}
						id={`horse-profile-view-claim-link-${id}`}
					>
						View Claim Form
					</Link>
				}
			/>
		)
	);

	const showAddress = shouldShowDetail(
		horseLocationInfo?.addressVisibility,
		isFollower,
		isOwner,
		isAdmin
	);
	const showSuburb = shouldShowDetail(
		horseLocationInfo?.suburbVisibility,
		isFollower,
		isOwner,
		isAdmin
	);
	const showState = shouldShowDetail(
		horseLocationInfo?.stateVisibility,
		isFollower,
		isOwner,
		isAdmin
	);
	const showPostcode = shouldShowDetail(
		horseLocationInfo?.postcodeVisibility,
		isFollower,
		isOwner,
		isAdmin
	);
	const showCountry = shouldShowDetail(
		horseLocationInfo?.countryVisibility,
		isFollower,
		isOwner,
		isAdmin
	);

	const showSourceLocation = useMemo(() => {
		if (horseLocationInfo?.source?.value === PRINCIPAL_RACING_AUTHORITY) {
			const otherDetails = horseLocationInfo?.principalRacingAuthorityDetails
				?.principalRacingAuthorityOther
				? ` - ${horseLocationInfo?.principalRacingAuthorityDetails?.principalRacingAuthorityOther}`
				: '';
			return `${horseLocationInfo?.source?.value}, ${horseLocationInfo?.principalRacingAuthorityDetails?.principalRacingAuthorityValue} ${otherDetails}`;
		}

		return horseLocationInfo?.source?.value;
	}, [horseLocationInfo?.source]);

	useEffect(() => {
		AppSyncService.execute(getResetConcludedDetails, {
			horseCode: horseInfo.horseCode
		}).then((result) => {
			if (result?.data) {
				setResetConcludedDetails(result.data.getResetConcludedDetails);
			}
		});
	}, []);

	const evaluateLinkAndTitle = (subType, id) => {
		let item = {};
		switch (subType) {
			case FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT:
				item.link = `/safety-net-program-reports/${id}?${qs.stringify({
					subType: FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT
				})}`;
				item.title = FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT;
				break;
			case RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT:
				item.link = `/safety-net-program-reports/${id}?${qs.stringify({
					subType: RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT
				})}`;
				item.title = RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT;
				break;
			case RESET_FORMS.RESET_INITIAL_ASSESSMENT:
				item.link = `/reset-form-review/${id}?${qs.stringify({
					subType: RESET_FORMS.RESET_INITIAL_ASSESSMENT
				})}`;
				item.title = RESET_FORMS.RESET_INITIAL_ASSESSMENT;
				break;
			case RESET_FORMS.RESET_NOMINATION:
				item.link = `/reset-form-review/${id}?${qs.stringify({
					subType: RESET_FORMS.RESET_NOMINATION
				})}`;
				item.title = RESET_FORMS.RESET_NOMINATION;
				break;
			case RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT:
				item.link = `/reset-form-review/${id}?${qs.stringify({
					subType: RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT
				})}`;
				item.title = RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT;
				break;
			case RESET_FORMS.RESET_EXPRESS_REHOME_REPORT:
				item.link = `/reset-form-review/${id}?${qs.stringify({
					subType: RESET_FORMS.RESET_EXPRESS_REHOME_REPORT
				})}`;
				item.title = RESET_FORMS.RESET_EXPRESS_REHOME_REPORT;
				break;
			case RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT:
				item.link = `/reset-form-review/${id}?${qs.stringify({
					subType: RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT
				})}`;
				item.title = RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT;
				break;
			case OHEP_FORMS.OHEP_NOMINATION:
				item.link = `/ohep-form-review/${id}`;
				item.title = OHEP_FORMS.OHEP_NOMINATION;
				break;
			case SHARE_INFORMATION_FORM:
				item.link = `/share-information-form-review/${id}`;
				item.title = SHARE_INFORMATION_FORM;
				break;
			case RETRAINING_FORMS.REQUEST_FOR_RETRAINING:
				item.link = `/available-for-retraining/${id}`;
				item.title = RETRAINING_FORMS.REQUEST_FOR_RETRAINING;
				break;
			case RETRAINING_FORMS.REHABILITATION_SUBSIDY_APPLICATION:
				item.link = `/rehabilitation-subsidy/${id}`;
				item.title = RETRAINING_FORMS.REHABILITATION_SUBSIDY_APPLICATION;
				break;
			case FULL_CIRCLE_FORM:
				item.link = `/full-circle-review/${id}`;
				item.title = FULL_CIRCLE_FORM;
				break;
			case TRANSITION_REPORT:
				item.link = `/transition-report-by-id/${id}`;
				item.title = TRANSITION_REPORT;
				break;
			case SAFETY_NET_PORGRAM_FORMS.RTCP_HORSE_WELFARE_REPORT:
				item.link = `/companion-horse-report-by-id/${id}`;
				item.title = SAFETY_NET_PORGRAM_FORMS.RTCP_HORSE_WELFARE_REPORT;
				break;
			default:
				item.link = `/previous-claim-thoroughbred-review/${id}`;
				item.title = 'Previous Claim';
				break;
		}
		return item;
	};

	const renderConclusionReasonAndPurpose = () => {
		let result = `${status.conclusionReason.value}`;
		if (!status.conclusionPurpose?.value) {
			return result;
		}
		result += ', ';
		if (status.conclusionPurpose?.value === 'Other') {
			result += status.conclusionPurposeOther;
			return result;
		}
		result += status.conclusionPurpose.value;
		return result;
	};
	return (
		<>
			<HorseInfoSection showSection>
				<HorseDatum
					label={
						<>
							Racing Status
							<FormToolTip
								toolTipContainerStyle={{
									width: isSmallScreen ? 170 : 400,
									left: 40
								}}
								type="questionMark"
							>
								This is the last known Racing Status of this horse, if you have
								an update please submit a Share Information form.
							</FormToolTip>
						</>
					}
					showToolTip
					isRacing
					value={horseInfo.status}
				/>
				<HorseDatum label="Sex" value={horseInfo.sex} />
				<HorseDatum label="Age" value={horseInfo.age} />
				<HorseDatum label="Country of Birth" value={horseInfo.country} />
				<HorseDatum label="Color" value={horseInfo.colour} />
				<HorseDatum
					label="Known as"
					value={ottInfo.paddockName}
					displayCondition={
						ottInfo.paddockName &&
						shouldShowDetail(
							ottInfo.paddockNameVisibility,
							isFollower,
							isAdmin,
							isOwner
						)
					}
				/>
				<HorseDatum
					label="Stable Address"
					showPrivateDataTooltip
					value={
						<div className={classes.addressSectionContent}>
							{horseLocationInfo?.address && (
								<>
									{showAddress && <p>{`${horseLocationInfo.address}`}</p>}
									{showSuburb && horseLocationInfo.suburb && (
										<p>{horseLocationInfo.suburb}</p>
									)}
									{horseLocationInfo.state && horseLocationInfo.postcode && (
										<p>{`${showState ? horseLocationInfo.state : ''} ${
											showPostcode ? horseLocationInfo.postcode : ''
										}`}</p>
									)}
									{showCountry && horseLocationInfo.country && (
										<p>{horseLocationInfo.country}</p>
									)}
								</>
							)}
						</div>
					}
					displayCondition={
						showAddress ||
						showSuburb ||
						showState ||
						showPostcode ||
						showCountry
					}
				/>
				<HorseDatum
					label="Date Arrived"
					showPrivateDataTooltip
					value={
						horseLocationInfo?.stabledDate
							? formatDateReadableVariant(horseLocationInfo.stabledDate)
							: ''
					}
					displayCondition={shouldShowDetail(
						horseLocationInfo?.stabledDateVisibility,
						isFollower,
						isAdmin,
						isOwner
					)}
				/>
			</HorseInfoSection>
			<HorseInfoSection
				headerContent="Additional Details"
				darkContentBackground
				showSection={isAdmin || isOwner}
				isToggleActive={isShowAdditionalDetails}
				isMobile={isMobile}
				isShowToggle={true}
				onToggleClick={() =>
					setIsShowAdditionalDetails(!isShowAdditionalDetails)
				}
			>
				<HorseDatum label="Sire Name" value={horseInfo.sireName} />
				<HorseDatum label="Dam Name" value={horseInfo.damName} />
				<HorseDatum
					label="Registration No."
					value={horseInfo.registrationNumber}
				/>
				<HorseDatum label="Life No." value={horseInfo.lifeNumber} />
				<HorseDatum
					label="Foal Date (DOB)"
					value={formatDateReadableVariant(horseInfo.foalDate)}
				/>
				<HorseDatum label="Foal Year" value={horseInfo.foalYear} />
				<HorseDatum label="Microchip No." value={horseInfo.microChipNumber} />
				<HorseDatum label="Overseas Name" value={horseInfo.nameOverseas} />
			</HorseInfoSection>
			<HorseInfoSection
				headerContent="OTT Status"
				showSection={isAdmin}
				isToggleActive={isShowOttStatus}
				isMobile={isMobile}
				isShowToggle={true}
				onToggleClick={() => setIsShowOttStatus(!isShowOttStatus)}
			>
				<HorseDatum
					label="Claimed"
					value={
						claims?.length > 0
							? 'Yes'
							: unclaims?.length > 0
							? 'Previously'
							: 'No'
					}
				/>
				{HorseClaims}
				<HorseDatum
					label="How Did User Source"
					value={
						horseProfile.claimsSource !== OTHER
							? horseProfile.claimsSource
							: `${horseProfile.claimsSource}, ${horseProfile.claimsSourceOther} `
					}
				/>
				<HorseDatum
					label="Full Circle"
					value={fullCircles.length > 0 ? 'Yes' : 'No'}
				/>
				<HorseDatum
					label="Program"
					value={program.value == 'RESET Express' ? 'PRESET' : program.value}
				/>
				<HorseDatum
					label="OTT Status"
					value={
						<Fragment>
							<div className="text-right">{status.status.value}</div>
							{program.value === 'Acknowledged Retrainer' &&
								status.status?.value === 'Concluded Retraining' &&
								status.conclusionReason.value && (
									<span>{renderConclusionReasonAndPurpose()}</span>
								)}
							{program.value === 'RESET' &&
								status.status?.value === 'Concluded' &&
								resetConcludedDetails && (
									<span>
										{`${resetConcludedDetails.concludedReason}, ${resetConcludedDetails.reason}`}
									</span>
								)}
						</Fragment>
					}
				/>
				<HorseDatum
					label="As Of Date (Date can be approximate)"
					value={
						status.effectiveDate
							? formatDateReadableVariant(status.effectiveDate)
							: ''
					}
				/>
				<HorseDatum label="Source of Location" value={showSourceLocation} />
				{horseLocationInfo?.sourceOther && (
					<HorseDatum label="Other" value={horseLocationInfo.sourceOther} />
				)}
			</HorseInfoSection>
			{horseProfileRetirement && (
				<HorseInfoSection
					headerContent="Retirement Category"
					showSection={isAdmin}
					isToggleActive={isRetirementCategory}
					isMobile={isMobile}
					isShowToggle={true}
					onToggleClick={() => setIsRetirementCategory(!isRetirementCategory)}
				>
					<HorseDatum
						label="Retirement Category"
						value={
							retirementCategoryList.find(
								(x) => x.id === horseProfileRetirement?.retirementCategory
							)?.value
						}
					/>
					<HorseDatum
						label="Reason"
						value={
							<div className={classes.addressSectionContent}>
								{horseProfileRetirement.reason?.map((item, index) => {
									let reasonString = (
										<p key={index}>
											{item.value}
											{index !== horseProfileRetirement.reason.length - 1
												? ','
												: ''}
										</p>
									);
									return reasonString;
								})}
							</div>
						}
					/>
					<HorseDatum
						label="Vulnerability"
						value={
							vulnerabilityTypeList.find(
								(x) => x.id === horseProfileRetirement?.vulnerabilityType
							)?.value
						}
					/>
				</HorseInfoSection>
			)}
			<HorseInfoSection
				headerContent="Horse Forms"
				showSection={isAdmin}
				isToggleActive={isShowPreviousClaims}
				isMobile={isMobile}
				isShowToggle={true}
				onToggleClick={() => setIsShowPreviousClaims(!isShowPreviousClaims)}
			>
				{sortArrayByDate(horseRelatedFormsListFinal, 'created').map(
					({ id, member: { firstName, lastName }, subType, lastUpdated }) => {
						const item = evaluateLinkAndTitle(subType, id);
						return (
							<HorseDatum
								key={id}
								label={
									<strong>
										{firstName} {lastName},{' '}
										{formatDateReadableVariant(lastUpdated)}
									</strong>
								}
								value={
									<Link
										to={item.link}
										className={classes.viewClaimFormLink}
										id={`horse-profile-view-previous-claim-link-${id}`}
									>
										{item.title} Form
									</Link>
								}
							/>
						);
					}
				)}
			</HorseInfoSection>
		</>
	);
};

export default HorseProfileDataTab;
