import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import { useAuth } from '../../hooks/useAuth';
import FormTextField from '../FormTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import MessageBar from '../MessageBar';
import AppSyncService from '../../graphql/AppSyncService';
import { generateChangePasswordEmail } from '../../graphql/custom.queries';

// form validation rules
const schema = yup.object().shape({
	currentPassword: yup
		.string()
		.required('Current Password is required')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			'Must Contain at-least 8 Characters with One Uppercase, One Lowercase, One Number and One Special Case Character(!@#$%^&*)'
		),
	newPassword: yup
		.string()
		.required('Password is required')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			'Must Contain at-least 8 Characters with One Uppercase, One Lowercase, One Number and One Special Case Character(!@#$%^&*)'
		),
	confirmPassword: yup
		.string()
		.required('Confirm Password is required')
		.oneOf([yup.ref('newPassword'), null], 'Passwords do not match')
});

const PasswordChange = ({ memberExternalId }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { changeUserPassword } = useAuth();
	const { handleSubmit, errors, control } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});

	const [currentPassword, setcurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [changePasswordClicked, setChangePasswordClicked] = useState(false);

	const validatePasswordFields = () => {
		if (
			currentPassword.trim() == '' ||
			newPassword.trim() == '' ||
			confirmPassword.trim() == '' ||
			newPassword != confirmPassword
		) {
			setBackendErrorMessage('Please ented valid details');
			return false;
		} else {
			setBackendErrorMessage(null);
			return true;
		}
	};

	const sendChangePasswordEmail = async () => {
		await AppSyncService.execute(generateChangePasswordEmail, {
			memberExternalId: memberExternalId
		});
	};
	const onSubmit = async () => {
		setBackendErrorMessage(null);
		if (validatePasswordFields()) {
			try {
				await changeUserPassword(currentPassword, newPassword);
				sendChangePasswordEmail();
				addToast({ Message: 'Password changed', IsSuccess: true });
				changePasswordButtonClicked(false);
			} catch (error) {
				console.error(
					`error while changing the password: ${JSON.stringify(error)}`
				);
				if (error && error.message) {
					setBackendErrorMessage(error.message);
				} else {
					setBackendErrorMessage(
						'Unknown error while updating password. Please contact support'
					);
				}
			}
		}
	};

	const changePasswordButtonClicked = (value) => {
		setChangePasswordClicked(value);
		setcurrentPassword('');
		setNewPassword('');
		setConfirmPassword('');
	};

	return (
		<>
			<form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
				<div className={classes.passwordChange}>
					{changePasswordClicked != true && (
						<Row>
							<Col>
								<PrimaryButton
									style={{ width: '165px' }}
									onClick={() => changePasswordButtonClicked(true)}
									isNonSubmit
								>
									Change Password
								</PrimaryButton>
							</Col>
						</Row>
					)}
					{changePasswordClicked == true && (
						<>
							<Row>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>Current Password*</div>
										<Controller
											control={control}
											name="currentPassword"
											render={({ onChange }) => (
												<FormTextField
													value={currentPassword}
													fieldType="password"
													onChange={(e) => {
														onChange(e.target.value);
														setcurrentPassword(e.target.value);
													}}
												/>
											)}
										/>
										<span className={classes.errorText}>
											{errors.currentPassword?.message}
										</span>
									</div>
								</Col>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>New Password*</div>
										<Controller
											control={control}
											name="newPassword"
											render={({ onChange }) => (
												<FormTextField
													value={newPassword}
													fieldType="password"
													onChange={(e) => {
														onChange(e.target.value);
														setNewPassword(e.target.value);
													}}
												/>
											)}
										/>
										<span className={classes.errorText}>
											{errors.newPassword?.message}
										</span>
									</div>
								</Col>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>Confirm Password*</div>
										<Controller
											control={control}
											name="confirmPassword"
											render={({ onChange }) => (
												<FormTextField
													value={confirmPassword}
													fieldType="password"
													onChange={(e) => {
														onChange(e.target.value);
														setConfirmPassword(e.target.value);
													}}
												/>
											)}
										/>
										<span className={classes.errorText}>
											{errors.confirmPassword?.message}
										</span>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<MessageBar type="info" className={classes.passwordInfo}>
										Your password must contain:
										<br />
										&nbsp;&nbsp;.&nbsp;&nbsp;At least 8 Characters
										<br />
										&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 uppercase letter
										<br />
										&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 lowercase letter
										<br />
										&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 number
										<br />
										&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 special character
										(!@#$%^&*)
										<br />
									</MessageBar>
								</Col>
							</Row>
							<div className={classes.buttonContainer}>
								<div></div>
								<div className={classes.rightContainer}>
									{backendErrorMessage && (
										<span className={classes.errorMessage}>
											{backendErrorMessage}
										</span>
									)}
									<span
										className={classes.cancelRequest}
										onClick={() => changePasswordButtonClicked(false)}
									>
										Cancel
									</span>
									<span>
										<PrimaryButton style={{ width: '165px' }}>
											Save Password
										</PrimaryButton>
									</span>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={classes.clear}></div>
			</form>
		</>
	);
};

export default PasswordChange;
