import React, { useState, useEffect } from 'react';
import FormDatePicker from '../FormDatePicker';
import MessageBar from '../MessageBar';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import SaveCancelButton from '../SaveCancelButton';
import SimpleRadioGroup from '../SimpleRadioGroup';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import {
	formatDate,
	isOptionOther,
	extractLookUpOptions,
	RehabWeeks
} from '../../utils/helpers';
import {
	HorseManagementKeys,
	CommonKeys,
	WORD_DOC_MIME_TYPE
} from './../../utils/constants/index';
import Toggle from '../Toggle';
import UploadPhoto from '../UploadPhoto';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import Lightbox from 'react-image-lightbox';
import {
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	getListOfReferences
} from '../../graphql/custom.queries';
import axios from 'axios';
import AppSyncService from '../../graphql/AppSyncService';
import { useToast } from '../../hooks/useToast';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import ResizingTextArea from '../ResizingTextArea';
import { vetReportOpts } from './constants';

const RetrainerRehabilitationForm = ({
	title,
	submitForm,
	injuryOptions,
	cancel,
	illnessOptions
}) => {
	const classes = useStyles();

	const [selectedOption, setSelectedOption] = useState(0);
	const [illness, setIllness] = useState(0);
	const [illnessOther, setIllnessOther] = useState('');
	const [injury, setInjury] = useState(0);
	const [injuryOther, setInjuryOther] = useState('');
	const [otherText, setOtherText] = useState('');
	const [error, setError] = useState('');
	const [date, setDate] = useState(null);
	const [isRehabSubsidy, setIsRehabSubsidy] = useState(false);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [vetAssessmentReportPhotos, setVetAssessmentReportPhotos] = useState(
		[]
	);
	const { addToast } = useToast();

	const [dateRehabCommenced, setDateRehabCommenced] = useState(null);
	const [horseCommencedRetraining, setHorseCommencedRetraining] = useState(
		false
	);

	const [dateVetAssessment, setDateVetAssessment] = useState(null);
	const [selectedArrangeVetReport, setSelectedArrangeVetReport] = useState(0);
	const [
		selectedArrangeVetReportText,
		setSelectedArrangeVetReportText
	] = useState('');
	const [horseRehabWeeks, setHorseRehabWeeks] = useState(0);
	const [dateBackToWork, setDateBackToWork] = useState(null);
	const [injuriesList, setInjuriesList] = useState([]);
	const [injuriesValues, setInjuriesValues] = useState([]);
	const [rehabInjuryOther, setRehabInjuryOther] = useState('');
	const [illnessList, setIllnessList] = useState([]);
	const [illnessValues, setIllnessValues] = useState([]);
	const [rehabIllnessOther, setRehabIllnessOther] = useState('');
	const [additionalInfoAssessment, setAdditionalInfoAssessment] = useState('');
	const [shouldValidate, setShouldValidate] = useState(false);
	const [rehabValidated, setRehabValidated] = useState(false);

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const clearForm = () => {
		setInjury(0);
		setIllness(0);
		setInjuryOther('');
		setIllnessOther('');
		setOtherText('');
		setError('');
	};

	const submitData = () => {
		let data = null;
		if (isRehabSubsidy) {
			data = {
				rehabSubsidy: isRehabSubsidy,
				dateRehabCommenced: dateRehabCommenced,
				date: dateRehabCommenced,
				hasHorseCommencedRetraining: horseCommencedRetraining,
				dateVetAssessment: dateVetAssessment,
				arrangeVetReport: selectedArrangeVetReport,
				...(selectedArrangeVetReport == 4 && {
					arrangeVetReportOther: selectedArrangeVetReportText
				}),
				rehabiliationWeek: horseRehabWeeks,
				dateBackToWork: dateBackToWork,
				...(injuriesValues.length > 0 && {
					injuryType: injuriesValues.map((item) => item.value)
				}),
				...(rehabInjuryOther.length > 0 && {
					otherInjury: rehabInjuryOther.map((item) => item.value).toString()
				}),
				...(illnessValues.length > 0 && {
					illnessType: illnessValues.map((item) => item.value)
				}),
				...(rehabIllnessOther.length > 0 && {
					otherIllness: rehabIllnessOther.map((item) => item.value).toString()
				}),
				additionalAssessment: additionalInfoAssessment,
				fileUploads: vetAssessmentReportPhotos,
				rehabReasonId: 3,
				injuryDetailsId: 1
			};
		} else {
			if (selectedOption === 1) {
				if (date === null || injury === 0) {
					setError('Fields cannot be empty');
				} else {
					if (isOptionOther(injuryOptions, injury)) {
						data = {
							date: date,
							injury: injury,
							otherInjuryDetails: injuryOther,
							rehabReason: 1
						};
					} else {
						data = {
							date: date,
							injury: injury,
							rehabReason: 1
						};
					}
				}
			} else if (selectedOption === 2) {
				if (date === null || illness === 0) {
					setError('Fields cannot be empty');
				} else {
					if (isOptionOther(illnessOptions, illness)) {
						data = {
							date: date,
							injury: illness,
							otherIllnessDetails: illnessOther,
							rehabReason: 2
						};
					} else {
						data = {
							date: date,
							illness: illness,
							rehabReason: 2
						};
					}
				}
			} else {
				if (date === null || otherText === '') {
					setError('Fields cannot be empty');
				} else {
					data = {
						date: date,
						other: otherText,
						rehabReason: 3
					};
				}
			}
			data = { ...data, rehabSubsidy: isRehabSubsidy };
		}

		if (data != null) {
			submitForm(data);
		}
	};

	const updateDate = (date) => {
		if (date == '') {
			setDate(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				setDate(formattedDate);
				setError('');
			} else {
				setDate(null);
			}
		}
	};

	const updateRehabDate = (
		value,
		stateAssignmentFunction,
		validationFunction
	) => {
		var now = new Date();
		var dateDifference = now - value;
		if (dateDifference > 504910816000 && dateDifference < 3155839771896) {
			validationFunction(false);
		} else {
			validationFunction(true);
		}
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
		setShouldValidate(true);
	};

	const updateSelectionChange = (e) => {
		let radioInt = parseInt(e.target.value);
		setSelectedOption(radioInt);
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key != img.key));
					setShouldValidate(true);
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	useEffect(() => {
		AppSyncService.execute(getListOfReferences).then((result) => {
			setInjuriesList(extractLookUpOptions(result, 'listInjuryDetails'));
			setIllnessList(
				extractLookUpOptions(result, 'listAckRetirementIllnessTypes')
			);
		});
	}, []);

	useEffect(() => {
		if (shouldValidate) {
			const isValidated = validateRehabSubsidy();

			setShouldValidate(false);
			setRehabValidated(isValidated);
		}
	}, [shouldValidate]);

	const validateRehabSubsidy = () => {
		return (
			!!dateRehabCommenced &&
			vetAssessmentReportPhotos.length > 0 &&
			!!dateVetAssessment &&
			onValidateVetReport() &&
			horseRehabWeeks != 0 &&
			!!dateBackToWork
		);
	};

	const onValidateVetReport = () => {
		if (selectedArrangeVetReport == 4) {
			return selectedArrangeVetReportText.length > 0;
		} else {
			return selectedArrangeVetReport != 0;
		}
	};

	const renderDropDownSelection = () => {
		switch (selectedOption) {
			case 1:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Injury Details*</div>
							<FormDropDown
								id={HorseManagementKeys.INJURY_DETAILS}
								showBlank
								selectedId={injury}
								onChange={(e) => {
									setInjuryOther('');
									setInjury(e.target.value);
								}}
								items={injuryOptions}
							/>
						</Col>
						{injuryOptions.filter(
							(i) => i.id === parseInt(injury) && i.value === 'Other'
						).length > 0 && (
							<Col lg={4}>
								<div className={classes.fieldTitle}>Other Details</div>
								<FormTextField
									id={HorseManagementKeys.INJURY_OTHER_DETAILS}
									value={injuryOther}
									onChange={(e) => setInjuryOther(e.target.value)}
								/>
							</Col>
						)}
					</Row>
				);
			case 2:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Illness Details*</div>
							<FormDropDown
								id={HorseManagementKeys.ILLNESS_DETAILS}
								showBlank
								selectedId={illness}
								onChange={(e) => {
									setIllnessOther('');
									setIllness(e.target.value);
								}}
								items={illnessOptions}
							/>
						</Col>
						{illnessOptions.filter(
							(i) => i.id === parseInt(illness) && i.value === 'Other'
						).length > 0 && (
							<Col lg={4}>
								<div className={classes.fieldTitle}>Other Details</div>
								<FormTextField
									id={HorseManagementKeys.ILLNESS_OTHER_DETAILS}
									value={illnessOther}
									onChange={(e) => setIllnessOther(e.target.value)}
								/>
							</Col>
						)}
					</Row>
				);
			case 3:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Other Details*</div>
							<FormTextField
								id={HorseManagementKeys.OTHER_DETAILS}
								value={otherText}
								onChange={(e) => setOtherText(e.target.value)}
							/>
						</Col>
					</Row>
				);
		}
	};

	const renderRehabilitationSubsidy = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Date rehabilitation commenced*
							</div>
							<div>
								<FormDatePicker
									id={HorseManagementKeys.DATE_COMMENCED}
									DateValue={dateRehabCommenced}
									updateSelection={(value) => {
										updateRehabDate(value, setDateRehabCommenced, () => {});
									}}
								/>
								{/* <span className={classes.errorStyle}>
									{uncontrolledErrors?.DateRehabCommenced?.message}
								</span> */}
							</div>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Had this horse commenced retraining?
							</div>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setHorseCommencedRetraining(value);
								}}
								value={horseCommencedRetraining}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainerNoPaddingTop}>
							<div className={classes.fieldTitle}>
								Please upload the veterinary assessment report/s*{' '}
								<span style={{ fontWeight: 'normal' }}>
									(maximum of 3 documents, to meet the provided criteria)
								</span>
							</div>
							<UploadPhoto
								formats={['png', 'jpeg', 'pdf', WORD_DOC_MIME_TYPE, 'docx']}
								labelName="Upload"
								error={uploadError}
								data={vetAssessmentReportPhotos}
								sizeLimitMB={10}
								numberOfImages={3}
								success={(e) => photosUpload(e, setVetAssessmentReportPhotos)}
								getImage={getImage}
								removeImage={(e) =>
									removePhoto(
										e,
										vetAssessmentReportPhotos,
										setVetAssessmentReportPhotos
									)
								}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainerNoPaddingTop}>
							<div className={classes.fieldTitle}>
								What date was the vet assessment completed?*
							</div>
							<div>
								<FormDatePicker
									id={HorseManagementKeys.DATE_COMMENCED}
									DateValue={dateVetAssessment}
									updateSelection={(value) => {
										updateRehabDate(value, setDateVetAssessment, () => {});
									}}
								/>
							</div>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Who arranged the vet report?*
							</div>
							<FormDropDown
								id={HorseManagementKeys.ILLNESS_DETAILS}
								showBlank
								selectedId={selectedArrangeVetReport}
								onChange={(e) => {
									setIllnessOther('');
									setSelectedArrangeVetReport(e.target.value);
									setShouldValidate(true);
									setSelectedArrangeVetReportText('');
								}}
								items={vetReportOpts}
							/>
						</div>
					</Col>
					{selectedArrangeVetReport == 4 ? (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Other*</div>
								<FormTextField
									id={HorseManagementKeys.OTHER_DETAILS}
									value={selectedArrangeVetReportText}
									onChange={(e) => {
										setSelectedArrangeVetReportText(e.target.value);
										setShouldValidate(true);
									}}
								/>
							</div>
						</Col>
					) : null}

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainerNoPaddingTop}>
							<div className={classes.fieldTitle}>
								How many weeks rehabilitation does this horse require?*
							</div>
							<FormDropDown
								items={RehabWeeks()}
								selectedId={horseRehabWeeks}
								onChange={(e) => {
									setHorseRehabWeeks(e.target.value);
									setShouldValidate(true);
								}}
								showBlank
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainerNoPaddingTop}>
							<div className={classes.fieldTitle}>
								What date to you intend to bring this horse back into work?*
							</div>
							<div>
								<FormDatePicker
									id={HorseManagementKeys.DATE_COMMENCED}
									DateValue={dateBackToWork}
									updateSelection={(value) => {
										updateRehabDate(value, setDateBackToWork, () => {});
									}}
									minDate={new Date()}
									noMaxDate
								/>
							</div>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<MultiSelectInputField
							fieldLabel={`Please select the injury type`}
							selectedIds={injuriesValues}
							options={injuriesList}
							onSelectChange={(injuries) => {
								setInjuriesValues(injuries);
								if (!injuries.find(({ label }) => label === 'Other')) {
									setRehabInjuryOther('');
								}
							}}
							otherValue={rehabInjuryOther}
							onOtherChange={(newOtherValue) => {
								setRehabInjuryOther(newOtherValue);
							}}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<MultiSelectInputField
							fieldLabel={`Please select the illness type`}
							selectedIds={illnessValues}
							options={illnessList}
							onSelectChange={(illness) => {
								setIllnessValues(illness);
								if (!illness.find(({ label }) => label === 'Other')) {
									setRehabIllnessOther('');
								}
							}}
							otherValue={rehabIllnessOther}
							onOtherChange={(newOtherValue) => {
								setRehabIllnessOther(newOtherValue);
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<ResizingTextArea
							title={'Any additional information to support the assessment?'}
							onChange={(e) => {
								console.log(e);
								setAdditionalInfoAssessment(e.target.value);
							}}
							maxChars={500}
							value={additionalInfoAssessment}
							titleStyles={{
								fontSize: 14
							}}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const renderReasonForRehab = () => {
		return (
			<>
				<div className={classes.formHeading} style={{ paddingBottom: 0 }}>
					Reason for Rehabilitation
				</div>
				<Row style={{ marginTop: 20 }}>
					<Col lg={12}>
						<SimpleRadioGroup
							groupName="sampleType"
							onChange={(e) => {
								clearForm();
								updateSelectionChange(e);
							}}
							verticalDisplay
							items={[
								{
									id: 1,
									label: 'Injury',
									value: 1,
									isSelected: false
								},
								{
									id: 2,
									label: 'Illness',
									value: 2,
									isSelected: false
								},
								{
									id: 3,
									label: 'Other',
									value: 3,
									isSelected: false
								}
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col>{renderDropDownSelection()}</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					{selectedOption != 0 && (
						<Col lg={4}>
							<div className={classes.dateFieldTitle}>Date Commenced*</div>
							<div>
								<FormDatePicker
									id={HorseManagementKeys.DATE_COMMENCED}
									styles={{ width: '100%', color: 'blue' }}
									DateValue={date}
									updateSelection={(value) => updateDate(value)}
								/>
							</div>
							<div className={classes.error}>{error}</div>
						</Col>
					)}
				</Row>
			</>
		);
	};
	return (
		<div className={classes.container}>
			<div className={classes.formContainer} style={{}}>
				<div className={classes.formHeading}>{title}</div>
				<MessageBar>
					<div className={classes.topMessageBold}>
						To apply for the Rehabilitation subsidy, a vet report is required to
						be submitted for assessment by RVEW.
					</div>
					<div className={classes.topMessage}>
						The veterinary report must include (at a minimum) the following
						information;
					</div>
					<div className={classes.topMessage}>
						<ul>
							<li>
								The horse’s identification details (including microchip number)
							</li>
							<li>The date of assessment and report</li>
							<li>
								Details of the diagnosis for the horse’s injury, illness or
								condition
							</li>
							<li>
								Detail of the treatment / rehabilitation required including the
								duration for such treatment / rehabilitation
							</li>
							<li>
								The prognosis to return to retraining, the time expected for
								such a return and the level of equestrian pursuits they should
								return to (eg. Low level activities Vs high performance)
							</li>
						</ul>
					</div>

					<div className={classes.topMessage}>
						Following submission, RVEW will review and may consult with the RV
						Veterinary Services team, before providing you with the assessment
						outcome.
					</div>
				</MessageBar>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldTitle}>
							Do you want to apply for the Rehabilitation Subsidy?
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setIsRehabSubsidy(value);
								}}
								value={isRehabSubsidy}
							/>
						</div>
					</Col>
				</Row>
				{isRehabSubsidy
					? renderRehabilitationSubsidy()
					: renderReasonForRehab()}

				{((selectedOption != 0 && !isRehabSubsidy) ||
					(isRehabSubsidy && rehabValidated)) && (
					<>
						<hr className={classes.divider}></hr>
						<div className={classes.saveButtonContainer}>
							<SaveCancelButton
								idPositive={CommonKeys.SAVE}
								idNegative={CommonKeys.CANCEL}
								positiveLabel="Save"
								cancel={cancel}
								onClickPositive={submitData}
								negativeLabel="Cancel"
							/>
						</div>
					</>
				)}
			</div>
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
				maxTitle={'10'}
			/>

			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</div>
	);
};
export default RetrainerRehabilitationForm;
