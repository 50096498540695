import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		width: 1000,
		marginBottom: 50,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileContainer: {
		width: '100%',
		marginBottom: 50,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	desktopPageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150
	},
	mobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	topContainer: {
		maxWidth: '100%',
		background: 'white'
	},
	text: {
		width: '100%',
		fontSize: 14,
		marginBottom: 10
	},
	textMobile: {
		width: '100%',
		fontSize: 12,
		marginBottom: 10
	},
	buttonText: {
		display: 'block',
		color: 'inherit',
		textDecoration: 'inherit'
	},
	messageBar: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	claimText: {
		marginTop: 16,
		color: '#464749'
	},
	multipleVideos: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginTop: 20
	},
	videoContainer: {
		width: 320,
		paddingRight: 5,
		paddingLeft: 5
	},
	mobileVideoContainer: {
		width: '100%',
		paddingRight: 5,
		paddingLeft: 5
	},
	mailRef: {
		textDecoration: 'none',
		color: 'white'
	},
	videoTitle: {
		fontWeight: 600,
		marginBottom: 10
	},
	statsContainer: {
		paddingTop: 12,
		width: 370
	},
	statsContainerMobile: {
		paddingTop: 15
	},
	infoContainer: {
		alignItems: 'center',
		width: '100%',
		marginLeft: '-10%'
	},
	infoContainerMobile: {
		alignItems: 'center',
		width: '100%',
		paddingBottom: 5,
		marginLeft: -15
	},
	description: {
		fontSize: 11,
		backgroundColor: '#EDEDED',
		fontWeight: 400,
		width: 146,
		borderRadius: '0px 0px 5px 5px',
		height: 70,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 20,
		padding: '0px 7px 0px 7px',
		textAlign: 'center'
	},
	mobileDescription: {
		extend: 'description',
		width: '100%',
		padding: 0,
		justifyContent: 'center'
	},
	mobileValue: {
		fontSize: 25,
		fontWeight: 600,
		height: 60,
		color: 'white',
		borderRadius: '5px 5px 0px 0px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
	},
	value: {
		extend: 'mobileValue',
		width: 160
	}
}));
