import React from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import FormTextArea from '../FormTextArea';
import HeaderBanner from '../HeaderBanner';
import LoaderSpinner from '../LoaderSpinner';

const REVIEW = 'Review';

const AdminReviewFormApproval = ({
	isProcessingApprove,
	isProcessingReject,
	mobile,
	processReject,
	processApprove,
	updateNotes,
	rejectReason,
	updateRejectReason,
	status,
	noHeader,
	errorMessage,
	maxReasonCharacters,
	totalLength,
	formName,
	notes,
	isReadOnly = false,
	eligibilityForBCTS,
	retirementCategory,
	notifNotRequired = false
}) => {
	const classes = useStyles();
	const isResetButtonDisabled = notifNotRequired
		? !notes || status != REVIEW
		: notes === undefined
		? !rejectReason || status != REVIEW
		: !rejectReason || !notes || status != REVIEW;

	return (
		<>
			{!noHeader && (
				<HeaderBanner
					title="Additional Notes (Admin Only)"
					inline
					type="primary"
					mobile={mobile}
					styles={{ marginBottom: '0px', marginTop: '0px' }}
				/>
			)}
			<div
				className={classes.container}
				style={
					mobile ? { border: 'none' } : noHeader ? { borderTop: 'none' } : {}
				}
			>
				<div>
					<div>
						{eligibilityForBCTS ? eligibilityForBCTS : null}
						{eligibilityForBCTS && retirementCategory ? (
							<hr
								style={{
									height: 2,
									backgroundColor: '#edf5f9',
									border: 'none'
								}}
							/>
						) : null}
						<div style={!eligibilityForBCTS ? { marginTop: 20 } : null}>
							{retirementCategory ? retirementCategory : null}
						</div>
						{updateNotes && (
							<Row>
								<Col
									className={classes.notesContainer}
									style={{ marginLeft: 20 }}
								>
									<div>
										<span className={classes.fieldTitle}>
											Notes (Required if Rejecting form)
										</span>
									</div>
									<FormTextArea
										id={`${formName}-formReview-notes`}
										styles={{ marginTop: 10 }}
										value={notes}
										disabled={isReadOnly}
										onChange={(e) => updateNotes(e.target.value)}
									/>
								</Col>
							</Row>
						)}
						{!notifNotRequired ? (
							<Row>
								<Col
									className={classes.notesContainer}
									style={{ marginLeft: 20 }}
								>
									<div>
										<span className={classes.fieldTitle}>
											Reason for Rejection (Required if Rejecting form - This is
											a Notification to the User)
										</span>
										<span
											className={classes.charLengthInfo}
											style={mobile ? { float: 'left' } : {}}
										>
											{`${maxReasonCharacters} characters remaining`}{' '}
										</span>
									</div>
									<FormTextArea
										id={`${formName}-formReview-rejectReason`}
										styles={{ marginTop: 10 }}
										value={rejectReason}
										disabled={isReadOnly}
										maxLength={totalLength}
										onChange={(e) => updateRejectReason(e.target.value)}
									/>
								</Col>
							</Row>
						) : null}
					</div>
					<hr className={classes.divider}></hr>
					<div className={classes.buttonContainer}>
						{!mobile && (
							<div className={classes.errorMessage}>{errorMessage}</div>
						)}
						<div
							className={
								mobile ? classes.saveRequestMobile : classes.saveRequest
							}
						>
							<div
								style={
									!mobile ? { display: 'inline-block' } : { dispaly: 'block' }
								}
							>
								<button
									id={`${formName}-formReview-reject`}
									type="button"
									onClick={() => processReject()}
									disabled={isResetButtonDisabled}
									className={
										mobile && isResetButtonDisabled
											? `${classes.secondaryFormButtonMobile} ${classes.disabledFormButtonMobile}`
											: mobile
											? classes.secondaryFormButtonMobile
											: isResetButtonDisabled
											? `${classes.secondaryFormButton} ${classes.disabledFormButtonMobile}`
											: classes.secondaryFormButton
									}
								>
									{isProcessingReject && (
										<LoaderSpinner status={true} height="14" color="black" />
									)}
									{!isProcessingReject && 'Reject'}
								</button>
							</div>
							<div
								style={
									!mobile ? { display: 'inline-block' } : { dispaly: 'block' }
								}
							>
								<button
									id={`${formName}-formReview-approve`}
									type="button"
									disabled={status != REVIEW || rejectReason}
									className={
										mobile
											? status != REVIEW || rejectReason
												? `${classes.primaryFormButtonMobile} ${classes.disabledFormButton}`
												: classes.primaryFormButtonMobile
											: status != REVIEW || rejectReason
											? `${classes.primaryFormButton} ${classes.disabledFormButton}`
											: classes.primaryFormButton
									}
									onClick={() => processApprove()}
								>
									{isProcessingApprove && (
										<LoaderSpinner status={true} height="14" color="black" />
									)}
									{!isProcessingApprove && 'Approve'}
								</button>
							</div>
						</div>
					</div>
					{mobile && (
						<div className={classes.errorMessageMobile}>{errorMessage}</div>
					)}
				</div>
			</div>
		</>
	);
};
export default AdminReviewFormApproval;
